import type { AxiosInstance, AxiosPromise } from 'axios';
import { getCsrfConfig } from '@app/utils/get-csrf-config';
import type { Dictionary } from '@app/models/dictionary';
import type { EventSelectableOptions } from '@app/models/event';
import type { LookupTypes, FieldType } from '@app/models/sub-form-question';
import type { RuleSetFilter } from '@app/models/rule-set-filter';
import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
import type { GeocoderResult } from '@app/models/geocoder-result';
import type { ApiRequestConfig } from '@app/services/donesafe-api-utils';
import type { PlaceholderItem } from '@app/utils/types/placeholder-item';

type Id = number;

export interface InjectableArguments {
  concept_name?: string;
  include_all?: boolean;
  include_calculations?: boolean;
  include_current_user?: boolean;
  involvement_id?: Id;
  regulatory_report_config_id?: Id;
  relationship_code?: string;
  sub_form_id?: Id;
  sub_form_question_id?: Id;
}

export interface FilterParams {
  escape_select_key: boolean;
  filter_method: string;
  hide_visible_sub_form_list_rules: boolean;
  include_context_options: boolean;
}

export interface DomoEmbedParams {
  embed_origin: string;
  embed_path: string;
  embed_token: string;
}

export interface PerOptionResponse {
  concept_name: string;
  injectable_arguments: Record<string, string>;
}

export interface LookupResponse {
  read_only_lookup?: boolean;
  responses: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface DonesafeUtilsApi {
  evaluateCollection: (
    params: {
      injectable_arguments?: Dictionary<string>;
      per: string;
      rules?: QueryBuilderBlob;
    },
    config?: ApiRequestConfig
  ) => AxiosPromise<{ total: number }>;
  evaluatePerOption: (params: { per: string }, config?: ApiRequestConfig) => AxiosPromise<PerOptionResponse>;
  evaluatePlaceholders: (params: { placeholders: string[] }, config?: ApiRequestConfig) => AxiosPromise<Dictionary<string>>;
  executeApiRequest: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: { data: Dictionary<any>; sub_form_question_ids: (number | string)[] },
    config?: ApiRequestConfig
  ) => AxiosPromise;
  geocode: (
    params: {
      latitude: number | string;
      longitude: number | string;
    },
    config?: ApiRequestConfig
  ) => AxiosPromise<{ results: GeocoderResult[] }>;
  getAutomationCreateEventOptions: (
    params?: { injectable_arguments: InjectableArguments },
    config?: ApiRequestConfig
  ) => AxiosPromise<[string, string][]>;
  getDateOptions: (
    params: {
      concept_name: string;
      injectable_arguments: InjectableArguments;
    },
    config?: ApiRequestConfig
  ) => AxiosPromise<[string, string][]>;
  getDetails: (
    params: { methods: string[]; record_id: number; type: FieldType }, // todo typing
    config?: ApiRequestConfig
  ) => AxiosPromise<Record<string, string>>;

  getDomoWidgetData: (params: { embed_cache?: boolean; widget_id: number }) => AxiosPromise<DomoEmbedParams>;
  getInjectableOptions: (
    params: {
      chained?: boolean;
      concept_name?: string;
      execution_context?: 'automation' | 'schedule';
      injectable_arguments?: InjectableArguments;
    },
    config?: ApiRequestConfig
  ) => AxiosPromise<PlaceholderItem[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLimits: (params: any, config?: object) => AxiosPromise<Dictionary<[Nullable<string>, Nullable<string>]>>;
  getPerOptions: (
    params: { concept_name: Nullable<string>; injectable_arguments: InjectableArguments },
    config?: ApiRequestConfig
  ) => AxiosPromise<[string, string][]>;
  getRecordBudgetSource: (
    params: { cross_module_budget_lookup: boolean; record_id: number },
    config?: ApiRequestConfig
  ) => AxiosPromise<number>;
  getRuleSetFilters: (
    params: {
      concept_name?: string;
      execution_context?: 'automation' | 'schedule';
      filter_method?: string;
      filter_params?: FilterParams;
      injectable_arguments: Dictionary<string>;
      triggering_concept_name?: string;
      triggering_injectable_arguments?: Dictionary<string>;
    },
    config?: ApiRequestConfig
  ) => AxiosPromise<RuleSetFilter[]>;
  getSelectableInjectableOptions: (
    params: {
      concept_name: string;
      injectable_arguments?: InjectableArguments;
    },
    config?: ApiRequestConfig
  ) => AxiosPromise<EventSelectableOptions>;
  lookupRequest: (
    params: {
      lookup_record_id?: string | number;
      lookup_type: LookupTypes;
      read_only_lookup?: boolean;
      related_record_question_code: string;
      sub_form_id: string | number;
    },
    config?: ApiRequestConfig
  ) => AxiosPromise<LookupResponse>;
}

export const createUtilsApi = (api: AxiosInstance, path = '/api/utils'): DonesafeUtilsApi => {
  return {
    getInjectableOptions: (params, config) => api.get(`${path}/injectable_options`, { params, ...config }),
    evaluatePlaceholders: (params, config) => api.post(`${path}/evaluate_placeholders`, params, config),
    executeApiRequest: (params, config) => api.post(`${path}/api_request`, params, config),
    geocode: (params, config) => api.get(`${path}/geocode`, { params, ...config, ...getCsrfConfig() }),
    lookupRequest: (params, config) => api.get(`${path}/lookup`, { params, ...config, ...getCsrfConfig() }),
    getLimits: (params, config) => api.get(`${path}/date_limits`, { params, ...config }),
    evaluatePerOption: (params, config) => api.get(`${path}/evaluate_per_option`, { params, ...config }),
    getRuleSetFilters: (params, config) => api.get(`${path}/rule_set_filters`, { params, ...config }),
    evaluateCollection: (params, config) => api.post(`${path}/evaluate_collection`, params, config),
    getAutomationCreateEventOptions: (params, config) => api.get(`${path}/create_event_options`, { params, ...config }),
    getPerOptions: (params, config) => api.get(`${path}/per_options`, { params, ...config }),
    getSelectableInjectableOptions: (params, config) => api.get(`${path}/selectable_injectable_options`, { params, ...config }),
    getDateOptions: (params, config) => api.get(`${path}/date_options`, { params, ...config }),
    getRecordBudgetSource: (params, config) => api.get(`${path}/record_budget_source`, { params, ...config }),
    // TODO: move to domo-api.ts
    getDomoWidgetData: (params) => api.get('/api/domo/widget_data', { params }),
    getDetails: (params, config) => api.get(`${path}/details`, { params, ...config }),
  };
};
