// IT IS A COPY OF THE: activestorage/src/file_checksum just not to make webpacker compile node_modules dir
import SparkMD5 from 'spark-md5';

const fileSlice = File.prototype.slice || (File.prototype as any).mozSlice || (File.prototype as any).webkitSlice;

export class FileChecksum {
  private file: any;
  private chunkSize: number;
  private chunkCount: number;
  private chunkIndex: number;
  private md5Buffer!: SparkMD5.ArrayBuffer;
  private fileReader!: FileReader;
  private callback: any;

  static create(file: any, callback: any) {
    const instance = new FileChecksum(file);
    instance.create(callback);
  }

  constructor(file: any) {
    this.file = file;
    this.chunkSize = 2097152; // 2MB
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize);
    this.chunkIndex = 0;
  }

  create(callback: any): void {
    this.callback = callback;
    this.md5Buffer = new SparkMD5.ArrayBuffer;
    this.fileReader = new FileReader;
    this.fileReader.addEventListener('load', event => this.fileReaderDidLoad(event));
    this.fileReader.addEventListener('error', ({}) => this.fileReaderDidError());
    this.readNextChunk();
  }

  fileReaderDidLoad(event: any): void {
    this.md5Buffer.append(event.target.result);

    if (!this.readNextChunk()) {
      const binaryDigest = this.md5Buffer.end(true);
      const base64digest = btoa(binaryDigest);
      this.callback(null, base64digest);
    }
  }

  fileReaderDidError(): void {
    this.callback(`Error reading ${this.file.name}`);
  }

  readNextChunk(): boolean {
    if (this.chunkIndex < this.chunkCount || (!this.chunkIndex && !this.chunkCount)) {
      const start = this.chunkIndex * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.file.size);
      const bytes = fileSlice.call(this.file, start, end);
      this.fileReader.readAsArrayBuffer(bytes);
      this.chunkIndex++;
      return true;
    } else {
      return false;
    }
  }
}
