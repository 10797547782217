import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ExpensableExpensingTable } from '@app/models/expensable-expensing-table';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeExpensingTablesApi {
  getExpensableExpensingTables(
    options: DonesafeIndexApiOptions<ExpensableExpensingTable>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableExpensingTable[]>;
}

export const createExpensingTablesApi = (api: AxiosInstance, path = '/api/expensing_tables'): DonesafeExpensingTablesApi => {
  return {
    getExpensableExpensingTables: (params, config) => api.get(path, { params, ...config }),
  };
};
