import type { AxiosInstance, AxiosPromise } from 'axios';
import type { PrintConfiguration } from '@app/models/print-configuration';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { RequiredBy } from '@app/utils/types/required-by';

export interface DonesafePrintConfigurationsApi {
  createPrintConfiguration(printConfig: RequiredBy<Partial<PrintConfiguration>, 'module_name_id'>): AxiosPromise<PrintConfiguration>;

  deletePrintConfiguration(id: number): AxiosPromise<PrintConfiguration>;

  getPrintConfiguration(
    id: number,
    params?: DonesafeApiOptions<PrintConfiguration>,
    config?: ApiRequestConfig
  ): AxiosPromise<PrintConfiguration>;

  getPrintConfigurations(
    options: DonesafeIndexApiOptions<PrintConfiguration>,
    config?: ApiRequestConfig
  ): AxiosPromise<PrintConfiguration[]>;

  updatePrintConfiguration(id: number, entity: Partial<PrintConfiguration>): AxiosPromise<PrintConfiguration>;
}

export const createPrintConfigurationsApi = (api: AxiosInstance, path = '/api/print_configurations'): DonesafePrintConfigurationsApi => {
  return {
    createPrintConfiguration: (printConfig) => api.post(path, printConfig),
    deletePrintConfiguration: (id) => api.delete(`${path}/${id}`),
    getPrintConfiguration: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getPrintConfigurations: (params, config) => api.get(path, { params, ...config }),
    updatePrintConfiguration: (id, entity) => api.put(`${path}/${id}`, entity),
  };
};
