import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SubFormListRestriction } from '../../models/sub-form-list-restriction';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeSubFormListRestrictionsApi {
  getSubFormListRestriction(
    id: number | string,
    params?: DonesafeApiOptions<SubFormListRestriction>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubFormListRestriction>;
  getSubFormListRestrictions(
    options: DonesafeIndexApiOptions<SubFormListRestriction>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormListRestriction[]>;
}

export const createSubFormListRestrictionsApi = (
  api: AxiosInstance,
  path = '/api/sub_form_list_restrictions'
): DonesafeSubFormListRestrictionsApi => {
  const restrictionsApi: DonesafeSubFormListRestrictionsApi = {
    getSubFormListRestrictions: (params, config) => api.get(path, { params, ...config }),
    getSubFormListRestriction: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...restrictionsApi,
    getSubFormListRestriction: convertToJoinedRequest<SubFormListRestriction>(
      restrictionsApi.getSubFormListRestriction,
      restrictionsApi.getSubFormListRestrictions
    ),
  };
};
