import type { AxiosInstance, AxiosPromise } from 'axios';
import type { State } from '@app/models/state';
import type { ApiRequestConfig } from '@app/services/donesafe-api-utils';

interface StatesOptions {
  filters?: Partial<State>;
  language?: string;
}

export interface DonesafeStatesApi {
  getStates(options: StatesOptions, config?: ApiRequestConfig): AxiosPromise<State[]>;
}

export const createStatesApi = (api: AxiosInstance, path = '/api/states'): DonesafeStatesApi => {
  return {
    getStates: (params, config) => api.get(path, { params, ...config }),
  };
};
