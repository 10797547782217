import type { AxiosInstance, AxiosPromise } from 'axios';
import type { MapInformation } from '@app/models/map-information';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeMapInformationsApi {
  createMapInformation(MapInformation: Partial<MapInformation>): AxiosPromise<MapInformation>;

  deleteMapInformation(id: number | string): AxiosPromise<MapInformation>;

  getMapInformation(
    id: number | string,
    params?: DonesafeApiOptions<MapInformation>,
    config?: ApiRequestConfig
  ): AxiosPromise<MapInformation>;

  getMapInformations(options: DonesafeIndexApiOptions<MapInformation>, config?: ApiRequestConfig): AxiosPromise<MapInformation[]>;
}

export const createMapInformationsApi = (api: AxiosInstance, path = '/api/map_informations'): DonesafeMapInformationsApi => {
  return {
    getMapInformations: (params, config) => api.get(path, { params, ...config }),
    createMapInformation: (MapInformation) => api.post(path, MapInformation),
    deleteMapInformation: (id) => api.delete(`${path}/${id}`),
    getMapInformation: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
