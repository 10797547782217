import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ModuleRecordCollection } from '@app/models/module-record-collection';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeModuleRecordCollectionsApi {
  getModuleRecordCollection(
    id: number | string,
    params?: DonesafeApiOptions<ModuleRecordCollection>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ModuleRecordCollection>;

  getModuleRecordCollections(
    options: DonesafeIndexApiOptions<ModuleRecordCollection>,
    config?: ApiRequestConfig
  ): AxiosPromise<ModuleRecordCollection[]>;
}

export const createModuleRecordCollectionsApi = (
  api: AxiosInstance,
  path = '/api/module_record_collections'
): DonesafeModuleRecordCollectionsApi => {
  return {
    getModuleRecordCollections: (params, config) => api.get(path, { params, ...config }),
    getModuleRecordCollection: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
