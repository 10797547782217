import type { AdminPermissionFeature } from '@app/models/admin-permission';
import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { PiaweDetail, TenantUser } from '@app/models/tenant-user';
import type { Profile } from '@app/models/profile';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  FilterValue,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
} from '@app/services/donesafe-api-utils';
import type { PartialNullBy } from '@app/utils/types/partial';

export type ValidUserScopes = 'all' | 'user_team' | 'user_wider_team';

export type AdminFlag = AdminAccessFlag | 'can_change_password';
export type AdminAccessFlag = 'admin' | AdminPermissionFeature;

export interface ExtraUserApiOptions {
  accessible_location_id?: number | number[];
  accessible_organization_id?: number | number[];
  admin_flags?: AdminFlag[];
  home_location_accessible_by_user_id?: number;
  home_organization_accessible_by_user_id?: number;
  scope?: ValidUserScopes;
  user_involvements?: {
    involvement_id: number | number[];
  };
  user_location_tags?: {
    location_id?: number | number[] | string;
  };
}

export interface DonesafeTenantUsersApi {
  createTenantUser(
    data: Partial<
      PartialNullBy<
        TenantUser,
        | 'primary_signature_id'
        | 'organization_ceiling_id'
        | 'location_ceiling_id'
        | 'manager_id'
        | 'employment_type_id'
        | 'workplace_industry_id'
        | 'gender_identity_id'
        | 'timezone'
        | 'start_date'
        | 'end_date'
        | 'date_of_birth'
      >
    > & {
      confirmed?: boolean;
    } & DonesafeApiOptions<TenantUser>
  ): AxiosPromise<TenantUser>;

  deleteTenantUser(id: number): AxiosPromise<TenantUser>;

  getTenantUser(
    id: number,
    params?: DonesafeApiOptions<TenantUser>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<TenantUser>;

  getTenantUsers(options: DonesafeIndexApiOptions<TenantUser, ExtraUserApiOptions>, config?: ApiRequestConfig): AxiosPromise<TenantUser[]>;

  getTenantUsersWithSharedFilters(
    options: DonesafeIndexApiOptions<TenantUser>,
    filterKey: keyof TenantUser,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<TenantUser[]>;
  resetTenantUserMfa(id: number): AxiosPromise<TenantUser>;
  unlockTenantUser(id: number): AxiosPromise<TenantUser>;
  updateTenantUser(
    id: number,
    entity: Partial<
      PartialNullBy<
        Omit<TenantUser, 'profile' | 'piawe_detail'>,
        | 'primary_signature_id'
        | 'organization_ceiling_id'
        | 'location_ceiling_id'
        | 'manager_id'
        | 'employment_type_id'
        | 'workplace_industry_id'
        | 'gender_identity_id'
        | 'timezone'
        | 'start_date'
        | 'end_date'
        | 'date_of_birth'
      >
    > & {
      avatar?: Nullable<string>;
      piawe_detail?: Partial<PiaweDetail>;
      profile?: Partial<PartialNullBy<Profile, 'country_code' | 'state_code'>>;
      related_module_records?: Record<string, number | null>;
    } & DonesafeApiOptions<TenantUser>,
    params?: DonesafeApiOptions<TenantUser>
  ): AxiosPromise<TenantUser>;
}

export const createTenantUsersApi = (api: AxiosInstance, path = '/api/tenant_users'): DonesafeTenantUsersApi => {
  const tenantUsersApi: Omit<DonesafeTenantUsersApi, 'getTenantUsersWithSharedFilters'> = {
    getTenantUsers: (params, config) => api.get(path, { params, ...config }),
    getTenantUser: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createTenantUser: (data) => api.post(path, data),
    updateTenantUser: (id, entity, params) => api.put(`${path}/${id}`, entity, { params }),
    deleteTenantUser: (id) => api.delete(`${path}/${id}`),
    resetTenantUserMfa: (id) => api.post(`${path}/${id}/reset_user_mfa`),
    unlockTenantUser: (id) => api.post(`${path}/${id}/unlock`),
  };

  return {
    ...tenantUsersApi,
    getTenantUser: convertToJoinedRequest<TenantUser>(tenantUsersApi.getTenantUser, tenantUsersApi.getTenantUsers),
    getTenantUsersWithSharedFilters: sharedFilterListRequest(tenantUsersApi.getTenantUsers),
  };
};
