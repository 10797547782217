import type { AxiosInstance, AxiosPromise } from 'axios';
import type { IncidentParticipantRole } from '@app/models/incident-participant-role';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeIncidentParticipantRolesApi {
  getIncidentParticipantRoles(
    options: DonesafeIndexApiOptions<IncidentParticipantRole>,
    config?: ApiRequestConfig
  ): AxiosPromise<IncidentParticipantRole[]>;
}

export const createIncidentParticipantRolesApi = (
  api: AxiosInstance,
  path = '/api/incident_participant_roles'
): DonesafeIncidentParticipantRolesApi => {
  return {
    getIncidentParticipantRoles: (params, config) => api.get(path, { params, ...config }),
  };
};
