import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SvgLibraryComponent } from '@app/models/svg-library/component';
import type { SvgLibraryStaticTemplate } from '@app/models/svg-library/static-template';
import type { SvgLibraryTemplate } from '@app/models/svg-library/template';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions } from '@app/services/donesafe-api-utils';

export type CreateSvgTemplateRequest = Omit<SvgLibraryTemplate, 'id' | 'created_at' | 'updated_at'> & {
  components: Array<Omit<SvgLibraryComponent, 'id'>>;
};

export type UpdateSvgTemplateRequest = Omit<SvgLibraryTemplate, 'file'> & {
  components: Array<Omit<SvgLibraryComponent, 'path'>>;
};

export interface SvgTemplatesApi {
  createSvgTemplate(template: CreateSvgTemplateRequest): AxiosPromise<SvgLibraryTemplate>;

  deleteSvgTemplate(templateId: number): AxiosPromise<SvgLibraryTemplate>;

  getSvgStaticTemplateFile(url: string): AxiosPromise<string>;

  getSvgStaticTemplates(config?: ApiRequestConfig): AxiosPromise<SvgLibraryStaticTemplate[]>;

  getSvgTemplate(
    id: number | string,
    params?: DonesafeApiOptions<SvgLibraryTemplate>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SvgLibraryTemplate>;

  getSvgTemplateFile(id: number | string, config?: ApiRequestConfig): AxiosPromise<string>;

  getSvgTemplates(params?: DonesafeApiOptions<SvgLibraryTemplate>, config?: ApiRequestConfig): AxiosPromise<SvgLibraryTemplate[]>;

  updateSvgTemplate(template: UpdateSvgTemplateRequest): AxiosPromise<SvgLibraryTemplate>;
}

export const createSvgTemplatesApi = (api: AxiosInstance, path = '/api/svg_templates'): SvgTemplatesApi => {
  const svgTemplatesApi: SvgTemplatesApi = {
    getSvgTemplates: (params, config) => api.get(path, { params, ...config }),
    getSvgTemplate: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createSvgTemplate: (template) => api.post(path, template),
    updateSvgTemplate: (template) => api.put(`${path}/${template.id}`, template),
    deleteSvgTemplate: (id) => api.delete(`${path}/${id}`),
    getSvgTemplateFile: (id, config) => api.get(`${path}/${id}/file`, config),
    getSvgStaticTemplates: (config) => api.get(`${path}/static`, { ...config }),
    getSvgStaticTemplateFile: (url) => api.get(url),
  };

  return {
    ...svgTemplatesApi,
    getSvgTemplate: convertToJoinedRequest(svgTemplatesApi.getSvgTemplate, svgTemplatesApi.getSvgTemplates),
  };
};
