import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SuperReport, SuperReportTotal, SuperReportGraphData, SuperReportData } from '@app/models/super-report';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface SuperReportExtraFilters {
  concept: string;
  report_blob: { '@filters'?: string; klass?: string };
}

export type BlobFilters = {
  filter_blob: {
    filters?: { comparison: string; key: string; type: string; value: string }[];
    graph?: { group_by_1: string; group_by_2: string; include_blanks: string; stacked: string; type: string };
    klass: string;
    module_name_id: string | number;
  };
};

export type GraphDataArray = { data: [string, number][] }[];
export type GraphDataError = { data: { error: string } };
export interface GraphData {
  data: {
    graph_data: GraphDataArray | GraphDataError;
  };
}

export interface DonesafeSuperReportsApi {
  buildSuperReportGraphData(params?: BlobFilters, config?: ApiRequestConfig): AxiosPromise<GraphData>;
  cloneSuperReport(id: number, entity?: Partial<SuperReport>): AxiosPromise<SuperReport>;
  deleteSuperReport(reportId: number): AxiosPromise<SuperReport>;
  getSuperReport(
    id: number,
    params?: DonesafeIndexApiOptions<SuperReport, SuperReportExtraFilters>,
    config?: ApiRequestConfig
  ): AxiosPromise<SuperReport>;
  getSuperReportData(id: number, params?: DonesafeApiOptions<SuperReportData>, config?: ApiRequestConfig): AxiosPromise<SuperReportData>;
  getSuperReportGraphData(id: number, config?: ApiRequestConfig): AxiosPromise<SuperReportGraphData>;
  getSuperReportTotal(id: number, config?: ApiRequestConfig): AxiosPromise<SuperReportTotal>;
  getSuperReports(options: DonesafeIndexApiOptions<SuperReport>, config?: ApiRequestConfig): AxiosPromise<SuperReport[]>;
  sftpExportSuperReport(id: number, data?: { encrypt?: boolean; notify?: boolean }): AxiosPromise<SuperReport>;
}

export const createSuperReportsApi = (api: AxiosInstance, path = '/api/super_reports'): DonesafeSuperReportsApi => {
  return {
    buildSuperReportGraphData: (params, config) => api.get(`${path}/graph_data`, { params, ...config }),
    getSuperReports: (params, config) => api.get(path, { params, ...config }),
    getSuperReport: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getSuperReportTotal: (id) => api.get(`${path}/${id}/total`),
    getSuperReportGraphData: (id) => api.get(`${path}/${id}/graph_data`),
    cloneSuperReport: (id, entity) => api.post(`${path}/${id}/clone`, entity),
    deleteSuperReport: (id) => api.delete(`${path}/${id}`),
    getSuperReportData: (id, params, config) => api.get(`${path}/${id}/data`, { params, ...config }),
    sftpExportSuperReport: (id, data) => api.post(`${path}/${id}/sftp_export`, data),
  };
};
