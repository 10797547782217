import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ProcedureModuleRecord } from '@app/models/procedure-module-record';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeProcedureModuleRecordsApi {
  createProcedureModuleRecord(
    entity: Pick<ProcedureModuleRecord, 'procedure_id' | 'module_record_id' | 'sub_form_list_id'>
  ): AxiosPromise<ProcedureModuleRecord>;
  deleteProcedureModuleRecord(id: number | string, params?: DonesafeApiOptions<ProcedureModuleRecord>): AxiosPromise<ProcedureModuleRecord>;
  getProcedureModuleRecord(
    id: number | string,
    params?: DonesafeApiOptions<ProcedureModuleRecord>,
    config?: ApiRequestConfig
  ): AxiosPromise<ProcedureModuleRecord>;
  getProcedureModuleRecords(
    params?: DonesafeIndexApiOptions<ProcedureModuleRecord>,
    config?: ApiRequestConfig
  ): AxiosPromise<ProcedureModuleRecord[]>;
}

export const createProcedureModuleRecordsApi = (
  api: AxiosInstance,
  path = '/api/procedure_module_records'
): DonesafeProcedureModuleRecordsApi => {
  return {
    getProcedureModuleRecord: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createProcedureModuleRecord: (params) => api.post(path, params),
    getProcedureModuleRecords: (params, config) => api.get(path, { params, ...config }),
    deleteProcedureModuleRecord: (id, params) => api.delete(`${path}/${id}`, { params }),
  };
};
