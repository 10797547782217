import type { AxiosInstance, AxiosPromise } from 'axios';
import type { MobileAppConfig } from '@app/models/mobile-app/config';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeMobileAppConfigsApi {
  createMobileAppConfig(entity: Partial<MobileAppConfig>): AxiosPromise<MobileAppConfig>;

  deleteMobileAppConfig(id: number | string): AxiosPromise<MobileAppConfig>;

  getMobileAppConfig(
    id: number | string,
    params?: DonesafeApiOptions<MobileAppConfig>,
    config?: ApiRequestConfig
  ): AxiosPromise<MobileAppConfig>;

  getMobileAppConfigs(params?: DonesafeIndexApiOptions<MobileAppConfig>, config?: ApiRequestConfig): AxiosPromise<MobileAppConfig[]>;

  updateMobileAppConfig(id: number | string, entity: Partial<MobileAppConfig>): AxiosPromise<MobileAppConfig>;
}

export const createMobileAppConfigsApi = (api: AxiosInstance, path = '/api/mobile_app_configs'): DonesafeMobileAppConfigsApi => {
  return {
    getMobileAppConfigs: (params, config) => api.get(path, { params, ...config }),
    getMobileAppConfig: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createMobileAppConfig: (entity) => api.post(path, entity),
    updateMobileAppConfig: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteMobileAppConfig: (id) => api.delete(`${path}/${id}`),
  };
};
