import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ModuleTabExport } from '@app/models/module-tab-export';
import type { ModuleTab } from '@app/models/module-tab';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';

export interface DonesafeModuleTabsApi {
  createModuleTab(entity: DonesafeApiOptions<ModuleTab>, config?: ApiRequestConfig): AxiosPromise<ModuleTab>;
  deleteModuleTab(id: ModuleTab['id'], params?: DonesafeApiOptions<ModuleTab>): AxiosPromise<ModuleTab>;
  getModuleTab(
    id: ModuleTab['id'],
    params?: DonesafeApiOptions<ModuleTab>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ModuleTab>;
  getModuleTabExport(params: ModuleTabExport): AxiosPromise<Blob>;
  getModuleTabs(options?: DonesafeIndexApiOptions<ModuleTab>, config?: ApiRequestConfig): AxiosPromise<ModuleTab[]>;
  updateModuleTab(
    id: ModuleTab['id'],
    entity: Partial<ModuleTab>,
    params?: DonesafeApiOptions<ModuleTab>,
    config?: ApiRequestConfig
  ): AxiosPromise<ModuleTab>;
  updateModuleTabIndexes(params: UpdateIndexParams): AxiosPromise<ModuleTab[]>;
}

export const createModuleTabsApi = (api: AxiosInstance, path = '/api/module_tabs'): DonesafeModuleTabsApi => {
  const moduleTabsApi: DonesafeModuleTabsApi = {
    getModuleTabExport: (params) => api.get(`${path}/${params.id}/export`, { params, responseType: 'blob' }),
    getModuleTabs: (params, config) => api.get(path, { params, ...config }),
    getModuleTab: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateModuleTab: (id, entity, params, config) => api.put(`${path}/${id}`, entity, { params, ...config }),
    deleteModuleTab: (id, params) => api.delete(`${path}/${id}`, { params }),
    createModuleTab: (entity, config) => api.post(path, entity, { ...config }),
    updateModuleTabIndexes: (params) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...moduleTabsApi,
    getModuleTab: convertToJoinedRequest<ModuleTab>(moduleTabsApi.getModuleTab, moduleTabsApi.getModuleTabs),
  };
};
