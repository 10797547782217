import { AdminPermissionFeature } from '@app/models/admin-permission';
import type { CurrentUser } from '@app/models/current-user';
import { useAccountStore } from '@app/stores/account';
import { castArray } from 'lodash';

export const featureEnabled = (
  user: Nullable<Pick<CurrentUser, 'admin' | 'admin_permission_features'>>,
  features: AdminPermissionFeature | AdminPermissionFeature[],
  allow: { admin?: boolean } = {}
): boolean => {
  const { admin } = { admin: true, ...allow };
  const accountStore = useAccountStore();
  return (
    (admin && !!user?.admin) ||
    castArray(features).some((f) => {
      if (f === AdminPermissionFeature.module_config) {
        return !!user?.admin_permission_features?.includes(f) && accountStore.moduleConfigForNonTechAdmins;
      }

      return !!user?.admin_permission_features?.includes(f);
    })
  );
};
