import { Vue, Component } from 'vue-property-decorator';
import { toaster } from '@app/utils/toaster';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultErrorCb = (err: any, text: string): void => {
  console.error(err.data?.error || err);
  toaster({ text, position: 'top-right', icon: 'error' });
};

@Component
export default class Blocking extends Vue {
  blocked = false;
  loading = false;

  async blocking<T>(cb: () => Promise<T>, { exclusive = false, loading = false, errorCb = defaultErrorCb } = {}): Promise<T | null> {
    if (exclusive && this.blocked) return null;
    if (loading) this.loading = true;

    this.blocked = true;
    let ret = null;

    try {
      ret = await cb();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const { status, statusText, data } = err;
      let text;

      switch (status) {
        case undefined: // Custom js error class, eg: CustomError
          text = err.message || err.toString();
          break;
        case 0: // no internet
          text = this.$t('components.admin.blocking.connection_error');
          break;
        case 502:
        case 504:
          text = statusText;
          break;
        default:
          text = data?.error || this.$t('app.labels.something_went_wrong');
          break;
      }
      errorCb(err, text);
    }

    if (loading) this.loading = false;

    this.blocked = false;
    return ret;
  }
}
