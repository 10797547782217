import type { ExpensableRecordBudgetCategory } from '@app/models/expensable-record-budget-category';
import type { ExpensableRecordBudgetCategoryValue } from '@app/models/expensable-record-budget-category-value';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';

export interface DonesafeExpensableRecordBudgetCategoriesApi {
  createExpensableRecordBudgetCategory(
    entity: Partial<Omit<ExpensableRecordBudgetCategory, 'record_budget_category_values'>> & {
      record_budget_category_values?: Pick<
        ExpensableRecordBudgetCategoryValue,
        'expected_value' | 'expensable_record_budget_behaviour_id'
      >[];
    }
  ): AxiosPromise<ExpensableRecordBudgetCategory>;
  getExpensableRecordBudgetCategories(
    params: DonesafeIndexApiOptions<ExpensableRecordBudgetCategory, { visible?: true }>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableRecordBudgetCategory[]>;

  updateExpensableRecordBudgetCategory(
    id: ExpensableRecordBudgetCategory['id'],
    entity: Partial<Omit<ExpensableRecordBudgetCategory, 'record_budget_category_values'>> & {
      record_budget_category_values?: Pick<
        ExpensableRecordBudgetCategoryValue,
        'expected_value' | 'expensable_record_budget_behaviour_id'
      >[];
    }
  ): AxiosPromise<ExpensableRecordBudgetCategory>;
}

export const createExpensableRecordBudgetCategoriesApi = (
  api: AxiosInstance,
  path = '/api/record_budget_categories'
): DonesafeExpensableRecordBudgetCategoriesApi => {
  return {
    updateExpensableRecordBudgetCategory: (id, entity) => api.put(`${path}/${id}`, entity),
    createExpensableRecordBudgetCategory: (entity) => api.post(path, entity),
    getExpensableRecordBudgetCategories: (params, config) => api.get(path, { params, ...config }),
  };
};
