import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { EmploymentType } from '@app/models/employment-type';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeEmploymentTypesApi {
  createEmploymentType(employmentType: Partial<EmploymentType>): AxiosPromise<EmploymentType>;
  getEmploymentType(
    id: number | string,
    params?: DonesafeApiOptions<EmploymentType>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<EmploymentType>;
  getEmploymentTypes(options: DonesafeIndexApiOptions<EmploymentType>, config?: ApiRequestConfig): AxiosPromise<EmploymentType[]>;
  updateEmploymentType(id: number | string, employmentType?: Partial<EmploymentType>): AxiosPromise<EmploymentType>;
}

export const createEmploymentTypesApi = (api: AxiosInstance, path = '/api/employment_types'): DonesafeEmploymentTypesApi => {
  const employmentTypesApi: DonesafeEmploymentTypesApi = {
    getEmploymentTypes: (params, config) => api.get(path, { params, ...config }),
    getEmploymentType: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createEmploymentType: (employmentType) => api.post(path, employmentType),
    updateEmploymentType: (id, employmentType) => api.put(`${path}/${id}`, employmentType),
  };

  return {
    ...employmentTypesApi,
    getEmploymentType: convertToJoinedRequest<EmploymentType>(employmentTypesApi.getEmploymentType, employmentTypesApi.getEmploymentTypes),
  };
};
