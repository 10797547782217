import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Area } from '@app/models/area';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeAreasApi {
  getArea(id: number | string, params?: DonesafeApiOptions<Area>, config?: ApiRequestConfig): AxiosPromise<Area>;

  getAreas(options: DonesafeIndexApiOptions<Area>, config?: ApiRequestConfig): AxiosPromise<Area[]>;
}

export const createAreasApi = (api: AxiosInstance, path = '/api/areas'): DonesafeAreasApi => {
  return {
    getAreas: (params, config) => api.get(path, { params, ...config }),
    getArea: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
