import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ContributingType } from '@app/models/contributing-type';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeContributingTypesApi {
  getContributingTypes(options: DonesafeIndexApiOptions<ContributingType>, config?: ApiRequestConfig): AxiosPromise<ContributingType[]>;
}

export const createContributingTypesApi = (api: AxiosInstance, path = '/api/contributing_types'): DonesafeContributingTypesApi => {
  return {
    getContributingTypes: (params, config) => api.get(path, { params, ...config }),
  };
};
