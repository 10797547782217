import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { Procedure } from '@app/models/procedure';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { TenantUser } from '@app/models/tenant-user';

export interface ProcedureIndexExtraFilters {
  scope?: 'all' | 'team' | 'wider_team' | 'mine';
}
export interface DonesafeProceduresApi {
  getProcedure(
    id: number,
    params?: DonesafeApiOptions<Procedure>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Procedure>;
  getProcedureCategoryFilters(config?: ApiRequestConfig): AxiosPromise<[string, string][]>;
  getProcedures(
    params?: DonesafeIndexApiOptions<Procedure, ProcedureIndexExtraFilters>,
    config?: ApiRequestConfig
  ): AxiosPromise<Procedure[]>;
  updateUserAcknowledgements(
    params?: DonesafeApiOptions<{ user_ids: (number | string)[] }>,
    config?: ApiRequestConfig
  ): AxiosPromise<TenantUser[]>;
}

export const createProceduresApi = (api: AxiosInstance, path = '/api/procedures'): DonesafeProceduresApi => {
  const proceduresApi: DonesafeProceduresApi = {
    getProcedure: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getProcedures: (params, config) => api.get(path, { params, ...config }),
    updateUserAcknowledgements: (params, config) => api.post(`${path}/kb_acknowledgements`, { ...params, ...config }),
    getProcedureCategoryFilters: (config) => api.get(`${path}/filters/procedure_category`, { ...config }),
  };

  return {
    ...proceduresApi,
    getProcedure: convertToJoinedRequest<Procedure>(proceduresApi.getProcedure, proceduresApi.getProcedures),
  };
};
