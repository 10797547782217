import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RegulatoryReportConfig } from '@app/models/regulatory-report-config';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeRegulatoryReportConfigsApi {
  getRegulatoryReportConfig(
    id: number,
    params?: DonesafeApiOptions<RegulatoryReportConfig>,
    config?: ApiRequestConfig
  ): AxiosPromise<RegulatoryReportConfig>;

  getRegulatoryReportConfigs(
    options: DonesafeIndexApiOptions<RegulatoryReportConfig>,
    config?: ApiRequestConfig
  ): AxiosPromise<RegulatoryReportConfig[]>;

  updateRegulatoryReportConfig(id: number, entity: Partial<RegulatoryReportConfig>): AxiosPromise<RegulatoryReportConfig>;
}

export const createRegulatoryReportConfigsApi = (
  api: AxiosInstance,
  path = '/api/regulatory_report_configs'
): DonesafeRegulatoryReportConfigsApi => {
  return {
    getRegulatoryReportConfigs: (params, config) => api.get(path, { params, ...config }),
    updateRegulatoryReportConfig: (id, entity) => api.put(`${path}/${id}`, entity),
    getRegulatoryReportConfig: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
