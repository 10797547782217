import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { DonesafeApiOptions, ApiRequestConfig, DonesafeIndexApiOptions, ApiRequestJoinConfig } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { LocationTagCount } from '@app/models/location-tag-count';

type UserReportSubscriptionResult = {
  subscribed: boolean;
};
export interface DonesafeLocationTagCountsApi {
  createLocationTagCount(entity: Partial<LocationTagCount>): AxiosPromise<LocationTagCount>;
  getLocationTagCount(
    id: number,
    params?: DonesafeApiOptions<LocationTagCount>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<LocationTagCount>;

  getLocationTagCounts(params: DonesafeIndexApiOptions<LocationTagCount>, config?: ApiRequestConfig): AxiosPromise<LocationTagCount[]>;

  reportSubscribe(): AxiosPromise<UserReportSubscriptionResult>;
  reportUnsubscribe(): AxiosPromise<UserReportSubscriptionResult>;

  updateLocationTagCount(
    id: number,

    entity?: Partial<LocationTagCount>
  ): AxiosPromise<LocationTagCount>;
}

export const createLocationTagCountsApi = (api: AxiosInstance, path = '/api/location_tag_counts'): DonesafeLocationTagCountsApi => {
  const locationTagCountsApi: DonesafeLocationTagCountsApi = {
    getLocationTagCounts: (params, config) => api.get(path, { params, ...config }),
    createLocationTagCount: (entity) => api.post(`${path}`, entity),
    reportSubscribe: () => api.post(`${path}/report_subscribe`),
    updateLocationTagCount: (id, entity) => api.put(`${path}/${id}`, entity),
    getLocationTagCount: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    reportUnsubscribe: () => api.post(`${path}/report_unsubscribe`),
  };
  return {
    ...locationTagCountsApi,
    getLocationTagCount: convertToJoinedRequest(locationTagCountsApi.getLocationTagCount, locationTagCountsApi.getLocationTagCounts),
  };
};
