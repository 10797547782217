import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ContributingSubtype } from '@app/models/contributing-subtype';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeContributingSubtypesApi {
  getContributingSubtypes(
    options: DonesafeIndexApiOptions<ContributingSubtype>,
    config?: ApiRequestConfig
  ): AxiosPromise<ContributingSubtype[]>;
}

export const createContributingSubtypesApi = (api: AxiosInstance, path = '/api/contributing_subtypes'): DonesafeContributingSubtypesApi => {
  return {
    getContributingSubtypes: (params, config) => api.get(path, { params, ...config }),
  };
};
