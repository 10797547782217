import type { BaseEntity } from './base-entity';

export enum AdminPermissionFeature {
  hybrid_modules = 'hybrid_modules',
  look_and_feel = 'look_and_feel',
  module_config = 'module_config',
  record_importing = 'record_importing',
  regulatory_reporting = 'regulatory_reporting',
  reporting = 'reporting',
  roles_and_tags = 'roles_and_tags',
  system_administration = 'system_administration',
  translations = 'translations',
  users_locations_organizations = 'users_locations_organizations',
}

export interface AdminPermission extends BaseEntity {
  feature: AdminPermissionFeature;
  user_id: number;
}
