import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RegulatoryReport } from '@app/models/regulatory-report';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeRegulatoryReportsApi {
  createRegulatoryReport(entity: Partial<RegulatoryReport>): AxiosPromise<RegulatoryReport>;
  getRegulatoryReport(id: number, params: DonesafeApiOptions<RegulatoryReport>, config?: ApiRequestConfig): AxiosPromise<RegulatoryReport>;

  getRegulatoryReports(options: DonesafeIndexApiOptions<RegulatoryReport>, config?: ApiRequestConfig): AxiosPromise<RegulatoryReport[]>;

  zipRegulatoryReportAttachments(
    id: number,
    params?: DonesafeApiOptions<RegulatoryReport>,
    config?: ApiRequestConfig
  ): AxiosPromise<RegulatoryReport>;
}

export const createRegulatoryReportsApi = (api: AxiosInstance, path = '/api/regulatory_reports'): DonesafeRegulatoryReportsApi => {
  return {
    getRegulatoryReports: (params, config) => api.get(path, { params, ...config }),
    getRegulatoryReport: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createRegulatoryReport: (entity) => api.post(path, entity),
    zipRegulatoryReportAttachments: (id, params, config) => api.post(`${path}/${id}/create_zip`, { params, ...config }),
  };
};
