import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { TabSectionGroup } from '@app/models/tab-section-group';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeTabSectionGroupsApi {
  createTabSectionGroup(entity: Partial<TabSectionGroup>): AxiosPromise<TabSectionGroup>;

  getTabSectionGroup(
    id: number | string,
    params?: DonesafeApiOptions<TabSectionGroup>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<TabSectionGroup>;

  getTabSectionGroups(options: DonesafeIndexApiOptions<TabSectionGroup>, config?: ApiRequestConfig): AxiosPromise<TabSectionGroup[]>;

  updateTabSectionGroup(id: number | string, entity: Partial<TabSectionGroup>): AxiosPromise<TabSectionGroup>;
}

export const createTabSectionGroupsApi = (api: AxiosInstance, path = '/api/tab_section_groups'): DonesafeTabSectionGroupsApi => {
  const tabSectionGroupsApi: DonesafeTabSectionGroupsApi = {
    createTabSectionGroup: (entity) => api.post(path, entity),
    getTabSectionGroups: (params, config) => api.get(path, { params, ...config }),
    getTabSectionGroup: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateTabSectionGroup: (id, entity) => api.put(`${path}/${id}`, entity),
  };

  return {
    ...tabSectionGroupsApi,
    getTabSectionGroup: convertToJoinedRequest<TabSectionGroup>(
      tabSectionGroupsApi.getTabSectionGroup,
      tabSectionGroupsApi.getTabSectionGroups
    ),
  };
};
