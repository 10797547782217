import type { Vue } from 'vue-property-decorator';

export enum ConsoleLogLevel {
  disabled = 0,
  warn = 1,
  info = 2,
  debug = 3,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const $$warn = function (vue: Vue, ...rest: any[]) {
  vue.$isDevelopment && vue.$consoleLogLevel >= ConsoleLogLevel.warn && console.warn(...rest);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const $$info = function (vue: Vue, ...rest: any[]) {
  // eslint-disable-next-line no-console
  vue.$isDevelopment && vue.$consoleLogLevel >= ConsoleLogLevel.info && console.info(...rest);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const $$debug = function (vue: Vue, ...rest: any[]) {
  // eslint-disable-next-line no-console
  vue.$isDevelopment && vue.$consoleLogLevel >= ConsoleLogLevel.debug && console.debug(...rest);
};
