import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SuperImport } from '@app/models/super-import';
import type { SuperImportError } from '@app/models/super-import-error';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

type Id = number | string;

export interface DonesafeSuperImportsApi {
  cancelImport(id: Id): AxiosPromise;
  createImport(entity: Partial<SuperImport & { files: string[] }>): AxiosPromise<SuperImport>;
  deleteImport(id: Id): AxiosPromise<SuperImport>;
  getSuperImportErrors(
    id: Id,
    params?: DonesafeIndexApiOptions<SuperImportError>,
    config?: ApiRequestConfig
  ): AxiosPromise<SuperImportError[]>;
  getSuperImportErrorsCSV(id: Id, params?: DonesafeIndexApiOptions<SuperImportError>, config?: ApiRequestConfig): AxiosPromise;
  getSuperImports(options: DonesafeIndexApiOptions<SuperImport, { type?: string }>, config?: ApiRequestConfig): AxiosPromise<SuperImport[]>;

  pauseImport(id: Id): AxiosPromise;
  resumeImport(id: Id): AxiosPromise;
}

export const createSuperImportsApi = (api: AxiosInstance, path = '/api/super_imports'): DonesafeSuperImportsApi => {
  return {
    getSuperImports: (params, config) => api.get(path, { params, ...config }),
    getSuperImportErrors: (id, params = {}, config = {}) => api.get(`${path}/${id}/errors`, { ...config, params }),
    getSuperImportErrorsCSV: (id, params = {}, config = {}) =>
      api.get(`${path}/${id}/errors.csv`, { ...config, params, headers: { Accept: 'application/octet-stream' }, responseType: 'stream' }),

    pauseImport: (id) => api.put(`${path}/${id}/pause`, {}),
    resumeImport: (id) => api.put(`${path}/${id}/resume`, {}),
    cancelImport: (id) => api.put(`${path}/${id}/cancel`, {}),
    createImport: (entity) => api.post(path, entity),
    deleteImport: (id) => api.delete(`${path}/${id}`),
  };
};
