import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { SubFormQuestion } from '@app/models/sub-form-question';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';

export interface LookupSourceOption {
  code: string;
  disabled_options?: string[];
  options: LookupOption[];
  title: string;
  type: 'user_attribute' | 'sub_form_question_code' | 'location_attribute' | 'organization_attribute';
}

export interface LookupOption {
  code: string;
  hidden?: boolean;
  title: string;
}

export interface DonesafeSubFormQuestionsApiExtraOptions {
  config?: { main_form_id?: number | string; scorm_course_id?: number | string };
}

export interface PiiFieldQuestion {
  code: string;
  id: number;
  sub_form_id: number;
}

export interface DonesafeSubFormQuestionsApi {
  createSubFormQuestion(data: Partial<SubFormQuestion>, config?: ApiRequestConfig): AxiosPromise<SubFormQuestion>;

  deleteSubFormQuestion(id: SubFormQuestion['id'], config?: ApiRequestConfig): AxiosPromise<void>;

  getSubFormQuestion(
    id: SubFormQuestion['id'],
    params?: DonesafeApiOptions<SubFormQuestion>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubFormQuestion>;
  getSubFormQuestionLookupSourceOptions(
    id: SubFormQuestion['id'],
    params?: { advanced?: boolean; code?: string },
    config?: ApiRequestConfig
  ): AxiosPromise<LookupSourceOption[]>;

  getSubFormQuestions(
    params: DonesafeIndexApiOptions<SubFormQuestion, DonesafeSubFormQuestionsApiExtraOptions>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormQuestion[]>;

  getSubFormQuestionsWithSharedFilters(
    options: DonesafeIndexApiOptions<SubFormQuestion, DonesafeSubFormQuestionsApiExtraOptions>,
    filterKey: keyof SubFormQuestion,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormQuestion[]>;

  hasSubFormQuestionResponses(id: SubFormQuestion['id'], config?: ApiRequestConfig): AxiosPromise<{ result: boolean }>;

  updateSubFormQuestion(
    id: SubFormQuestion['id'],
    data: Partial<SubFormQuestion>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormQuestion>;

  updateSubFormQuestionIndexes(
    data: UpdateIndexParams,
    options?: DonesafeIndexApiOptions<SubFormQuestion>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormQuestion[]>;
}

export const createSubFormQuestionsApi = (api: AxiosInstance, path = '/api/sub_form_questions'): DonesafeSubFormQuestionsApi => {
  const subFormQuestionsApi: Omit<DonesafeSubFormQuestionsApi, 'getSubFormQuestionsWithSharedFilters'> = {
    getSubFormQuestions: (params, config) => api.get(path, { params, ...config }),
    getSubFormQuestion: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    hasSubFormQuestionResponses: (id, config) => api.get(`${path}/${id}/has_responses`, { ...config }),
    updateSubFormQuestionIndexes: (data, options, config) => api.post(`${path}/update_indexes`, { ...data, ...options, ...config }),
    updateSubFormQuestion: (id, data, config) => api.put(`${path}/${id}`, data, config),
    createSubFormQuestion: (data, config) => api.post(path, data, config),
    deleteSubFormQuestion: (id) => api.delete(`${path}/${id}`),
    getSubFormQuestionLookupSourceOptions: (id, params, config) => api.get(`${path}/${id}/lookup_source_options`, { params, ...config }),
  };

  return {
    ...subFormQuestionsApi,
    getSubFormQuestion: convertToJoinedRequest<SubFormQuestion>(
      subFormQuestionsApi.getSubFormQuestion,
      subFormQuestionsApi.getSubFormQuestions
    ),
    getSubFormQuestionsWithSharedFilters: sharedFilterListRequest(subFormQuestionsApi.getSubFormQuestions),
  };
};
