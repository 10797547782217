import type { TenantUser } from './models/tenant-user';

export const RELATED_RECORDS_PREFIX = 'related_records:';
export const RELATIONSHIP_PREFIX = 'relationship:';
export const API_VERSION_HEADER = 'v1-ui';

export const API_NULL = 'NULL';

export const VISIBLE_QUESTIONS_SELECTOR = '.question-block:not(.hidden)';
export const CLEAR_FILTERS_ID = '--clear-filters-id--';
export const RE_FETCH_DEBOUNCE_TIME = 500;
export const TCF_SAVE_AND_INSERT_BROADCAST_TIME = 500;

export const POSSIBLE_PII_FIELDS: (keyof TenantUser)[] = [
  'date_of_birth',
  'employment_type_id',
  'start_date',
  'end_date',
  'mobile',
  'language',
  'gender_identity_id',
];

export const extraPayItems = [
  'overtime',
  'shift_allowance',
  'commission',
  'piece_tally_bonus',
  'motor_vehicle_allowance',
  'residential_allowance',
  'health_insurance',
  'education_fees',
];

export const MAIN_FORM_MODULE_NAME = 'StarterForms';
export const ACTIVITY_CONCEPT = 'Activity';

export const WRAP_TEXT_COLUMN_WIDTH = 'auto';

export const LANGUAGE_CODES: Record<string, string> = {
  cs: 'cs',
  de: 'de',
  en_au: 'en',
  en_gb: 'en',
  en_us: 'en',
  es: 'es',
  fr: 'fr',
  id_id: 'id',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  nl: 'nl',
  pl: 'pl',
  pt: 'pt',
  ru: 'ru',
  th: 'th',
  vi: 'vi',
  zh_cn: 'zh_CN',
  zh_hk: 'zh_CN',
};
