import type { BaseEntity } from '@app/models/base-entity';
import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { IndexFilter } from '@app/models/index-filter';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions, ApiRequestJoinConfig } from '@app/services/donesafe-api-utils';

export interface DonesafeIndexFiltersApi {
  createIndexFilter<T extends BaseEntity = BaseEntity, F = {}>(
    indexFilter: Partial<IndexFilter<T, F> & { global?: boolean }>
  ): AxiosPromise<IndexFilter<T, F>>;

  deleteIndexFilter<T extends BaseEntity = BaseEntity, F = {}>(id: number): AxiosPromise<IndexFilter<T, F>>;

  getIndexFilter<T extends BaseEntity = BaseEntity, F = {}>(
    id: BaseEntity['id'],
    params?: DonesafeApiOptions<IndexFilter<T, F>>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<IndexFilter<T, F>>;

  getIndexFilters<T extends BaseEntity = BaseEntity, F = {}>(
    options: DonesafeIndexApiOptions<IndexFilter<T, F>>,
    config?: ApiRequestConfig
  ): AxiosPromise<IndexFilter<T, F>[]>;

  updateIndexFilter<T extends BaseEntity = BaseEntity, F = {}>(
    id: BaseEntity['id'],
    indexFilter?: Partial<IndexFilter<T, F>>
  ): AxiosPromise<IndexFilter<T, F>>;
}

export const createIndexFiltersApi = (api: AxiosInstance, path = '/api/index_filters'): DonesafeIndexFiltersApi => {
  const indexApi: DonesafeIndexFiltersApi = {
    getIndexFilters: (params, config) => api.get(path, { params, ...config }),
    createIndexFilter: (indexFilter) => api.post(path, indexFilter),
    updateIndexFilter: (id, indexFilter) => api.put(`${path}/${id}`, indexFilter),
    deleteIndexFilter: (id) => api.delete(`${path}/${id}`),
    getIndexFilter: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...indexApi,
    getIndexFilter: convertToJoinedRequest(indexApi.getIndexFilter, indexApi.getIndexFilters),
  };
};
