import type { AxiosInstance, AxiosPromise } from 'axios';
import type { UserCollection } from '@app/models/user-collection';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeUserCollectionsApi {
  createUserCollection(params: UserCollection): AxiosPromise<UserCollection>;

  getUserCollection(
    id: number | string,
    params?: DonesafeApiOptions<UserCollection>,
    config?: ApiRequestConfig
  ): AxiosPromise<UserCollection>;

  getUserCollections(options: DonesafeIndexApiOptions<UserCollection>, config?: ApiRequestConfig): AxiosPromise<UserCollection[]>;
  updateUserCollection(id: number | string, params: Partial<UserCollection>): AxiosPromise<UserCollection>;
}

export const createUserCollectionsApi = (api: AxiosInstance, path = '/api/user_collections'): DonesafeUserCollectionsApi => {
  return {
    getUserCollections: (params, config) => api.get(path, { params, ...config }),

    getUserCollection: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),

    updateUserCollection: (id, data) => api.put(`${path}/${id}`, data),
    createUserCollection: (data) => api.post(path, data),
  };
};
