import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ScoringGroup } from '@app/models/scoring-group';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';

export interface DonesafeScoringGroupsApi {
  createScoringGroup(entity: Partial<ScoringGroup>): AxiosPromise<ScoringGroup>;

  getScoringGroup(
    id: number | string,
    params?: DonesafeApiOptions<ScoringGroup>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ScoringGroup>;

  getScoringGroups(options: DonesafeIndexApiOptions<ScoringGroup>, config?: ApiRequestConfig): AxiosPromise<ScoringGroup[]>;

  updateScoringGroup(id: number | string, entity: Partial<ScoringGroup>): AxiosPromise<ScoringGroup>;

  updateScoringGroupIndexes(params: UpdateIndexParams): AxiosPromise<ScoringGroup[]>;
}

export const createScoringGroupsApi = (api: AxiosInstance, path = '/api/scoring_groups'): DonesafeScoringGroupsApi => {
  const scoringGroupsApi: DonesafeScoringGroupsApi = {
    createScoringGroup: (entity) => api.post(path, entity),
    getScoringGroups: (params, config) => api.get(path, { params, ...config }),
    getScoringGroup: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateScoringGroup: (id, entity) => api.put(`${path}/${id}`, entity),
    updateScoringGroupIndexes: (params) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...scoringGroupsApi,
    getScoringGroup: convertToJoinedRequest<ScoringGroup>(scoringGroupsApi.getScoringGroup, scoringGroupsApi.getScoringGroups),
  };
};
