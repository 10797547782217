import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SubFormListsTabSectionGroup } from '@app/models/sub-form-lists-tab-section-groups';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeSubFormListsTabSectionGroupsApi {
  createSubFormListsTabSectionGroup(entity: Partial<SubFormListsTabSectionGroup>): AxiosPromise<SubFormListsTabSectionGroup>;
  deleteSubFormListsTabSectionGroup(id: number | string): AxiosPromise<SubFormListsTabSectionGroup>;
  getSubFormListsTabSectionGroups(
    options: DonesafeIndexApiOptions<SubFormListsTabSectionGroup>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormListsTabSectionGroup[]>;
}

export const createSubFormListsTabSectionGroupsApi = (
  api: AxiosInstance,
  path = '/api/sub_form_lists_tab_section_groups'
): DonesafeSubFormListsTabSectionGroupsApi => {
  return {
    createSubFormListsTabSectionGroup: (entity) => api.post(path, entity),
    getSubFormListsTabSectionGroups: (params, config) => api.get(path, { params, ...config }),
    deleteSubFormListsTabSectionGroup: (id) => api.delete(`${path}/${id}`),
  };
};
