import type { AxiosInstance, AxiosPromise } from 'axios';
import type { AppBundleValidationResponse } from '@app/models/app-bundles/validation-response';
import type { AppBundle } from '@app/models/app-bundle';
import type { AppBundleInstallation } from '@app/models/app-bundles/installation';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeAppBundlesApi {
  createAppBundle(entity: Partial<AppBundle>): AxiosPromise<AppBundle>;

  createAppBundleInstallation(data: FormData): AxiosPromise<AppBundleInstallation>;

  deleteAppBundleInstallation(id: string): AxiosPromise<AppBundleInstallation>;

  downloadAppBundleBlob(id: string): AxiosPromise;

  getAppBundle(id: string, params?: DonesafeApiOptions<AppBundle>, config?: ApiRequestConfig): AxiosPromise<AppBundle>;

  getAppBundleInstallations(
    options: DonesafeIndexApiOptions<AppBundleInstallation>,
    config?: ApiRequestConfig
  ): AxiosPromise<AppBundleInstallation[]>;

  getAppBundles(options: DonesafeIndexApiOptions<AppBundle>, config?: ApiRequestConfig): AxiosPromise<AppBundle[]>;

  restartAppBundleInstallation(id: string): AxiosPromise;

  startCopyAppBundleInstallation(installation: AppBundleInstallation): AxiosPromise<AppBundleInstallation>;

  updateAppBundle(id: string, entity: Partial<AppBundle>, params?: DonesafeApiOptions<AppBundle>): AxiosPromise<AppBundle>;

  validateAppBundle(id: string): AxiosPromise<AppBundleValidationResponse>;
}

const installationsPath = '/api/app_bundle_installations'; // TODO: move to a separate file

export const createAppBundlesApi = (api: AxiosInstance, path = '/api/app_bundles'): DonesafeAppBundlesApi => {
  return {
    getAppBundles: (params, config) => api.get(path, { params, ...config }),
    getAppBundle: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createAppBundle: (entity) => api.post(path, entity),
    updateAppBundle: (id, entity, params) => api.put(`${path}/${id}`, entity, { params }),
    downloadAppBundleBlob: (id) => api.get(`${path}/${id}/blob`),
    validateAppBundle: (id) => api.get(`${path}/${id}/validate`),
    // AppBundle/Installations
    getAppBundleInstallations: (params, config) => api.get(installationsPath, { params, ...config }),
    createAppBundleInstallation: (data: FormData) =>
      api.post(`${installationsPath}/by_file`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
    startCopyAppBundleInstallation: (installation) => api.post(`${installationsPath}/${installation.id}/start`, installation),
    deleteAppBundleInstallation: (id) => api.delete(`${installationsPath}/${id}`),
    restartAppBundleInstallation: (id) => api.post(`${installationsPath}/${id}/restart`),
  };
};
