import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ExtendedPermission } from '@app/models/extended-permission';
import type { Role } from '@app/models/role';
import type { RoleModuleName } from '@app/models/role-module-name';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { PartialNullBy } from '@app/utils/types/partial';

type CreateUpdateRole = Partial<
  PartialNullBy<Omit<Role, 'extended_permissions' | 'role_module_names'>, 'managerial_hierarchy_access_level'>
> &
  ExtraCreateUpdateParams;
type ExtraCreateUpdateParams = {
  extended_permissions?: Pick<ExtendedPermission, 'concept_name' | 'name'>[];
  permissions_ids?: number[];
  role_module_names?: Pick<RoleModuleName, 'configuration_type' | 'module_name_id' | 'role_id' | 'role_profile_id'>[];
  role_profile_ids?: number[];
};

export interface DonesafeRolesApi {
  createRole(entity: CreateUpdateRole, params?: DonesafeApiOptions<Role>): AxiosPromise<Role>;
  getRole(id: Role['id'], params?: DonesafeApiOptions<Role>, config?: ApiRequestConfig & ApiRequestJoinConfig): AxiosPromise<Role>;
  getRoles(params?: DonesafeIndexApiOptions<Role>, config?: ApiRequestConfig): AxiosPromise<Role[]>;
  updateRole(id: Role['id'], entity: CreateUpdateRole, params?: DonesafeApiOptions<Role>): AxiosPromise<Role>;
}

export const createRolesApi = (api: AxiosInstance, path = '/api/roles'): DonesafeRolesApi => {
  const rolesApi: DonesafeRolesApi = {
    getRoles: (params, config) => api.get(path, { params, ...config }),
    getRole: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateRole: (id, entity, params) => api.put(`${path}/${id}`, { ...entity }, { params }),
    createRole: (entity, params) => api.post(path, { ...entity }, { params }),
  };

  return {
    ...rolesApi,
    getRole: convertToJoinedRequest(rolesApi.getRole, rolesApi.getRoles),
  };
};
