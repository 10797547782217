import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { FieldType } from '@app/models/sub-form-question';
import type { SubForm } from '@app/models/sub-form';
import type {
  ApiRequestConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  ApiRequestJoinConfig,
  FilterValue,
} from '@app/services/donesafe-api-utils';
import type { RequiredBy } from '@app/utils/types/required-by';

export interface DonesafeSubFormsApi {
  createSubForm(subForm: RequiredBy<Partial<SubForm>, 'module_name' | 'title'>): AxiosPromise<SubForm>;
  exportSubForm(id: number): AxiosPromise<Blob>;
  getSubForm(
    id: SubForm['id'],
    params?: DonesafeApiOptions<SubForm>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubForm>;
  getSubFormDefaultValueTypeOptions(id: SubForm['id'], fieldType: FieldType, config?: ApiRequestConfig): AxiosPromise<string[]>;
  getSubFormFieldTypeOptions(id: SubForm['id'], config?: ApiRequestConfig): AxiosPromise<FieldType[]>;
  getSubForms(params: DonesafeIndexApiOptions<SubForm>, config?: ApiRequestConfig): AxiosPromise<SubForm[]>;
  getSubFormsWithSharedFilters(
    options: DonesafeIndexApiOptions<SubForm>,
    filterKey: keyof SubForm,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<SubForm[]>;
  updateSubForm(
    id: SubForm['id'],
    entity: Partial<SubForm>,
    params?: DonesafeApiOptions<SubForm>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubForm>;
}

export const createSubFormsApi = (api: AxiosInstance, path = '/api/sub_forms'): DonesafeSubFormsApi => {
  const subFormsApi: Omit<DonesafeSubFormsApi, 'getSubFormsWithSharedFilters'> = {
    createSubForm: (subForm) => api.post(path, subForm),
    exportSubForm: (id) => api.get(`${path}/${id}/export`, { responseType: 'blob' }),
    getSubFormFieldTypeOptions: (id, config) => api.get(`${path}/${id}/field_type_options`, { ...config }),
    getSubFormDefaultValueTypeOptions: (id, field_type, config) =>
      api.get(`${path}/${id}/default_value_type_options`, { params: { field_type }, ...config }),
    getSubForm: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getSubForms: (params, config) => api.get(path, { params, ...config }),
    updateSubForm: (id, entity, params, config) => api.put(`${path}/${id}`, entity, { params, ...config }),
  };

  return {
    ...subFormsApi,
    getSubForm: convertToJoinedRequest<SubForm>(subFormsApi.getSubForm, subFormsApi.getSubForms),
    getSubFormsWithSharedFilters: sharedFilterListRequest(subFormsApi.getSubForms),
  };
};
