import { defineStore } from 'pinia';
import type { CurrentUser } from '@app/models/current-user';
import type { ProfilePermission } from '@app/models/role';
import type { SpecialPermission } from '@app/models/extended-permission';
import type { TenantUser } from '@app/models/tenant-user';
import { AdminPermissionFeature } from '@app/models/admin-permission';
import { featureEnabled } from '@app/utils/feature-enabled';

export type CurrentUserStoreData = ReturnType<typeof useCurrentUserStore>['data'];

export const useCurrentUserStore = defineStore('currentUser', {
  state: () => ({
    data: (window?.DONESAFE?.current_user || null) as CurrentUser | null,
  }),
  getters: {
    canViewActivity: ({ data }) => !!data?.acl?.module?.Activity?.view,
    canCreateActivity: ({ data }) => !!data?.acl?.module?.Activity?.create,
    isTechAdmin: ({ data }) => !!data?.admin,
    canChangePassword: ({ data }) => !!data?.admin || !!data?.can_change_password,
    isTechInternalAdmin: ({ data }) => !!data?.admin && !!data?.internal, // this can be removed as admin are only internals now
    isDefaultTechAdmin: ({ data }) => !!data?.technical_admin,
    isDefaultOrTechAdmin: ({ data }) => !!data?.technical_admin || !!data?.admin,
    isSubscribedToLocationTagReports: ({ data }) => !!data?.subscribed_to_location_tag_report,
    isInternal: ({ data }) => !!data?.internal,
    hasExtendedPermission:
      ({ data }) =>
      (permission: string) =>
        !!data?.role?.extended_permissions?.some((ep) => ep.name === permission) || false,
    hasModuleConfigPermission(): boolean {
      return this.featureEnabled(AdminPermissionFeature.module_config);
    },
    hasUsersLocationsOrganizationsPermission(): boolean {
      return this.featureEnabled(AdminPermissionFeature.users_locations_organizations);
    },
    hasSystemAdministrationPermission(): boolean {
      return this.featureEnabled(AdminPermissionFeature.system_administration);
    },
    hasReportingPermission(): boolean {
      return this.featureEnabled(AdminPermissionFeature.reporting);
    },
    hasRolesTagsPermission(): boolean {
      return this.featureEnabled(AdminPermissionFeature.roles_and_tags);
    },
    hasRecordImportingPermission(): boolean {
      return this.featureEnabled(AdminPermissionFeature.record_importing);
    },
    isAdminPermissionManageable:
      ({ data }) =>
      (permission: AdminPermissionFeature): boolean => {
        return !!data?.admin || !!data?.acl?.manageable_admin_features?.includes(permission);
      },
    checkProfilePermission:
      ({ data }) =>
      (targetUser: Pick<TenantUser, 'id'>, permission: keyof ProfilePermission, access: 'edit' | 'view'): boolean => {
        const type = data?.id === targetUser.id ? 'self' : 'others';
        const levelToCheck = access === 'view' ? ['edit', 'view'] : ['edit'];

        return levelToCheck.includes(data?.role?.profile_permissions?.[type]?.[permission] || '');
      },
    featureEnabled:
      ({ data }) =>
      (feature: AdminPermissionFeature | AdminPermissionFeature[], allow: { admin?: boolean } = {}): boolean => {
        return !!data && featureEnabled(data, feature, allow);
      },
    hasRoleExtendedPermission:
      ({ data }) =>
      (permission: SpecialPermission, conceptName?: string) => {
        return !!data?.role?.extended_permissions?.some(
          (p) => (!!conceptName ? p.concept_name === conceptName : true) && p.name === permission
        );
      },
    hasDynamicDashboardPersonalization: ({ data }) => {
      return !!Object.keys(data?.dynamic_dashboard_settings || {}).length;
    },
    hasDynamicDashboardAccess: ({ data }) => !!data?.role?.dynamic_dashboard_access,
    hasDynamicDashboardCustomisation: ({ data }) => !!data?.role?.dynamic_dashboard_customisation,
  },
});
