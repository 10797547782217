import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { Matrix } from '@app/models/matrix/matrix';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeMatricesApi {
  createMatrix(matrix: Partial<Matrix>): AxiosPromise<Matrix>;
  getMatrices(options?: DonesafeIndexApiOptions<Matrix>, config?: ApiRequestConfig): AxiosPromise<Matrix[]>;
  getMatrix(
    id: number | string,
    params?: DonesafeApiOptions<Matrix>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Matrix>;
  updateMatrix(id: number | string, matrix: Partial<Matrix>): AxiosPromise<Matrix>;
}

export const createMatricesApi = (api: AxiosInstance, path = '/api/matrices'): DonesafeMatricesApi => {
  const matricesApi: DonesafeMatricesApi = {
    getMatrices: (params, config) => api.get(path, { params, ...config }),
    getMatrix: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createMatrix: (matrix) => api.post(path, matrix),
    updateMatrix: (id, matrix) => api.put(`${path}/${id}`, matrix),
  };

  return {
    ...matricesApi,
    getMatrix: convertToJoinedRequest(matricesApi.getMatrix, matricesApi.getMatrices),
  };
};
