import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ScormCourse } from '@app/models/scorm-course';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeScormCoursesApi {
  createScormCourse(entity: Partial<ScormCourse & { file: string }>): AxiosPromise<ScormCourse>;
  getScormCourse(
    id: number | string,
    params?: DonesafeApiOptions<ScormCourse>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ScormCourse>;
  getScormCourses(options: DonesafeIndexApiOptions<ScormCourse>, config?: ApiRequestConfig): AxiosPromise<ScormCourse[]>;
  updateScormCourse(id: number | string, entity: Partial<ScormCourse & { file: string }>): AxiosPromise<ScormCourse>;
}

export const createScormCoursesApi = (api: AxiosInstance, path = '/api/scorm_courses'): DonesafeScormCoursesApi => {
  const scormCoursesApi: DonesafeScormCoursesApi = {
    createScormCourse: (entity) => api.post(path, entity),
    getScormCourses: (params, config) => api.get(path, { params, ...config }),
    getScormCourse: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateScormCourse: (id, entity) => api.put(`${path}/${id}`, entity),
  };

  return {
    ...scormCoursesApi,
    getScormCourse: convertToJoinedRequest(scormCoursesApi.getScormCourse, scormCoursesApi.getScormCourses),
  };
};
