import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Video } from '@app/models/video';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeVideosApi {
  createVideo(data: Partial<Video>): AxiosPromise<Video>;

  deleteVideo(id: number | string): AxiosPromise<Video>;

  getVideos(params: DonesafeIndexApiOptions<Video>, config?: ApiRequestConfig): AxiosPromise<Video[]>;
}

export const createVideosApi = (api: AxiosInstance, path = '/api/videos'): DonesafeVideosApi => {
  return {
    getVideos: (params, config) => api.get(path, { params, ...config }),
    createVideo: (data) => api.post(path, data),
    deleteVideo: (id) => api.delete(`${path}/${id}`),
  };
};
