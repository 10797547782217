import Rollbar from 'rollbar';

const ensureRollbar = (): Rollbar | null => {
  const accessToken = window.DONESAFE.ROLLBAR_POST_CLIENT_ITEM_TOKEN;
  const enabled = !['development', 'test'].some((env) => env === window.DONESAFE.env);

  if (enabled && !accessToken) {
    console.warn('ROLLBAR_POST_CLIENT_ITEM_TOKEN is not configured');
    return null;
  }
  const ignoredCodes = {
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
  };
  window.Rollbar ||= new Rollbar({
    accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled,
    verbose: window.DONESAFE.isDevelopment,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: window.DONESAFE.REVISION.commit,
          guess_uncaught_frames: true,
        },
      },
    },
    environment: window.DONESAFE.env,
    ignoredMessages: [
      /Uncaught NetworkError/,
      /Loading chunk/,
      /Cannot read properties of undefined \(reading 'addValidator'\)/,
      /Converting circular structure to JSON/,
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSendCallback: function (isUncaught, args, payload: any) {
      const { id, email } = window.DONESAFE.current_user || {};
      payload.accountId = window.DONESAFE.account?.id;
      payload.person = id ? { id, email } : undefined;
      payload.revision = window.DONESAFE.REVISION.commit;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkIgnore: function (isUncaught, args, payload: any) {
      const { body } = payload;
      if (body?.trace?.frames && body.trace.frames.length === 1 && body.trace.frames[0]?.filename === '(unknown)') return true;
      if (body?.trace?.extra) {
        if (Object.keys(ignoredCodes).includes(`${body.trace.extra.status}`)) return true;
        if (Object.values(ignoredCodes).includes(body.trace.extra.error)) return true;
        if (body.trace.extra.data?.code && Object.keys(ignoredCodes).includes(`${body.trace.extra.data.code}`)) return true;
      }
      return false;
    },
  });

  return window.Rollbar;
};

ensureRollbar();

export default ensureRollbar;
