import { sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type {
  ApiRequestConfig,
  DonesafeApiIncludeOptions,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
  ApiRequestJoinConfig,
} from '@app/services/donesafe-api-utils';
import { getCsrfConfig } from '@app/utils/get-csrf-config';
import type { ResponseValue } from '@app/models/sub-form-question';
import type { SubFormCompletion } from '@app/models/sub-form-completion';

export type SubFormData = Partial<Record<string, ResponseValue>>;
export type RelatedKey = 'attachments' | 'related_activities' | 'related_records';
export type RelatedItem = string | number;

export type SubFormCompletionExtraParameters = {
  attachments?: Record<string, RelatedItem[]>;
  form_component_id?: number;
  form_uuid?: string;
  lookup?: boolean;
  related_activities?: Record<string, RelatedItem[]>;
  related_records?: Record<string, RelatedItem[]>;
  responses: SubFormData;
};
export interface DonesafeSubFormCompletionsApi {
  createSubFormCompletion(
    entity: Partial<SubFormCompletion & SubFormCompletionExtraParameters>,
    params?: DonesafeApiOptions<SubFormCompletion>
  ): AxiosPromise<SubFormCompletion>;

  deleteSubFormCompletion(id: number): AxiosPromise<SubFormCompletion>;
  getSubFormCompletion(
    id: number,
    params?: DonesafeApiIncludeOptions<SubFormCompletion>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubFormCompletion>;

  getSubFormCompletionDefaultValues(
    params: {
      default_templating?: boolean;
      record_id?: number | null;
      record_type?: string | null;
      responses?: Record<string, unknown>;
      skip_current_completion?: boolean;
      // 'ModuleRecord' | 'Hazard' | 'Incident';
      sub_form_completion_id?: number;
      sub_form_id?: number;
      sub_form_list_id?: number | null;
    },
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormData>;

  getSubFormCompletions(options: DonesafeIndexApiOptions<SubFormCompletion>, config?: ApiRequestConfig): AxiosPromise<SubFormCompletion[]>;

  getSubFormCompletionsWithSharedFilters(
    options: DonesafeIndexApiOptions<SubFormCompletion>,
    filterKey: keyof SubFormCompletion,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormCompletion[]>;

  updateSubFormCompletion(
    id: number,
    entity: Partial<SubFormCompletion & SubFormCompletionExtraParameters>,
    params?: DonesafeApiOptions<SubFormCompletion>
  ): AxiosPromise<SubFormCompletion>;
}

export const createSubFormCompletionsApi = (api: AxiosInstance, path = '/api/sub_form_completions'): DonesafeSubFormCompletionsApi => {
  const subFormCompletionsApi: Omit<DonesafeSubFormCompletionsApi, 'getSubFormCompletionsWithSharedFilters'> = {
    getSubFormCompletions: (params, config) => api.get(path, { params, ...config }),
    getSubFormCompletion: (id, params) => api.get(`${path}/${id}`, { params }),
    getSubFormCompletionDefaultValues: (params, config) => api.get(`${path}/default_values`, { params, ...config }),
    createSubFormCompletion: (entity, params) => api.post(path, entity, { ...getCsrfConfig(), params }),
    updateSubFormCompletion: (id, entity, params) => api.put(`${path}/${id}`, entity, { params }),
    deleteSubFormCompletion: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...subFormCompletionsApi,
    getSubFormCompletionsWithSharedFilters: sharedFilterListRequest(subFormCompletionsApi.getSubFormCompletions),
  };
};
