import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Dictionary } from 'lodash';
import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AutomationDefinition } from '@app/models/automation-definition';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
} from '@app/services/donesafe-api-utils';
import type { QueryBuilderBlob } from '@app/models/query-builder-blob';
import type { PartialNullBy } from '@app/utils/types/partial';

import type { RecordParams } from './rule-sets-api';
import type { InjectableArguments } from './utils-api';

export interface DonesafeAutomationDefinitionsApiExtraOptions {
  tab?: string | string[];
}

export interface ValidationResult {
  deleted?: boolean;
  deleted_at?: boolean;
  error?: string;
  valid: boolean;
}

export interface AutomationRuleSetBuilder {
  concept_name?: string;
  for_collection_options: boolean;
  injectable_arguments: InjectableArguments;
  triggering_concept_name: Nullable<string>;
  triggering_injectable_arguments: Dictionary<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type AutomationDefinitionExtraParameters = {
  rule_builder_rules?: Nullable<QueryBuilderBlob>;
};

export interface DonesafeAutomationDefinitionsApi {
  activateAutomationDefinition(
    id: number | string,
    params?: DonesafeApiOptions<AutomationDefinition>,
    config?: ApiRequestConfig
  ): AxiosPromise<AutomationDefinition>;

  createAutomationDefinition(
    automationDefinition: PartialNullBy<Partial<AutomationDefinition>, 'rule_set_id'> &
      AutomationDefinitionExtraParameters &
      DonesafeApiOptions<AutomationDefinition>
  ): AxiosPromise<AutomationDefinition>;

  deactivateAutomationDefinition(
    id: number | string,
    params?: DonesafeApiOptions<AutomationDefinition>,
    config?: ApiRequestConfig
  ): AxiosPromise<AutomationDefinition>;

  deleteAutomationDefinition(id: number | string): AxiosPromise<AutomationDefinition>;

  getAutomationDefinition(
    id: number | string,
    params?: DonesafeApiOptions<AutomationDefinition>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<AutomationDefinition>;

  getAutomationDefinitions(
    options: DonesafeIndexApiOptions<AutomationDefinition, DonesafeAutomationDefinitionsApiExtraOptions & { event_types?: string[] }>,
    config?: ApiRequestConfig
  ): AxiosPromise<AutomationDefinition[]>;

  getAutomationDefinitionsWithSharedFilters(
    options: DonesafeIndexApiOptions<AutomationDefinition, DonesafeAutomationDefinitionsApiExtraOptions>,
    filterKey: keyof AutomationDefinition,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<AutomationDefinition[]>;

  getDisplayFilters(
    options?: { filters?: DonesafeAutomationDefinitionsApiExtraOptions },
    config?: ApiRequestConfig
  ): AxiosPromise<Record<string, string>>;

  getEventTypeFilters(
    options?: { filters?: DonesafeAutomationDefinitionsApiExtraOptions },
    config?: ApiRequestConfig
  ): AxiosPromise<Record<string, string>>;
  updateAutomationDefinition(
    id: number | string,
    entity?: PartialNullBy<Partial<AutomationDefinition>, 'rule_set_id'> &
      AutomationDefinitionExtraParameters &
      DonesafeApiOptions<AutomationDefinition>
  ): AxiosPromise<AutomationDefinition>;
  validateRecord(id: number | string, options: RecordParams, config?: ApiRequestConfig): AxiosPromise<ValidationResult>;
}

export const createAutomationDefinitionsApi = (
  api: AxiosInstance,
  path = '/api/automation_definitions'
): DonesafeAutomationDefinitionsApi => {
  const automationsApi: Omit<DonesafeAutomationDefinitionsApi, 'getAutomationDefinitionsWithSharedFilters'> = {
    getAutomationDefinitions: (params, config) => api.get(path, { params, ...config }),
    getAutomationDefinition: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createAutomationDefinition: (automationDefinition) => api.post(path, automationDefinition),
    updateAutomationDefinition: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteAutomationDefinition: (id): AxiosPromise<AutomationDefinition> => api.delete(`${path}/${id}`),
    getDisplayFilters: (params, config) => api.get(`${path}/filters/display`, { params, ...config }),
    getEventTypeFilters: (params, config) => api.get(`${path}/filters/event_type`, { params, ...config }),
    validateRecord: (id, params, config) => api.get(`${path}/${id}/validate_record`, { params, ...config }),
    activateAutomationDefinition: (id, params, config) => api.post(`${path}/${id}/activate`, {}, { params, ...config }),
    deactivateAutomationDefinition: (id, params, config) => api.post(`${path}/${id}/deactivate`, {}, { params, ...config }),
  };

  return {
    ...automationsApi,
    getAutomationDefinition: convertToJoinedRequest<AutomationDefinition>(
      automationsApi.getAutomationDefinition,
      automationsApi.getAutomationDefinitions
    ),
    getAutomationDefinitionsWithSharedFilters: sharedFilterListRequest(automationsApi.getAutomationDefinitions),
  };
};
