import { safeEvaluate } from './safe-evaluate';
import { escape } from 'lodash';

export const select2ResponseTemplate = (result: Record<string, unknown> = {}, options = {}): JQuery => {
  const defaults = {
    primaryAttribute: 'text',
    primaryAttributeClass: '',
    secondaryAttribute: 'secondary',
    secondaryAttributeClass: '',
  };
  const actualOptions = { ...defaults, ...options };
  const primaryText = escape(safeEvaluate(result, actualOptions.primaryAttribute));
  const secondaryText = actualOptions.secondaryAttribute && escape(safeEvaluate(result, actualOptions.secondaryAttribute));
  if (!secondaryText) {
    return $(`<span class="${actualOptions.primaryAttributeClass}">${primaryText}</span>`);
  }
  return $(
    `<div>
      <p class="${actualOptions.primaryAttributeClass}" style="margin-bottom:0;">${primaryText}</p>
      <small class="${actualOptions.secondaryAttributeClass}" style="display:block;font-size:10px">${secondaryText}</small>
    </div>`
  );
};
