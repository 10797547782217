import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Widget, WidgetTranslatedOptions, WidgetType } from '@app/models/widget';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface WidgetCreate<T = object> {
  dashboard_id: Nullable<number>;
  dashboard_pane_id: Nullable<number>;
  options: T;
  title: string;
  translated_options?: WidgetTranslatedOptions;
  widget_type: WidgetType;
}

export interface DonesafeWidgetsApi {
  createWidget(entity: WidgetCreate): AxiosPromise<Widget>;
  deleteWidget(id: number | string, params?: DonesafeApiOptions<Widget>): AxiosPromise<Widget>;
  getWidget(id: Widget['id'], params?: DonesafeApiOptions<Widget>, config?: ApiRequestConfig & ApiRequestJoinConfig): AxiosPromise<Widget>;
  getWidgets(options: DonesafeIndexApiOptions<Widget>, config?: ApiRequestConfig): AxiosPromise<Widget[]>;
  getWidgetsTypes(): AxiosPromise<WidgetType[]>;
  updateWidget(id: number, section: Partial<Widget>): AxiosPromise<Widget>;
  updateWidgetPositions(widgets: { data: Pick<Widget, 'id' | 'x' | 'y' | 'width' | 'height'>[] }): AxiosPromise<Widget[]>;
}

export const createWidgetsApi = (api: AxiosInstance, path = '/api/widgets'): DonesafeWidgetsApi => {
  const widgetsApi: DonesafeWidgetsApi = {
    getWidgets: (params, config) => api.get(path, { params, ...config }),
    getWidgetsTypes: () => api.get(`${path}/types`),
    createWidget: (entity) => api.post(path, entity),
    getWidget: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    deleteWidget: (id, params) => api.delete(`${path}/${id}`, { params }),
    updateWidget: (id, section) => api.put(`${path}/${id}`, section),
    updateWidgetPositions: (widgets: { data: Pick<Widget, 'id' | 'x' | 'y' | 'width' | 'height'>[] }) =>
      api.post(`${path}/update_positions`, widgets),
  };

  return { ...widgetsApi, getWidget: convertToJoinedRequest(widgetsApi.getWidget, widgetsApi.getWidgets) };
};
