import type { API_NULL } from '@app/constants';
import type { BaseEntity } from '../models/base-entity';
import { firstValueFrom, Subject, tap } from 'rxjs';
import { buffer, debounceTime, filter as rxJsFilter, map as rxJsMap, groupBy as rxJsGroupBy, mergeMap } from 'rxjs/operators';
import type { AxiosPromise, AxiosResponse } from 'axios';
import { uniq } from 'lodash';
import type { KeysOfUnion } from '@app/utils/types/keys-of-union';
import type { ValueOf } from '@app/utils/types/value-of';

export type OnlyOption<T> =
  | keyof T
  | Partial<{
      [K in keyof T]:
        | (OnlyOptions<T[K]> | OnlyOptions<KeysOfUnion<ValueOf<T>>>)[]
        | OnlyOptions<T[K]>
        | OnlyOption<KeysOfUnion<ValueOf<T>>>[];
    }>;
export type OnlyOptions<T> = OnlyOption<T> | OnlyOption<T>[];
export type IncludeOption = string | string[];

export interface DonesafeApiOptions<T> extends DonesafeApiIncludeOptions<T> {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface DonesafeApiIncludeOptions<T> {
  include?: IncludeOption;
  only?: OnlyOptions<T>;
}

export interface ApiRequestConfig {
  cache?: boolean;
  forceUpdate?: boolean;
  responseType?: 'arraybuffer' | 'document' | 'json' | 'text' | 'stream';
}

export interface ApiRequestJoinConfig {
  join?: boolean;
}

export interface UpdateIndexParams {
  data: {
    id: number;
    index: number;
  }[];
  sort?: string;
}

export type FilterValue = boolean | number | string | typeof API_NULL | object | FilterValue[];

export type DonesafeFilterOptions<T, F = {}> = Partial<Record<keyof T | `!${string & keyof T}`, FilterValue>> & {
  search?: Nullable<string>;
} & F;

export interface DonesafeIndexApiOptions<T extends BaseEntity<string | number>, F = {}> extends DonesafeApiOptions<T> {
  filters?: DonesafeFilterOptions<T, F>;
  page?: number;
  per_page?: number;
  sort?: string;
}

export function sharedFilterListRequest<T extends BaseEntity>(
  getMultiEntitiesFunction: (options: DonesafeIndexApiOptions<T>, config?: ApiRequestConfig) => AxiosPromise<T[]>,
  options: { bufferTime?: number; joinValues?: boolean } = {}
): (options: DonesafeIndexApiOptions<T>, filterKey: keyof T, filterValue: FilterValue, config?: ApiRequestConfig) => AxiosPromise<T[]> {
  const joinedIds$ = new Subject<{
    config?: ApiRequestConfig;
    filterKey: keyof T;
    filterValue: FilterValue;
    options?: DonesafeIndexApiOptions<T>;
  }>();

  const finalOptions = {
    bufferTime: 200,
    joinValues: true,
    ...options,
  };

  const joinedResults$ = new Subject<{
    config?: ApiRequestConfig;
    filterKey: keyof T;
    options: DonesafeIndexApiOptions<T>;
    rawFilterValues: string[];
    response: AxiosResponse<T[]>;
  }>();

  joinedIds$
    .pipe(
      rxJsGroupBy(({ options, filterKey, config }) => JSON.stringify({ options, filterKey, config })),
      mergeMap((groupByOptions) =>
        groupByOptions.pipe(
          buffer(groupByOptions.pipe(debounceTime(finalOptions.bufferTime))),
          tap((requestData) => {
            const per_page = -1;
            const groupByKey = JSON.parse(groupByOptions.key);
            const { options, filterKey, config } = groupByKey;
            const rawFilterValues: string[] = [];
            const filterValues = requestData
              .reduce<FilterValue[]>((memo, r) => {
                rawFilterValues.push(JSON.stringify(r.filterValue));
                return Array.isArray(r.filterValue) ? memo.concat(r.filterValue) : memo.concat([r.filterValue]);
              }, [])
              .flat();
            // TODO: always add filterKey to only
            // const only = options.only ? (options.only as OnlyOption[]).concat(filterKey as string) : options.only;
            const finalFilterValues = finalOptions.joinValues ? uniq(filterValues).join(',') : uniq(filterValues);
            const filters = { ...options.filters, [filterKey]: finalFilterValues } as DonesafeFilterOptions<T>;
            const requestOptions = { ...options, filters, per_page };
            getMultiEntitiesFunction(requestOptions, config).then((response) => {
              joinedResults$.next({ response, filterKey, options, rawFilterValues, config });
            });
          })
        )
      )
    )
    .subscribe();

  return (options, filterKey, filterValue, config) => {
    joinedIds$.next({ options, filterKey, filterValue, config });
    const values = (Array.isArray(filterValue) ? filterValue : [filterValue]).map((v) => `${v}`);
    return firstValueFrom(
      joinedResults$.pipe(
        rxJsFilter((v) => {
          return (
            v.filterKey === filterKey &&
            v.rawFilterValues.includes(JSON.stringify(filterValue)) &&
            JSON.stringify(v.options) === JSON.stringify(options) &&
            JSON.stringify(v.config) === JSON.stringify(config)
          );
        }),
        rxJsMap((value) => {
          const invert = String(filterKey)[0] === '!';
          const cleanKey = (invert ? String(filterKey).substring(1) : filterKey) as keyof T;
          const data = value.response.data.filter((entity) => {
            if (!entity[cleanKey]) throw Error(`Entity has to have ${String(cleanKey)} in response`);

            const index = values.indexOf(`${entity[cleanKey]}`);
            return invert ? index < 0 : index > -1;
          });
          return { ...value.response, data };
        })
      )
    );
  };
}

export function convertToJoinedRequest<T extends BaseEntity, F = {}>(
  getSingleEntityFunction: (id: T['id'], options?: DonesafeApiOptions<T>, config?: ApiRequestConfig) => AxiosPromise<T>,
  getMultiEntitiesFunction: (options: DonesafeIndexApiOptions<T, F>, config?: ApiRequestConfig) => AxiosPromise<T[]>,
  bufferTime = 200
): (id: T['id'], options?: DonesafeApiOptions<T>, config?: ApiRequestConfig) => AxiosPromise<T> {
  const joinedIds$ = new Subject<{ config: ApiRequestConfig; id: T['id']; options?: DonesafeApiOptions<T> }>();
  const joinedResults$ = new Subject<{
    config?: ApiRequestConfig;
    ids: string[];
    options?: DonesafeApiOptions<T>;
    response: AxiosResponse<T[]>;
  }>();

  joinedIds$
    .pipe(
      rxJsGroupBy(({ options, config }) => JSON.stringify({ options, config })),
      mergeMap((groupByOptions) =>
        groupByOptions.pipe(
          buffer(groupByOptions.pipe(debounceTime(bufferTime))),
          tap((values) => {
            const { options, config } = JSON.parse(groupByOptions.key);
            const ids = uniq(values.map((v) => `${v.id}`));
            const filters = { id: ids.join(',') } as DonesafeFilterOptions<T>;
            getMultiEntitiesFunction({ filters, per_page: -1, ...options }, config).then((response) =>
              joinedResults$.next({ response, options, ids, config })
            );
          })
        )
      )
    )
    .subscribe();

  return (id: T['id'], options?: DonesafeApiOptions<T>, config?: ApiRequestConfig & ApiRequestJoinConfig) => {
    if (config?.join) {
      joinedIds$.next({ id, options, config });
      return firstValueFrom(
        joinedResults$.pipe(
          rxJsFilter((v) => {
            return (
              v.ids.includes(`${id}`) &&
              JSON.stringify(v.options) === JSON.stringify(options) &&
              JSON.stringify(v.config) === JSON.stringify(config)
            );
          }),
          rxJsMap((value) => {
            const entity = value.response.data.find((r) => `${r.id}` === `${id}`);
            if (entity) {
              return { ...value.response, data: entity };
            }
            throw { data: { error: `Record ${id} was not found (${getSingleEntityFunction.name})` } };
          })
        )
      );
    }
    return getSingleEntityFunction(id, options, config);
  };
}
