import { API_VERSION_HEADER } from '@app/constants';
import { createAccountsApi } from '@app/services/api/accounts-api';
import { createContributingTypesApi } from '@app/services/api/contributing-subtypes-api';
import { createContributingSubtypesApi } from '@app/services/api/contributing-types-api';
import { createEventOccurrencesApi } from '@app/services/api/event-occurrences-api';
import { createExpensableRecordBudgetBehavioursApi } from '@app/services/api/expensable-record-budget-behaviours-api';
import { createExpensableRecordBudgetCategoriesApi } from '@app/services/api/expensable-record-budget-categories-api';
import { createExpensableTransactionLedgersApi } from '@app/services/api/expensable-transaction-ledgers-api';
import { createExpenseCategoriesApi } from '@app/services/api/expense-categories-api';
import { createExpenseCategoryOverwritesApi } from '@app/services/api/expense-category-overwrites-api';
import { createExpensableModuleBudgetsApi } from '@app/services/api/expensable-module-budgets-api';
import { createExpensableExpensingTableCategoriesApi } from '@app/services/api/expensable-expensing-table-categories-api';
import { createExpensableCategoriesApi } from '@app/services/api/expensable-categories-api';
import { createExpensingTablesApi } from '@app/services/api/expensing-tables-api';
import { createFollowsApi } from '@app/services/api/follows-api';
import { createNotificationsApi } from '@app/services/api/notifications-api';
import { createProcedureAcknowledgementsApi } from '@app/services/api/procedure-acknowledgements-api';
import { createProcedureModuleRecordsApi } from '@app/services/api/procedure-module-records-api';
import { createPaperTrailsApi } from '@app/services/api/paper-trails-api';
import type { AxiosAdapter, AxiosPromise } from 'axios';
import axios from 'axios';
import LRUCache from 'lru-cache';
import qs from 'qs';
import { createActivitiesApi } from './api/activities-api';
import { createActionPrioritiesApi } from './api/action-priorities-api';
import { createApprovedExpensesApi } from './api/approved-expenses-api';
import { createAreasApi } from './api/areas-api';
import { createDefaultTemplatesApi } from './api/default-templates-api';
import { createDocumentsApi } from './api/documents-api';
import { createFormComponentsApi } from './api/form-components-api';
import { createHourTypesApi } from './api/hour-types-api';
import { createIndicatorsApi } from './api/indicators-api';
import { createLocationTagCountsApi } from './api/location-tag-counts-api';
import { createMobileAppConfigsApi } from './api/mobile-app-configs-api';
import { createMobileAppLayoutEntitiesApi } from './api/mobile-app-layout-entities-api';
import { createMobileAppLayoutsApi } from './api/mobile-app-layouts-api';
import { createMobileAppRegistrationsApi } from './api/mobile-app-registrations-api';
import { createModuleEntitiesApi } from './api/module-entities-api';
import { createMapInformationsApi } from './api/map-informations-api';
import { createRecordIndicatorsApi } from './api/record-indicators-api';
import { createRegulatoryReportConfigsApi } from './api/regulatory-report-configs-api';
import { createRegulatoryReportsApi } from './api/regulatory-reports-api';
import { createRolesApi } from './api/roles-api';
import { createScheduledEventsApi } from './api/scheduled-events-api';
import { createSchemesApi } from './api/schemes-api';
import { createServiceCategoriesApi } from './api/service-categories-api';
import { createSubFormCompletionsApi } from './api/sub-form-completions-api';
import { createSubFormListRolesApi } from './api/sub-form-list-roles-api';
import { createUserLocationTagsApi } from './api/user-location-tags-api';
import { createUserRecordCalendarsApi } from './api/user-record-calendars-api';
import { createUtilsApi } from './api/utils-api';
import { createVisitorAppApi } from './api/visitor-app-api';
import { createWorkflowsApi } from './api/workflows-api';
import customCacheAdapterEnhancer, { CAPACITY, FIVE_MINUTES } from './axios-cache-adapter';
import type { DonesafeApi } from './donesafe-api';
import { createBaseApi } from './api/base-api';
import { createModuleRecordsApi } from './api/module-records-api';
import { createCommentsApi } from './api/comments-api';
import { createRelationsApi } from './api/relations-api';
import { createVideosApi } from './api/videos-api';
import { createAttachmentsApi } from './api/attachments-api';
import { createSuperImportsApi } from './api/super-imports-api';
import { createSuperReportsApi } from './api/super-reports-api';
import { createInvolvementsApi } from './api/involvements-api';
import { createUserInvolvementsApi } from './api/user-involvements-api';
import { createFeatureSetsApi } from './api/feature-sets-api';
import { createRecordSignaturesApi } from './api/record-signatures-api';
import { createIndicatorSetsApi } from './api/indicator-sets-api';
import { createModuleNamesApi } from './api/module-names-api';
import { createSignaturesApi } from './api/signatures-api';
import { createShiftsApi } from './api/shifts-api';
import { createMatricesApi } from './api/matrices-api';
import { createRecordRelationsApi } from './api/record-relations-api';
import { createRelationshipsApi } from './api/relationships-api';
import { createScoringGroupsApi } from './api/scoring-groups-api';
import { createScoreBandProfilesApi } from './api/score-band-profiles-api';
import { createLocationsApi } from './api/locations-api';
import { createTenantUsersApi } from './api/tenant-users-api';
import { createOrganizationsApi } from './api/organizations-api';
import { createSubFormQuestionSetsApi } from './api/sub-form-question-sets-api';
import { createSubFormSectionsApi } from './api/sub-form-sections-api';
import { createCountriesApi } from './api/countries-api';
import { createStatesApi } from './api/states-api';
import { createScormCoursesApi } from './api/scorm-courses-api';
import { createSubFormQuestionsApi } from './api/sub-form-questions-api';
import { createTabSectionGroupsApi } from './api/tab-section-groups-api';
import { createSubFormListsApi } from './api/sub-form-lists-api';
import { createDashboardsApi } from './api/dashboards-api';
import { createAppBundlesApi } from './api/app-bundles-api';
import { createScormCloudApi } from './api/scorm-cloud-api';
import { createScormRegistrationsApi } from './api/scorm-registrations-api';
import { createGlobalSearchApi } from './api/global-search-api';
import { createSubFormsApi } from './api/sub-forms-api';
import { createModuleTabsApi } from './api/module-tabs-api';
import { createAuditReportTemplatesApi } from './api/audit-report-templates-api';
import { createProceduresApi } from './api/procedures-api';
import { createContactUsersApi } from './api/contact-users-api';
import { createIndexFiltersApi } from './api/index-filters-api';
import { createSvgTemplatesApi } from './api/svg-templates-api';
import { createLocationTagsApi } from './api/locations-tags-api';
import { createConfidentialityTypesApi } from './api/confidentiality-types-api';
import { createObserveTemplatesApi } from './api/observe-templates-api';
import { createIncidentParticipantRolesApi } from './api/incident-participant-roles-api';
import { createPublicDashboardsApi } from './api/public-dashboards-api';
import { createSamlsApi } from './api/samls-api';
import { createAutomationDefinitionsApi } from './api/automation-definition-api';
import { createAutomatedEventsApi } from './api/automated-event-api';
import { createProfilesApi } from '@app/services/api/profiles-api';
import { createRecordCalculationsApi } from '@app/services/api/record-calculations-api';
import { createRecordCalculationResultsApi } from '@app/services/api/record-calculation-results-api';
import { createLogicSetsApi } from '@app/services/api/logic-sets-api';
import { createSubFormListsTabSectionGroupsApi } from '@app/services/api/sub-form-lists-tab-section-groups';
import { createGenderIdentitiesApi } from './api/gender-identities-api';
import { createEmploymentTypesApi } from './api/employment-types-api';
import { createWidgetsApi } from './api/widgets-api';
import { createWorkflowLinksApi } from './api/workflow-links-api';
import { createModuleRecordCollectionsApi } from './api/module-record-collections-api';
import { createSubFormCompletionCollectionsApi } from './api/sub-form-completion-collections-api';
import { createUserCollectionsApi } from './api/user-collections-api';
import { createPermissionsApi } from './api/permissions-api';
import { createAutomationTogglesApi } from './api/automation-toggles-api';
import { createRuleSetsApi } from './api/rule-sets-api';
import { createEventsApi } from './api/events-api';
import { createConfidentialitiesApi } from './api/confidentialities-api';
import { createWorkplaceIndustriesApi } from './api/workplace-industries-api';
import { createExpensableRecordBudgetsApi } from './api/expensable-record-budgets-api';
import { createMenuCollectionsApi } from '@app/services/api/menu-collections-api';
import { createRestrictionDefaultsApi } from './api/restriction-defaults-api';
import { createRestrictionsApi } from './api/restrictions-api';
import { createPlaceholderProfilesApi } from './api/placeholder-profiles-api';
import { createSubFormListRestrictionsApi } from './api/sub-form-list-restrictions-api';
import { createRecurringSetupsApi } from './api/recurring-setups-api';
import { createTableCalculationsApi } from './api/table-calculations-api';
import { createRoleProfilesApi } from './api/role-profiles-api';
import { createSubFormSectionGroupsApi } from './api/sub-form-section-groups-api';
import { createCalculationRulesApi } from './api/calculation-rules-api';
import { createManagedFormsApi } from '@app/services/api/managed-forms-api';
import { createDashboardPanesApi } from '@app/services/api/dashboard-panes-api';
import { createDashboardPaneGroupsApi } from '@app/services/api/dashboard-pane-groups-api';
import { createPrintConfigurationsApi } from './api/print-configurations-api';
import { createUnsubscriptionCollectionsApi } from '@app/services/api/unsubscription-collections-api';
import { createLmsCourseEnrollmentsApi } from '@app/services/api/lms-course-enrollments-api';

export interface ApiConfig {
  apiVersion?: string;
  enableCaching?: boolean;
}

const paramsSerializer = (params: object): string => qs.stringify(params, { arrayFormat: 'brackets' });

export const createApi = (config: ApiConfig = {}): DonesafeApi => {
  const { apiVersion = API_VERSION_HEADER, enableCaching = true } = config;
  const headers = {
    'Accept-Version': apiVersion,
    'Cache-Control': 'no-cache',
    'Donesafe-Locale': window.DONESAFE.locale,
    'X-Client-Subdomain': window.DONESAFE.account?.subdomain || '',
  };

  const cache = new LRUCache<string, AxiosPromise>({ ttl: FIVE_MINUTES, max: CAPACITY });

  const api = axios.create({
    headers,
    paramsSerializer,
    baseURL: window.DONESAFE.baseApiUrl,
    ...(enableCaching
      ? { adapter: customCacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { enabledByDefault: false, defaultCache: cache }) }
      : {}),
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 && window.DONESAFE.current_user?.id) {
        window.location.reload();
      }
      return Promise.reject(error.response);
    }
  );

  return {
    cache,
    ...createAccountsApi(api),
    ...createActivitiesApi(api),
    ...createActionPrioritiesApi(api),
    ...createDashboardPanesApi(api),
    ...createDashboardPaneGroupsApi(api),
    ...createFollowsApi(api),
    ...createRecordIndicatorsApi(api),
    ...createSignaturesApi(api),
    ...createInvolvementsApi(api),
    ...createUserInvolvementsApi(api),
    ...createBaseApi(api),
    ...createModuleRecordsApi(api),
    ...createSubFormCompletionsApi(api),
    ...createIndicatorSetsApi(api),
    ...createModuleNamesApi(api),
    ...createFeatureSetsApi(api),
    ...createSuperImportsApi(api),
    ...createSuperReportsApi(api),
    ...createRecordSignaturesApi(api),
    ...createCommentsApi(api),
    ...createRelationsApi(api),
    ...createAttachmentsApi(api),
    ...createMatricesApi(api),
    ...createVideosApi(api),
    ...createWorkflowsApi(api),
    ...createRecordRelationsApi(api),
    ...createIndicatorsApi(api),
    ...createLocationsApi(api),
    ...createOrganizationsApi(api),
    ...createTenantUsersApi(api),
    ...createRelationshipsApi(api),
    ...createScoringGroupsApi(api),
    ...createScoreBandProfilesApi(api),
    ...createRelationshipsApi(api),
    ...createSubFormQuestionsApi(api),
    ...createSubFormQuestionSetsApi(api),
    ...createAreasApi(api),
    ...createRolesApi(api),
    ...createDefaultTemplatesApi(api),
    ...createModuleEntitiesApi(api),
    ...createServiceCategoriesApi(api),
    ...createSubFormSectionsApi(api),
    ...createCountriesApi(api),
    ...createStatesApi(api),
    ...createRegulatoryReportConfigsApi(api),
    ...createRegulatoryReportsApi(api),
    ...createMapInformationsApi(api),
    ...createScheduledEventsApi(api),
    ...createScormCoursesApi(api),
    ...createTabSectionGroupsApi(api),
    ...createSubFormListsApi(api),
    ...createScormCoursesApi(api),
    ...createDashboardsApi(api),
    ...createAppBundlesApi(api),
    ...createHourTypesApi(api),
    ...createUtilsApi(api),
    ...createScormCloudApi(api),
    ...createSchemesApi(api),
    ...createScormRegistrationsApi(api),
    ...createGlobalSearchApi(api),
    ...createSubFormsApi(api),
    ...createModuleTabsApi(api),
    ...createMobileAppConfigsApi(api),
    ...createAuditReportTemplatesApi(api),
    ...createProceduresApi(api),
    ...createProcedureModuleRecordsApi(api),
    ...createUserRecordCalendarsApi(api),
    ...createContactUsersApi(api),
    ...createMobileAppRegistrationsApi(api),
    ...createMobileAppLayoutsApi(api),
    ...createMobileAppLayoutEntitiesApi(api),
    ...createVisitorAppApi(api),
    ...createSvgTemplatesApi(api),
    ...createIndexFiltersApi(api),
    ...createLocationTagsApi(api),
    ...createConfidentialityTypesApi(api),
    ...createContributingTypesApi(api),
    ...createContributingSubtypesApi(api),
    ...createFormComponentsApi(api),
    ...createUserLocationTagsApi(api),
    ...createLocationTagCountsApi(api),
    ...createObserveTemplatesApi(api),
    ...createIncidentParticipantRolesApi(api),
    ...createExpenseCategoriesApi(api),
    ...createExpenseCategoryOverwritesApi(api),
    ...createExpensableExpensingTableCategoriesApi(api),
    ...createExpensableCategoriesApi(api),
    ...createExpensingTablesApi(api),
    ...createPublicDashboardsApi(api),
    ...createSamlsApi(api),
    ...createAutomationDefinitionsApi(api),
    ...createAutomatedEventsApi(api),
    ...createProfilesApi(api),
    ...createRecordCalculationsApi(api),
    ...createRecordCalculationResultsApi(api),
    ...createDocumentsApi(api),
    ...createLogicSetsApi(api),
    ...createSubFormListsTabSectionGroupsApi(api),
    ...createGenderIdentitiesApi(api),
    ...createEmploymentTypesApi(api),
    ...createWidgetsApi(api),
    ...createWorkflowLinksApi(api),
    ...createModuleRecordCollectionsApi(api),
    ...createSubFormCompletionCollectionsApi(api),
    ...createUserCollectionsApi(api),
    ...createPermissionsApi(api),
    ...createPaperTrailsApi(api),
    ...createAutomationTogglesApi(api),
    ...createRuleSetsApi(api),
    ...createEventsApi(api),
    ...createConfidentialitiesApi(api),
    ...createProcedureAcknowledgementsApi(api),
    ...createApprovedExpensesApi(api),
    ...createWorkplaceIndustriesApi(api),
    ...createMenuCollectionsApi(api),
    ...createEventOccurrencesApi(api),
    ...createRestrictionDefaultsApi(api),
    ...createRestrictionsApi(api),
    ...createPlaceholderProfilesApi(api),
    ...createSubFormListRestrictionsApi(api),
    ...createSubFormListRolesApi(api),
    ...createRecurringSetupsApi(api),
    ...createNotificationsApi(api),
    ...createTableCalculationsApi(api),
    ...createExpensableRecordBudgetsApi(api),
    ...createExpensableModuleBudgetsApi(api),
    ...createExpensableRecordBudgetBehavioursApi(api),
    ...createExpensableTransactionLedgersApi(api),
    ...createExpensableRecordBudgetCategoriesApi(api),
    ...createRoleProfilesApi(api),
    ...createSubFormSectionGroupsApi(api),
    ...createCalculationRulesApi(api),
    ...createManagedFormsApi(api),
    ...createPrintConfigurationsApi(api),
    ...createShiftsApi(api),
    ...createUnsubscriptionCollectionsApi(api),
    ...createLmsCourseEnrollmentsApi(api),
  };
};

export const API = createApi();
