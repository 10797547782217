import { get } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function safeEvaluate<T = any>(result: T, attribute?: string | ((item: T) => string)): any {
  if (!attribute) {
    return result;
  }
  switch (typeof attribute) {
    case 'string':
      return get(result, attribute);
    case 'function':
      return attribute(result);
    default:
      return result;
  }
}
