import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ActivitiesStats, Activity } from '@app/models/activity';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
} from '@app/services/donesafe-api-utils';
import type { RequiredBy } from '@app/utils/types/required-by';

export interface DonesafeActivitiesApiExtraOptions {
  // TODO: proper typing
  scope_filter?: string;
  status_filter?: string;
  sub_form_response?: { sub_form_completion_id: FilterValue };
}

export interface CommentParams {
  attachments?: string[];
  comment?: string;
}

export interface DonesafeActivitiesApi {
  approveActivity(
    id: number,
    params: { approved: boolean } & RequiredBy<CommentParams, 'comment'> & DonesafeApiOptions<Activity>
  ): AxiosPromise<Activity>;
  closeActivity(id: number, params?: CommentParams & DonesafeApiOptions<Activity>): AxiosPromise<Activity>;

  createActivity(entity: Partial<Activity>): AxiosPromise<Activity[]>;
  deleteActivity(id: number): AxiosPromise<Activity>;
  getActivities(
    options: DonesafeIndexApiOptions<Activity, DonesafeActivitiesApiExtraOptions>,
    config?: ApiRequestConfig
  ): AxiosPromise<Activity[]>;
  getActivitiesStats(params: { actionable_id: number; actionable_type: string }, config?: ApiRequestConfig): AxiosPromise<ActivitiesStats>;
  getActivitiesWithSharedFilter(
    options: DonesafeIndexApiOptions<Activity, DonesafeActivitiesApiExtraOptions>,
    filterKey: keyof Activity,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<Activity[]>;
  getActivity(id: number, options?: DonesafeApiOptions<Activity>, config?: ApiRequestConfig & ApiRequestJoinConfig): AxiosPromise<Activity>;
  getActivityStatusFilters(config?: ApiRequestConfig): AxiosPromise<string[]>;
  openActivity(id: number, params?: DonesafeApiOptions<Activity> & CommentParams): AxiosPromise<Activity>;
  unapproveActivity(id: number, params?: DonesafeApiOptions<Activity>): AxiosPromise<Activity>;
  updateActivity(id: number, entity: Partial<Activity>): AxiosPromise<Activity>;
}

export const createActivitiesApi = (api: AxiosInstance, path = '/api/activities'): DonesafeActivitiesApi => {
  const activitiesApi: Omit<DonesafeActivitiesApi, 'getActivitiesWithSharedFilter'> = {
    getActivities: (params, config) => api.get(path, { params, ...config }),
    getActivity: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    deleteActivity: (id) => api.delete(`${path}/${id}`),
    createActivity: (entity) => api.post(path, entity),
    updateActivity: (id, entity) => api.put(`${path}/${id}`, entity),
    closeActivity: (id, params) => api.post(`${path}/${id}/close`, { ...params }),
    openActivity: (id, params) => api.post(`${path}/${id}/open`, { ...params }),
    getActivitiesStats: (params, config) => api.get(`${path}/stats`, { params, ...config }),
    approveActivity: (id, params) => api.post(`${path}/${id}/approve`, { ...params }),
    unapproveActivity: (id, params) => api.post(`${path}/${id}/unapprove`, { ...params }),
    getActivityStatusFilters: (config) => api.get(`${path}/filters/status`, { ...config }),
  };

  return {
    ...activitiesApi,
    getActivity: convertToJoinedRequest(activitiesApi.getActivity, activitiesApi.getActivities),
    getActivitiesWithSharedFilter: sharedFilterListRequest(activitiesApi.getActivities),
  };
};
