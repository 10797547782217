import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RecordSignature } from '@app/models/record-signature';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
} from '@app/services/donesafe-api-utils';

export interface OptionalSignatureData {
  signature_data?: string;
  signature_id?: number;
}

export interface DonesafeRecordSignaturesApi {
  createRecordSignature(entity: Partial<RecordSignature> & OptionalSignatureData): AxiosPromise<RecordSignature>;
  deleteRecordSignature(id: number | string): AxiosPromise<RecordSignature>;

  getRecordSignature(
    id: number | string,
    params?: DonesafeApiOptions<RecordSignature>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<RecordSignature>;

  getRecordSignatures(params?: DonesafeIndexApiOptions<RecordSignature>, config?: ApiRequestConfig): AxiosPromise<RecordSignature[]>;

  getRecordSignaturesWithSharedFilter(
    options: DonesafeIndexApiOptions<RecordSignature>,
    filterKey: keyof RecordSignature,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<RecordSignature[]>;
}

export const createRecordSignaturesApi = (api: AxiosInstance, path = '/api/record_signatures'): DonesafeRecordSignaturesApi => {
  const recordSignaturesApi: Omit<DonesafeRecordSignaturesApi, 'getRecordSignaturesWithSharedFilter'> = {
    getRecordSignatures: (params, config) => api.get(path, { params, ...config }),
    getRecordSignature: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createRecordSignature: (entity) => api.post(path, entity),
    deleteRecordSignature: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...recordSignaturesApi,
    getRecordSignaturesWithSharedFilter: sharedFilterListRequest(recordSignaturesApi.getRecordSignatures),
    getRecordSignature: convertToJoinedRequest<RecordSignature>(
      recordSignaturesApi.getRecordSignature,
      recordSignaturesApi.getRecordSignatures
    ),
  };
};
