import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Country } from '@app/models/country';
import type { ApiRequestConfig } from '@app/services/donesafe-api-utils';

interface CountriesOptions {
  filters?: object;
  language?: string;
}

export interface DonesafeCountriesApi {
  getCountries(options?: CountriesOptions, config?: ApiRequestConfig): AxiosPromise<Country[]>;
}

export const createCountriesApi = (api: AxiosInstance, path = '/api/countries'): DonesafeCountriesApi => {
  return {
    getCountries: (params, config) => api.get(path, { params, ...config }),
  };
};
