import type { AxiosInstance, AxiosPromise } from 'axios';
import type { UnsubscriptionCollection } from '@app/models/unsubcription-collection';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeUnsubscriptionCollectionsApi {
  createUnsubscriptionCollection(params: UnsubscriptionCollection): AxiosPromise<UnsubscriptionCollection>;

  getUnsubscriptionCollection(
    id: number,
    params?: DonesafeApiOptions<UnsubscriptionCollection>,
    config?: ApiRequestConfig
  ): AxiosPromise<UnsubscriptionCollection>;

  getUnsubscriptionCollections(
    options: DonesafeIndexApiOptions<UnsubscriptionCollection, { subscribed?: boolean }>,
    config?: ApiRequestConfig
  ): AxiosPromise<UnsubscriptionCollection[]>;
  updateUnsubscriptionCollection(id: number, params: Partial<UnsubscriptionCollection>): AxiosPromise<UnsubscriptionCollection>;
}

export const createUnsubscriptionCollectionsApi = (
  api: AxiosInstance,
  path = '/api/unsubscription_collections'
): DonesafeUnsubscriptionCollectionsApi => {
  return {
    getUnsubscriptionCollections: (params, config) => api.get(path, { params, ...config }),

    getUnsubscriptionCollection: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),

    updateUnsubscriptionCollection: (id, data) => api.put(`${path}/${id}`, data),
    createUnsubscriptionCollection: (data) => api.post(path, data),
  };
};
