import type { default as _Vue } from 'vue';
import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import type { ValidationRule } from 'vee-validate/dist/types/types';
import { LANGUAGE_CODES } from '@app/constants';

const withVeeValidate = (Vue: typeof _Vue): typeof _Vue => {
  Object.entries(rules).forEach(([rule, validation]) => {
    extend(rule, {
      ...(validation as ValidationRule),
    });
  });

  loadLocale(window.DONESAFE.locale);

  return Vue;
};

function loadLocale(code: string) {
  return import(`vee-validate/dist/locale/${LANGUAGE_CODES[code]}.json`).then((locale) => {
    localize(LANGUAGE_CODES[code], locale);
  });
}

export default withVeeValidate;
