import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Confidentiality } from '@app/models/confidentiality';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeConfidentialitiesApi {
  bulkCreateConfidentialities(entities: {
    confidentialities: Pick<Confidentiality & { user_ids: number[] }, 'lockable_id' | 'lockable_type' | 'confidentiality_type_id'>[];
  }): AxiosPromise<Confidentiality[]>;

  createConfidentiality(entity: Partial<Confidentiality>): AxiosPromise<Confidentiality>;

  deleteConfidentiality(id: number | string): AxiosPromise<Confidentiality>;
  getConfidentialities(options: DonesafeIndexApiOptions<Confidentiality>, config?: ApiRequestConfig): AxiosPromise<Confidentiality[]>;
  getConfidentiality(
    id: number | string,
    params?: DonesafeApiOptions<Confidentiality>,
    config?: ApiRequestConfig
  ): AxiosPromise<Confidentiality>;
}

export const createConfidentialitiesApi = (api: AxiosInstance, path = '/api/confidentialities'): DonesafeConfidentialitiesApi => {
  return {
    getConfidentialities: (params, config) => api.get(path, { params, ...config }),
    createConfidentiality: (entity) => api.post(path, entity),
    deleteConfidentiality: (id) => api.delete(`${path}/${id}`),
    getConfidentiality: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    bulkCreateConfidentialities: (entity) => api.post(`${path}/bulk_create`, entity),
  };
};
