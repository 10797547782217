import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { SubFormSectionGroup } from '@app/models/sub-form-section-group';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';

export interface DonesafeSubFormSectionGroupsApi {
  createSubFormSectionGroup(section: Partial<SubFormSectionGroup>): AxiosPromise<SubFormSectionGroup>;

  deleteSubFormSectionGroup(id: number): AxiosPromise<SubFormSectionGroup>;

  getSubFormSectionGroup(
    id: number,
    params?: DonesafeApiOptions<SubFormSectionGroup>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubFormSectionGroup>;

  getSubFormSectionGroups(
    params: DonesafeIndexApiOptions<SubFormSectionGroup>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormSectionGroup[]>;

  updateSubFormSectionGroup(id: number, section: Partial<SubFormSectionGroup>): AxiosPromise<SubFormSectionGroup>;

  updateSubFormSectionGroupIndexes(params: UpdateIndexParams): AxiosPromise<void>;
}

export const createSubFormSectionGroupsApi = (
  api: AxiosInstance,
  path = '/api/sub_form_section_groups'
): DonesafeSubFormSectionGroupsApi => {
  const subFormSectionGroupsApi: DonesafeSubFormSectionGroupsApi = {
    getSubFormSectionGroups: (params, config) => api.get(path, { params, ...config }),
    getSubFormSectionGroup: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createSubFormSectionGroup: (section) => api.post(path, section),
    deleteSubFormSectionGroup: (id) => api.delete(`${path}/${id}`),
    updateSubFormSectionGroup: (id, section) => api.put(`${path}/${id}`, section),
    updateSubFormSectionGroupIndexes: (params: UpdateIndexParams) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...subFormSectionGroupsApi,
    getSubFormSectionGroup: convertToJoinedRequest<SubFormSectionGroup>(
      subFormSectionGroupsApi.getSubFormSectionGroup,
      subFormSectionGroupsApi.getSubFormSectionGroups
    ),
  };
};
