import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Saml } from '@app/models/saml';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeSamlsApi {
  clearLogs(id: string | number, type: string): AxiosPromise<Saml>;
  cloneSaml(id: string | number, entity?: Partial<Saml>): AxiosPromise<Saml>;
  createSaml(entity: Partial<Saml>): AxiosPromise<Saml>;
  deleteSaml(id: number | string): AxiosPromise<Saml>;
  getSaml(id: number | string, params?: DonesafeApiOptions<Saml>, config?: ApiRequestConfig): AxiosPromise<Saml>;
  getSamls(params: DonesafeIndexApiOptions<Saml>, config?: ApiRequestConfig): AxiosPromise<Saml[]>;
  updateSaml(id: number | string, entity: Partial<Saml>): AxiosPromise<Saml>;
}

export const createSamlsApi = (api: AxiosInstance, path = '/api/samls'): DonesafeSamlsApi => {
  return {
    getSaml: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getSamls: (params, config) => api.get(path, { params, ...config }),
    createSaml: (entity) => api.post(path, entity),
    updateSaml: (id, entity) => api.put(`${path}/${id}`, entity),
    cloneSaml: (id, entity) => api.post(`${path}/${id}/clone`, entity),
    clearLogs: (id, type) => api.post(`${path}/${id}/clear_logs`, { type }),
    deleteSaml: (id) => api.delete(`${path}/${id}`),
  };
};
