import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { Indicator } from '@app/models/indicator';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';

export interface DonesafeIndicatorsApi {
  createIndicator(indicator: Partial<Indicator>): AxiosPromise<Indicator>;
  deleteIndicator(id: number): AxiosPromise<Indicator>;
  getIndicator(
    id: number | string,
    params?: DonesafeApiOptions<Indicator>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Indicator>;
  getIndicators(options?: DonesafeIndexApiOptions<Indicator>, config?: ApiRequestConfig): AxiosPromise<Indicator[]>;
  updateIndicator(id: number, entity?: Partial<Indicator>): AxiosPromise<Indicator>;
  updateIndicatorIndexes(params: UpdateIndexParams): AxiosPromise<Indicator[]>;
}

export const createIndicatorsApi = (api: AxiosInstance, path = '/api/indicators'): DonesafeIndicatorsApi => {
  const indicatorsApi: DonesafeIndicatorsApi = {
    createIndicator: (indicator) => api.post(path, indicator),
    deleteIndicator: (id) => api.delete(`${path}/${id}`),
    getIndicators: (params, config) => api.get(path, { params, ...config }),
    getIndicator: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateIndicator: (id, entity) => api.put(`${path}/${id}`, entity),
    updateIndicatorIndexes: (params) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...indicatorsApi,
    getIndicator: convertToJoinedRequest<Indicator>(indicatorsApi.getIndicator, indicatorsApi.getIndicators),
  };
};
