import type { ExpensableRecordBudgetBehaviour } from '@app/models/expensable-record-budget-behaviour';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeExpensableRecordBudgetBehavioursApi {
  getExpensableRecordBudgetBehaviours(
    params: DonesafeIndexApiOptions<ExpensableRecordBudgetBehaviour>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableRecordBudgetBehaviour[]>;
}

export const createExpensableRecordBudgetBehavioursApi = (
  api: AxiosInstance,
  path = '/api/record_budget_behaviours'
): DonesafeExpensableRecordBudgetBehavioursApi => {
  return {
    getExpensableRecordBudgetBehaviours: (params, config) => api.get(path, { params, ...config }),
  };
};
