import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Signature } from '@app/models/signature';
import type { ApiRequestConfig, DonesafeApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeSignaturesApi {
  createSignature(
    entity: { signature_data: string },
    options?: DonesafeApiOptions<Signature>,
    config?: ApiRequestConfig
  ): AxiosPromise<Signature>;
  deleteSignature(id: number): AxiosPromise<Signature>;
  getSignature(id: Signature['id'], options?: DonesafeApiOptions<Signature>, config?: ApiRequestConfig): AxiosPromise<Signature>;
}

export const createSignaturesApi = (api: AxiosInstance, path = '/api/signatures'): DonesafeSignaturesApi => {
  return {
    getSignature: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createSignature: (entity, params, config) => api.post(path, entity, { params, ...config }),
    deleteSignature: (id) => api.delete(`${path}/${id}`),
  };
};
