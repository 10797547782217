import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ModuleEntity } from '@app/models/module-entity';
import type { ApiRequestConfig, DonesafeIndexApiOptions, FilterValue } from '@app/services/donesafe-api-utils';

export interface DonesafeModuleEntitiesApi {
  getModuleEntities(
    options: DonesafeIndexApiOptions<
      ModuleEntity,
      { date_end?: string; date_start?: string; location_id?: FilterValue; record_type: string }
    >,
    config?: ApiRequestConfig
  ): AxiosPromise<ModuleEntity[]>;
}

export const createModuleEntitiesApi = (api: AxiosInstance, path = '/api/module_entities'): DonesafeModuleEntitiesApi => {
  return {
    getModuleEntities: (params, config) => api.get(path, { params, ...config }),
  };
};
