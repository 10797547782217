import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RecurringSetup } from '@app/models/recurring/recurring-setup';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeRecurringSetupsApi {
  getRecurringSetup(
    id: number | string,
    params?: DonesafeApiOptions<RecurringSetup>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<RecurringSetup>;
  getRecurringSetups(params: DonesafeIndexApiOptions<RecurringSetup>, config?: ApiRequestConfig): AxiosPromise<RecurringSetup[]>;
}

export const createRecurringSetupsApi = (api: AxiosInstance, path = '/api/recurring_setups'): DonesafeRecurringSetupsApi => {
  const recurringSetupsApi: DonesafeRecurringSetupsApi = {
    getRecurringSetups: (params, config) => api.get(path, { params, ...config }),
    getRecurringSetup: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...recurringSetupsApi,
  };
};
