import type { AxiosInstance, AxiosPromise } from 'axios';

export interface FollowResponse {
  follow: boolean;
}

interface FollowParams {
  followable_id: number | string;
  followable_type: string;
}

export interface DonesafeFollowsApi {
  followEntity(params: FollowParams): AxiosPromise<FollowResponse>;

  unfollowEntity(params: FollowParams): AxiosPromise<FollowResponse>;
}

export const createFollowsApi = (api: AxiosInstance, path = '/api/follows'): DonesafeFollowsApi => {
  return {
    followEntity: (params) => api.post(`${path}/follow`, params),
    unfollowEntity: (params) => api.post(`${path}/unfollow`, params),
  };
};
