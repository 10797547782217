import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { LmsCourseEnrollment } from '@app/models/lms-course-enrollment';

export interface DonesafeLmsCourseEnrollmentsApi {
  getLmsCourseEnrollment(
    id: number,
    params?: DonesafeApiOptions<LmsCourseEnrollment>,
    config?: ApiRequestConfig
  ): AxiosPromise<LmsCourseEnrollment>;

  getLmsCourseEnrollments(
    options: DonesafeIndexApiOptions<LmsCourseEnrollment>,
    config?: ApiRequestConfig
  ): AxiosPromise<LmsCourseEnrollment[]>;
}

export const createLmsCourseEnrollmentsApi = (
  api: AxiosInstance,
  path = '/api/lms_course_enrollments'
): DonesafeLmsCourseEnrollmentsApi => {
  return {
    getLmsCourseEnrollments: (params, config) => api.get(path, { params, ...config }),
    getLmsCourseEnrollment: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
