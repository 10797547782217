
  import { useAccountStore } from '@app/stores/account';
  import { API_VERSION_HEADER } from '@app/constants';
  import { getCsrfToken } from '@app/utils/get-csrf-token';
  import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
  import { Uploader } from '@app/services/uploader';
  import vueFilepond from 'vue-filepond';
  import { ValidationProvider } from 'vee-validate';
  // Import plugins
  import type { FilePondFile, FilePond, FilePondOptions, ActualFileObject, CaptureAttribute } from 'filepond';
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
  import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
  import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
  import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
  import FilePondPluginImageResize from 'filepond-plugin-image-resize';
  import MultipartUploader from '@app/services/multipart-uploader';
  import mime from 'mime-types';

  import FileUploadError from './file-upload-error.vue';
  import FileUploaderItem from './file-uploader-item.vue';

  // Create component
  const VueFilepond = vueFilepond(
    FilePondPluginFileValidateType,
    FilePondPluginImageExifOrientation,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImagePreview,
    FilePondPluginImageEdit
  );

  @Component({ components: { FileUploadError, FileUploaderItem, VueFilepond, ValidationProvider } })
  export default class FileUploader extends Vue {
    @Prop(String) readonly accept?: string;
    @Prop({ type: Boolean, default: false }) readonly allowFileTypeValidation!: boolean;
    @Prop({ type: Boolean, default: true }) readonly autoRemove!: boolean;
    @Prop(String) readonly captureMethod?: 'user' | 'environment' | CaptureAttribute;
    @Prop(Boolean) readonly hideButton!: boolean;
    @Prop({ type: Boolean, default: true }) readonly instantUpload!: boolean;
    @Prop(String) readonly label?: string;
    @Prop(Boolean) readonly local!: boolean;
    @Prop(Boolean) readonly multiple!: boolean;
    @Prop(String) readonly name!: string;
    @Prop({ type: Boolean, default: false }) readonly required!: boolean;
    @Prop(Boolean) readonly small!: boolean;
    @Prop(String) readonly toggleOn!: string;
    @Ref() readonly pond?: FilePond;
    @Ref() readonly validationProvider?: InstanceType<typeof ValidationProvider>;

    files: File[] = [];
    filesCount = 0;

    get allowImagePreview() {
      return !this.accountStore.data.one_hsi_ui;
    }

    get acceptTypes(): string[] | undefined {
      if (this.accept) {
        return this.accept.split(',');
      } else {
        return this.allowedAccountMimeTypes;
      }
    }

    get accountStore() {
      return useAccountStore();
    }

    get allowedAccountMimeTypes() {
      if (!!this.accountStore.data.file_upload_allowed_extensions?.length) {
        return this.accountStore.data.file_upload_allowed_extensions.reduce((acc: string[], ext: string) => {
          const convertedType = mime.lookup(ext);
          if (convertedType) acc.push(convertedType);
          return acc;
        }, []);
      }
    }

    get fileValidateTypeLabelExpectedTypes() {
      return 'Expects {allTypes}';
    }

    get fileValidateTypeLabelExpectedTypesMap() {
      return this.acceptTypes?.reduce((acc, type) => {
        return { ...acc, [type]: mime.extension(type) };
      }, {});
    }

    get server(): FilePondOptions['server'] {
      if (this.local) {
        return {};
      }
      return {
        process: this.serverFileProcess,
        revert: this.removeFile,
      };
    }

    addFile(file: Blob): Promise<FilePondFile> | undefined {
      return this.pond?.addFile(file);
    }

    fileValidateTypeDetectType(source: File, browserMimeType: string) {
      // If type is already defined, no need for detection
      if (browserMimeType) {
        return Promise.resolve(browserMimeType);
      }

      // If type is not defined, try to detect file type from file name.
      const ext = source.name?.split('.').pop();
      const mimeType = mime.lookup(ext);

      if (!mimeType) {
        this.$rollbar.error('Could not detect file type', { source });
        return Promise.resolve(browserMimeType);
      }

      return Promise.resolve(mimeType);
    }

    handleAddFile(error: Nullable<Error>, fpf: FilePondFile): void {
      if (!error) {
        this.$emit('files-loaded', fpf.file);
        if (this.instantUpload) {
          if (this.local) {
            fpf.abortLoad();
          } else {
            this.pond?.processFile(fpf);
          }
        }
      } else if (this.required && this.allowFileTypeValidation) {
        this.validationProvider?.validate(null).then(() => {
          this.validationProvider?.setErrors(['']);
        });
      }
      this.updateFilesCount();
    }

    handleFileRemove(): void {
      this.updateFilesCount();
    }

    handleFileUpload(error: Nullable<Error>, fpf: FilePondFile): void {
      if (this.autoRemove) {
        this.pond?.removeFile(fpf);
        this.updateFilesCount();
      }
    }

    openBrowser(): void {
      this.pond?.browse();
    }

    removeFile(data: { signed_id: string }): void {
      this.pond?.removeFile(data.signed_id);
      this.updateFilesCount();
    }

    serverFileProcess(
      fieldName: string,
      file: ActualFileObject,
      metadata: object,
      load: (blob: string) => void,
      error: (e: string) => void,
      progress: (lengthComputable: boolean, loaded: number, total: number) => void,
      abort: () => void
    ): { abort: () => void } {
      const UploaderCtr = window.DONESAFE.supportsMultipartUploads && file.size > 0 ? MultipartUploader : Uploader;
      const uploader = new UploaderCtr(file as File, {
        url: window.DONESAFE.directUploadUrl,
        headers: {
          'Accept-Version': API_VERSION_HEADER,
          'X-Client-Subdomain': window.DONESAFE.account?.subdomain || '',
          'Access-Token': window.DONESAFE.authentication_token,
          'X-CSRF-Token': getCsrfToken(),
        },
        onProgress: (e): void => progress(e.lengthComputable, e.loaded, e.total),
      });

      uploader
        .upload()
        .then((signedId) => {
          load(signedId);
          this.$emit('blob', {
            filename: file.name,
            content_type: file.type,
            byte_size: file.size,
            signed_id: signedId,
            blob: file,
            metadata,
          });
        })
        .catch((err) => {
          console.error(err);
          error(err);
        });

      return {
        abort: (): void => {
          uploader.cancel();
          abort();
        },
      };
    }

    updateFilesCount(): void {
      const files = this.pond?.getFiles() || [];
      this.filesCount = files.length;
      this.$emit('uploader-files', files);
    }
  }
