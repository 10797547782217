import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SubFormList } from '@app/models/sub-form-list';

export interface DonesafeManagedFormsApi {
  createManagedSubFormList(params: { module_name_id: number; title: string }): AxiosPromise<SubFormList>;

  updateManagedSubFormList(subFormListId: number, title: string): AxiosPromise<SubFormList>;
}

export const createManagedFormsApi = (api: AxiosInstance, path = '/api/managed_forms'): DonesafeManagedFormsApi => {
  return {
    createManagedSubFormList: (params) => api.post(path, params),
    updateManagedSubFormList: (subFormListId, title) => api.post(`${path}/${subFormListId}`, { title: title }),
  };
};
