import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ExpensableExpensingTableCategory } from '@app/models/expensable-expensing-table-category';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeExpensableExpensingTableCategoriesApi {
  getExpensingTableCategories(
    options: DonesafeIndexApiOptions<ExpensableExpensingTableCategory>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableExpensingTableCategory[]>;
}

export const createExpensableExpensingTableCategoriesApi = (
  api: AxiosInstance,
  path = '/api/expensing_table_categories'
): DonesafeExpensableExpensingTableCategoriesApi => {
  return {
    getExpensingTableCategories: (params, config) => api.get(path, { params, ...config }),
  };
};
