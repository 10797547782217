import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { WorkflowLink } from '@app/models/workflow-link';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeWorkflowLinksApi {
  createWorkflowLink(entity: Partial<WorkflowLink>, params?: DonesafeApiOptions<WorkflowLink>): AxiosPromise<WorkflowLink>;
  deleteWorkflowLink(id: WorkflowLink['id']): AxiosPromise<WorkflowLink>;
  getWorkflowLink(
    id: WorkflowLink['id'],
    params?: DonesafeApiOptions<WorkflowLink>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<WorkflowLink>;
  getWorkflowLinks(options: DonesafeIndexApiOptions<WorkflowLink>, config?: ApiRequestConfig): AxiosPromise<WorkflowLink[]>;
  updateWorkflowLink(
    id: WorkflowLink['id'],
    entity: Partial<WorkflowLink>,
    params?: DonesafeApiOptions<WorkflowLink>
  ): AxiosPromise<WorkflowLink>;
}

export const createWorkflowLinksApi = (api: AxiosInstance, path = '/api/workflow_links'): DonesafeWorkflowLinksApi => {
  const workflowLinksApi: DonesafeWorkflowLinksApi = {
    deleteWorkflowLink: (id) => api.delete(`${path}/${id}`),
    getWorkflowLink: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getWorkflowLinks: (params, config) => api.get(path, { params, ...config }),
    updateWorkflowLink: (id, entity, params) => api.put(`${path}/${id}`, entity, { params }),
    createWorkflowLink: (entity, params) => api.post(path, entity, { params }),
  };

  return {
    ...workflowLinksApi,
    getWorkflowLink: convertToJoinedRequest<WorkflowLink>(workflowLinksApi.getWorkflowLink, workflowLinksApi.getWorkflowLinks),
  };
};
