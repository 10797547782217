import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ProcedureAcknowledgement } from '@app/models/procedure-acknowledgement';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeProcedureAcknowledgementsApi {
  getProcedureAcknowledgements(
    params: DonesafeIndexApiOptions<ProcedureAcknowledgement>,
    config?: ApiRequestConfig
  ): AxiosPromise<ProcedureAcknowledgement[]>;
}

export const createProcedureAcknowledgementsApi = (
  api: AxiosInstance,
  path = '/api/procedure_acknowledgements'
): DonesafeProcedureAcknowledgementsApi => {
  return {
    getProcedureAcknowledgements: (params, config) => api.get(path, { params, ...config }),
  };
};
