import type { AxiosInstance, AxiosPromise } from 'axios';
import type { DebugQueryBuilderBlob } from '@app/models/query-builder-blob';
import type { ApiRequestConfig } from '@app/services/donesafe-api-utils';
import type { RuleSet } from '@app/models/rule-set';

export interface RecordParams {
  record_id: number;
  record_type: string;
}

export interface DebugRuleSet {
  global: boolean;
  results?: DebugQueryBuilderBlob;
}
export interface DonesafeRuleSetsApi {
  debugRuleSet(params: { id?: RuleSet['id'] } & RecordParams, config?: ApiRequestConfig): AxiosPromise<DebugRuleSet>;
}

export const createRuleSetsApi = (api: AxiosInstance, path = '/api/rule_sets'): DonesafeRuleSetsApi => {
  return {
    debugRuleSet: (params, config) => api.get(`${path}/debug`, { params, ...config }),
  };
};
