import type { AxiosInstance, AxiosPromise } from 'axios';
import type { CommentParams } from './activities-api';
import type { Comment } from '@app/models/comment';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { RequiredBy } from '@app/utils/types/required-by';

type MandatoryCreateCommentParams = Pick<Comment, 'commentable_id' | 'commentable_type'>;

export type SubmitCommentEvent = {
  data: CommentParams;
  mode: 'approve' | 'reopen' | 'comment' | 'close' | 'unapprove';
};

export interface DonesafeCommentsApi {
  createComment(comment: RequiredBy<CommentParams, 'comment'> & MandatoryCreateCommentParams): AxiosPromise<Comment>;

  deleteComment(id: number | string): AxiosPromise<Comment>;

  getComments(options: DonesafeIndexApiOptions<Comment>, config?: ApiRequestConfig): AxiosPromise<Comment[]>;
}

export const createCommentsApi = (api: AxiosInstance, path = '/api/comments'): DonesafeCommentsApi => {
  return {
    getComments: (params, config) => api.get(path, { params, ...config }),
    createComment: (comment) => api.post(path, comment),
    deleteComment: (id) => api.delete(`${path}/${id}`),
  };
};
