import type { AxiosInstance, AxiosPromise } from 'axios';
import type { LocationTag } from '@app/models/location-tag';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeLocationTagsApi {
  createLocationTag(locationTag: Partial<LocationTag>): AxiosPromise<LocationTag>;

  deleteLocationTag(id: number): AxiosPromise<LocationTag>;

  getLocationTag(
    id: number,
    params?: DonesafeApiOptions<LocationTag>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<LocationTag>;

  getLocationTags(options: DonesafeIndexApiOptions<LocationTag>, config?: ApiRequestConfig): AxiosPromise<LocationTag[]>;

  updateLocationTag(id: number, entity?: Partial<LocationTag>): AxiosPromise<LocationTag>;
}

export const createLocationTagsApi = (api: AxiosInstance, path = '/api/location_tags'): DonesafeLocationTagsApi => {
  return {
    getLocationTags: (params, config) => api.get(path, { params, ...config }),
    getLocationTag: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createLocationTag: (locationTag) => api.post(path, locationTag),
    updateLocationTag: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteLocationTag: (id) => api.delete(`${path}/${id}`),
  };
};
