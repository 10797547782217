import moment from 'moment';
import { select2IconResponseTemplate } from '@app/utils/select2-icon-response-template';
import { select2ResponseTemplate } from '@app/utils/select2-response-template';

export const SECTION_SHOWN_EVENT_NAME = (id: number) => `section-shown-${id}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const arrayify = (value: any) => $.map([value], (n) => n);

const dsAjaxEvent = function (action: string, source: string): CustomEvent {
  return new CustomEvent('dsAjaxTrigger', { detail: { action: action, source: source } });
};

const triggerDsAjaxStart = (source: string): boolean => document.dispatchEvent(dsAjaxEvent('started', source));

const triggerDsAjaxStop = (source: string): boolean => document.dispatchEvent(dsAjaxEvent('stopped', source));

const initDatePicker = (element: HTMLElement): void => {
  const $element = $(element);
  if (!$element.data('readonly')) {
    let startDate, endDate;

    if ($element.data('startDate')) {
      startDate = new Date($element.data('startDate').replace('+00:00', ''));
    } else {
      startDate = '-200y';
    }

    if ($element.data('endDate')) {
      endDate = new Date($element.data('endDate').replace('+00:00', ''));
    } else {
      endDate = '+200y';
    }

    $element.datepicker({
      startDate: startDate,
      endDate: endDate,
    });
  }
};

const initDatetimePicker = (element: HTMLElement): void => {
  const $element = $(element);
  if (!$element.data('readonly')) {
    let startDate, endDate;

    if ($element.data('startDatetime')) {
      startDate = moment($element.data('startDatetime').replace('+00:00', '')).toDate();
    } else {
      startDate = new Date('1/1/1800');
    }
    if ($element.data('endDatetime')) {
      endDate = moment($element.data('endDatetime').replace('+00:00', '')).toDate();
    } else {
      endDate = new Date('1/1/2200');
    }
    $element.datetimepicker({
      minDate: startDate,
      maxDate: endDate,
      keepInvalid: true,
      useCurrent: false,
    });
    $element.data('DateTimePicker')?.keepInvalid(false);
  }
};

const initSubForm = (): void => {
  $('.type-multi-select').on('select2:unselect', (e) => {
    const value = $(e.currentTarget).val() as string[];
    if (!value || (value.length === 1 && !value[0])) {
      $(e.currentTarget).val(null as unknown as string); // TODO
    }
  });

  $('.custom-select2')
    .select2({
      width: '100%',
      allowClear: true,
      placeholder: 'Select...',
    })
    .on('select2:unselect', function (e: JQuery.TriggeredEvent) {
      const $el = $(e.currentTarget);

      // must send something to cause record update, so select field can be cleared
      // [''] will be stripped when saving record
      if (!$el.val()) {
        $el.val(['']);
      }
    });

  $('.custom-datetimepicker-input').each(function () {
    initDatetimePicker(this);
  });

  $('.custom-datepicker-input').each(function () {
    initDatePicker(this);
  });

  $(document).find('[data-parsley-validate]').parsley();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const destroyVueComponent = (index: number, e: any): void => {
  if (!e?.__vue__) {
    return;
  }
  // Uncomment it if you want to see all manually destroyed components
  // console.info(`Component[${index}] will be destroyed`, e.__vue__.$el.firstChild);
  e.__vue__.$destroy();
};

window.select2ResponseTemplate = select2ResponseTemplate;
window.select2IconResponseTemplate = select2IconResponseTemplate;
window.initSubForm = initSubForm;
window.arrayify = arrayify;
window.initDatePicker = initDatePicker;
window.initDatetimePicker = initDatetimePicker;
window.triggerDsAjaxStart = triggerDsAjaxStart;
window.triggerDsAjaxStop = triggerDsAjaxStop;
window.destroyVueComponent = destroyVueComponent;
