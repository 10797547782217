import type { default as _Vue } from 'vue';
import type Rollbar from 'rollbar';
import ensureRollbar from '@app/services/rollbar';

const withRollbar = (Vue: typeof _Vue): typeof _Vue => {
  Vue.prototype.$rollbar = ensureRollbar();

  Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar?.error(err);
    throw err;
  };

  return Vue;
};

declare module 'vue/types/vue' {
  interface Vue {
    readonly $rollbar: Rollbar;
  }
}

export default withRollbar;
