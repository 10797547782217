import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ExpenseCategory } from '@app/models/expense-category';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeExpenseCategoriesApi {
  getExpenseCategories(options: DonesafeIndexApiOptions<ExpenseCategory>, config?: ApiRequestConfig): AxiosPromise<ExpenseCategory[]>;
  getExpenseCategory(
    id: number | string,
    params?: DonesafeApiOptions<ExpenseCategory>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpenseCategory>;
}

export const createExpenseCategoriesApi = (api: AxiosInstance, path = '/api/expense_categories'): DonesafeExpenseCategoriesApi => {
  return {
    getExpenseCategories: (params, config) => api.get(path, { params, ...config }),
    getExpenseCategory: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
