import type { AxiosInstance, AxiosPromise } from 'axios';
import type { UserRecordCalendar } from '@app/models/user-record-calendar';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeUserRecordCalendarsApi {
  createUserRecordCalendar(entity: Partial<UserRecordCalendar> & { global?: boolean }): AxiosPromise<UserRecordCalendar>;

  deleteUserRecordCalendar(id: number): AxiosPromise<UserRecordCalendar>;

  getUserRecordCalendar(
    id: number,
    params: DonesafeApiOptions<UserRecordCalendar>,
    config?: ApiRequestConfig
  ): AxiosPromise<UserRecordCalendar>;

  getUserRecordCalendars(
    params: DonesafeIndexApiOptions<UserRecordCalendar>,
    config?: ApiRequestConfig
  ): AxiosPromise<UserRecordCalendar[]>;

  refreshUserRecordCalendar(id: number): AxiosPromise<UserRecordCalendar>;

  updateUserRecordCalendar(id: number, entity: Partial<UserRecordCalendar>): AxiosPromise<UserRecordCalendar>;
}

export const createUserRecordCalendarsApi = (api: AxiosInstance, path = '/api/user_record_calendars'): DonesafeUserRecordCalendarsApi => {
  return {
    deleteUserRecordCalendar: (id: number) => api.delete(`${path}/${id}`),
    refreshUserRecordCalendar: (id: number) => api.post(`${path}/${id}/refresh`),
    updateUserRecordCalendar: (id: number, entity) => api.put(`${path}/${id}`, entity, {}),
    createUserRecordCalendar: (entity: Partial<UserRecordCalendar>) => api.post(path, entity),
    getUserRecordCalendars: (params, config) => api.get(path, { params, ...config }),
    getUserRecordCalendar: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
