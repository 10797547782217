import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SubFormCompletionCollection } from '@app/models/sub-form-completion-collection';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeSubFormCompletionCollectionsApi {
  getSubFormCompletionCollection(
    id: number | string,
    params?: DonesafeApiOptions<SubFormCompletionCollection>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubFormCompletionCollection>;

  getSubFormCompletionCollections(
    options: DonesafeIndexApiOptions<SubFormCompletionCollection>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormCompletionCollection[]>;
}

export const createSubFormCompletionCollectionsApi = (
  api: AxiosInstance,
  path = '/api/sub_form_completion_collections'
): DonesafeSubFormCompletionCollectionsApi => {
  return {
    getSubFormCompletionCollections: (params, config) => api.get(path, { params, ...config }),
    getSubFormCompletionCollection: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
