import type { PartialNullBy } from '@app/utils/types/partial';
import type { AxiosInstance, AxiosPromise } from 'axios';
import { getCsrfConfig } from '@app/utils/get-csrf-config';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { Organization } from '@app/models/organization';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions, ApiRequestJoinConfig } from '@app/services/donesafe-api-utils';

export interface DonesafeOrganizationsApiExtraOptions {
  with_restrictions?: boolean;
}

export interface DonesafeOrganizationsApi {
  createOrganization(organization: Partial<Organization>): AxiosPromise<Organization>;

  deleteOrganization(id: number): AxiosPromise<Organization>;

  getOrganization(
    id: number,
    params?: DonesafeApiOptions<Organization>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Organization>;

  getOrganizations(
    options: DonesafeIndexApiOptions<Organization, DonesafeOrganizationsApiExtraOptions>,
    config?: ApiRequestConfig
  ): AxiosPromise<Organization[]>;

  updateOrganization(id: number, entity?: Partial<PartialNullBy<Organization, 'organization_id'>>): AxiosPromise<Organization>;
}

export const createOrganizationsApi = (api: AxiosInstance, path = '/api/organizations'): DonesafeOrganizationsApi => {
  const organizationsApi: DonesafeOrganizationsApi = {
    getOrganizations: (params, config) => api.get(path, { params, ...config, ...getCsrfConfig() }),
    createOrganization: (organization) => api.post(path, organization),
    updateOrganization: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteOrganization: (id) => api.delete(`${path}/${id}`),
    getOrganization: (id, params, config) => api.get(`${path}/${id}`, { params, ...config, ...getCsrfConfig() }),
  };

  return {
    ...organizationsApi,
    getOrganization: convertToJoinedRequest<Organization>(organizationsApi.getOrganization, organizationsApi.getOrganizations),
  };
};
