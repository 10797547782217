import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { FeatureSet } from '@app/models/feature-set';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeFeatureSetsApi {
  getFeatureSet(
    id: number | string,
    params?: DonesafeApiOptions<FeatureSet>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<FeatureSet>;
  getFeatureSets(params?: DonesafeIndexApiOptions<FeatureSet>, config?: ApiRequestConfig): AxiosPromise<FeatureSet[]>;
}

export const createFeatureSetsApi = (api: AxiosInstance, path = '/api/feature_sets'): DonesafeFeatureSetsApi => {
  const featureSetsApi: DonesafeFeatureSetsApi = {
    getFeatureSets: (params, config) => api.get(path, { params, ...config }),
    getFeatureSet: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...featureSetsApi,
    getFeatureSet: convertToJoinedRequest<FeatureSet>(featureSetsApi.getFeatureSet, featureSetsApi.getFeatureSets),
  };
};
