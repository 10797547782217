import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SubFormQuestionSet } from '@app/models/sub-form-question-set';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeSubFormQuestionSetsApi {
  createQuestionSet(entity?: Partial<SubFormQuestionSet>): AxiosPromise<SubFormQuestionSet>;

  getQuestionSet(id: number, params: DonesafeApiOptions<SubFormQuestionSet>, config?: ApiRequestConfig): AxiosPromise<SubFormQuestionSet>;

  getQuestionSets(params: DonesafeIndexApiOptions<SubFormQuestionSet>, config?: ApiRequestConfig): AxiosPromise<SubFormQuestionSet[]>;

  updateQuestionSet(id: number | string, entity?: Partial<SubFormQuestionSet>): AxiosPromise<SubFormQuestionSet>;
}

export const createSubFormQuestionSetsApi = (api: AxiosInstance, path = '/api/sub_form_question_sets'): DonesafeSubFormQuestionSetsApi => {
  return {
    getQuestionSets: (params, config) => api.get(path, { params, ...config }),
    getQuestionSet: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateQuestionSet: (id, entity) => api.put(`${path}/${id}`, entity),
    createQuestionSet: (entity) => api.post(path, entity),
  };
};
