import { dispatchers } from '@app/services/courier';
import { useAccountStore } from '@app/stores/account';

export default function (
  e: MouseEvent,
  url: string,
  action: 'redirect' | 'location',
  opts: { prevent?: boolean; target?: '_self' | '_blank' } = { prevent: true, target: '_self' }
) {
  if (!useAccountStore().data.one_hsi_ui) return;
  const { prevent, target } = opts;
  prevent && e.preventDefault();

  switch (action) {
    case 'redirect':
      dispatchers.redirect.dispatch({
        target: 'window',
        location: url,
        windowTarget: target,
      });
      break;
    case 'location':
      dispatchers.location.dispatch(`${window.location.origin}${url}`);
      break;
  }
}
