import type { AxiosInstance, AxiosPromise } from 'axios';
import type { PgDocument } from '@app/models/pg-document';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface GlobalSearchParams {
  search_type?: 'records' | 'config';
}

export interface DonesafeGlobalSearchApi {
  globalSearch(options: DonesafeIndexApiOptions<PgDocument, GlobalSearchParams>, config?: ApiRequestConfig): AxiosPromise<PgDocument[]>;
}

export const createGlobalSearchApi = (api: AxiosInstance, path = '/api/global_search'): DonesafeGlobalSearchApi => {
  return {
    globalSearch: (params, config) => api.get(path, { params, ...config }),
  };
};
