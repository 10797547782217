import type { AxiosInstance, AxiosPromise } from 'axios';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';
import type DashboardPane from '@app/models/dashboard-pane';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';

export interface DonesafeDashboardPanesApi {
  createDashboardPane(entity: Partial<DashboardPane>): AxiosPromise<DashboardPane>;

  deleteDashboardPane(id: number | string): AxiosPromise<DashboardPane>;

  getDashboardPane(
    id: number | string,
    params?: DonesafeApiOptions<DashboardPane>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<DashboardPane>;

  getDashboardPanes(
    options: DonesafeIndexApiOptions<DashboardPane, { accessible?: boolean }>,
    config?: ApiRequestConfig
  ): AxiosPromise<DashboardPane[]>;

  updateDashboardPane(id: number | string, entity: Partial<DashboardPane>): AxiosPromise<DashboardPane>;

  updateDashboardPanesIndexes(params: UpdateIndexParams): AxiosPromise<void>;
}

export const createDashboardPanesApi = (api: AxiosInstance, path = '/api/dashboard_panes'): DonesafeDashboardPanesApi => {
  const dashboardPanesApi: DonesafeDashboardPanesApi = {
    createDashboardPane: (entity) => api.post(path, entity),
    updateDashboardPane: (id, entity) => api.put(`${path}/${id}`, entity),
    getDashboardPanes: (params, config) => api.get(path, { params, ...config }),
    getDashboardPane: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    deleteDashboardPane: (id) => api.delete(`${path}/${id}`),
    updateDashboardPanesIndexes: (params: UpdateIndexParams) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...dashboardPanesApi,
    getDashboardPane: convertToJoinedRequest(dashboardPanesApi.getDashboardPane, dashboardPanesApi.getDashboardPanes),
  };
};
