import type {
  DonesafeIndexApiOptions,
  DonesafeApiIncludeOptions,
  ApiRequestConfig,
  DonesafeApiOptions,
} from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Dictionary } from '@app/models/dictionary';
import type { SubFormCompletion, SubFormCompletionStage } from '@app/models/sub-form-completion';
import type { TenantUser } from '@app/models/tenant-user';
import type { VisitorAppVisitCourseRecord } from '@app/models/visitor-app-visit-course-record';
import type { VisitorAppVisitRecord } from '@app/models/visitor-app-visit-record';

export interface VisitorAppRequiredParams {
  mobile_app_registration_uuid: string;
}
export interface VisitorAppApi {
  completeVisitorAppVisitCourseRecord(
    visitId: string,
    courseRecordId: number | string,
    params: DonesafeApiIncludeOptions<VisitorAppVisitCourseRecord> &
      Partial<SubFormCompletion> & {
        mobile_app_registration_uuid: string;
        responses: object;
        stage?: SubFormCompletionStage;
      }
  ): AxiosPromise<VisitorAppVisitCourseRecord>;

  getVisitorAppVisit(
    id: string,
    params?: DonesafeApiOptions<VisitorAppVisitRecord>,
    config?: ApiRequestConfig
  ): AxiosPromise<VisitorAppVisitRecord>;

  getVisitorAppVisitCourseRecords(
    id: string,
    params?: DonesafeApiOptions<VisitorAppVisitCourseRecord>,
    config?: ApiRequestConfig
  ): AxiosPromise<VisitorAppVisitCourseRecord[]>;

  getVisitorAppVisitees(
    params: VisitorAppRequiredParams & Pick<DonesafeIndexApiOptions<TenantUser>, 'filters'>,
    config?: ApiRequestConfig
  ): AxiosPromise<{ users: Pick<TenantUser, 'id' | 'full_name' | 'uuid'>[] }>;

  getVisitorAppVisitors(
    params: { query: string } & VisitorAppRequiredParams,
    config?: ApiRequestConfig
  ): AxiosPromise<{ out_of_scope: boolean; users: Pick<TenantUser, 'id' | 'full_name' | 'uuid'>[] }>;

  getVisitorAppVisits(params?: DonesafeApiOptions<VisitorAppVisitRecord>, config?: ApiRequestConfig): AxiosPromise<VisitorAppVisitRecord[]>;

  prepareVisitorAppVisitCourseRecord(
    id: string,
    params: DonesafeApiOptions<VisitorAppVisitCourseRecord> & { course_id: string | number } & VisitorAppRequiredParams
  ): AxiosPromise<VisitorAppVisitCourseRecord>;

  printVisitorAppVisitLabel(id: string, params: VisitorAppRequiredParams): AxiosPromise<{ error?: string; success: boolean }>;
  visitorAppCreateVisitor(
    params: {
      email: string;
      first_name: string;
      last_name: string;
      mobile: string;
    } & VisitorAppRequiredParams
  ): AxiosPromise<Pick<TenantUser, 'id' | 'first_name' | 'last_name' | 'mobile' | 'email' | 'uuid'>>;
  visitorAppSignIn(
    params: {
      extra_data?: Dictionary<string>;
      photo: string;
      visitee: string;
      visitor: string;
    } & VisitorAppRequiredParams
  ): AxiosPromise<VisitorAppVisitRecord>;
  visitorAppSignOut(params: { visit_id: string } & VisitorAppRequiredParams): AxiosPromise<VisitorAppVisitRecord>;
}

export const createVisitorAppApi = (api: AxiosInstance, path = '/api/visitor_app'): VisitorAppApi => {
  return {
    visitorAppSignIn: (params) => api.post(`${path}/sign_in`, params),
    visitorAppCreateVisitor: (params) => api.post(`${path}/create_visitor`, params),
    visitorAppSignOut: (params) => api.post(`${path}/sign_out`, params),
    getVisitorAppVisit: (id, params, config) => api.get(`${path}/visits/${id}`, { params, ...config }),
    getVisitorAppVisitCourseRecords: (id, params, config) => api.get(`${path}/visits/${id}/course_records`, { params, ...config }),
    prepareVisitorAppVisitCourseRecord: (id, params) => api.post(`${path}/visits/${id}/course_records`, params),
    completeVisitorAppVisitCourseRecord: (id, courseRecordId, params) =>
      api.post(`${path}/visits/${id}/course_records/${courseRecordId}/complete`, params),
    getVisitorAppVisits: (params, config) => api.get(`${path}/visits`, { params, ...config }),
    getVisitorAppVisitees: (params, config) => api.get(`${path}/visitees`, { params, ...config }),
    getVisitorAppVisitors: (params, config) => api.get(`${path}/visitors`, { params, ...config }),
    printVisitorAppVisitLabel: (id, params) => api.post(`${path}/visits/${id}/print`, params),
  };
};
