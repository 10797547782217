import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ActionPriority } from '@app/models/action-priority';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  UpdateIndexParams,
  DonesafeIndexApiOptions,
} from '@app/services/donesafe-api-utils';

export interface DonesafeActionPrioritiesApi {
  createActionPriority(entity: Partial<ActionPriority>): AxiosPromise<ActionPriority>;
  getActionPriorities(options?: DonesafeIndexApiOptions<ActionPriority>, config?: ApiRequestConfig): AxiosPromise<ActionPriority[]>;
  getActionPriority(
    id: ActionPriority['id'],
    options?: DonesafeApiOptions<ActionPriority>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ActionPriority>;
  updateActionPrioritiesIndexes(params: UpdateIndexParams): AxiosPromise<void>;
  updateActionPriority(id: ActionPriority['id'], entity: Partial<ActionPriority>): AxiosPromise<ActionPriority>;
}

export const createActionPrioritiesApi = (api: AxiosInstance, path = '/api/action_priorities'): DonesafeActionPrioritiesApi => {
  const ret: DonesafeActionPrioritiesApi = {
    getActionPriority: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getActionPriorities: (params = {}, config = {}) => api.get(path, { params, ...config }),
    createActionPriority: (entity) => api.post(path, entity),
    updateActionPriority: (id, entity) => api.put(`${path}/${id}`, entity),
    updateActionPrioritiesIndexes: (params: UpdateIndexParams) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...ret,
    getActionPriority: convertToJoinedRequest<ActionPriority>(ret.getActionPriority, ret.getActionPriorities),
  };
};
