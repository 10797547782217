import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { Involvement } from '@app/models/involvement';
import type {
  ApiRequestConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
  ApiRequestJoinConfig,
} from '@app/services/donesafe-api-utils';

export interface DonesafeInvolvementsApi {
  createInvolvement(entity?: Partial<Involvement>): AxiosPromise<Involvement>;

  getInvolvement(
    id: number | string,
    params?: DonesafeApiOptions<Involvement>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Involvement>;

  getInvolvements(options: DonesafeIndexApiOptions<Involvement>, config?: ApiRequestConfig): AxiosPromise<Involvement[]>;

  updateInvolvement(id: number | string, entity?: Partial<Involvement>): AxiosPromise<Involvement>;

  updateInvolvementIndexes(params: UpdateIndexParams): AxiosPromise<Involvement[]>;
}

export const createInvolvementsApi = (api: AxiosInstance, path = '/api/involvements'): DonesafeInvolvementsApi => {
  const involvementsApi: DonesafeInvolvementsApi = {
    getInvolvements: (params, config) => api.get(path, { params, ...config }),
    getInvolvement: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateInvolvement: (id, entity) => api.put(`${path}/${id}`, entity),
    createInvolvement: (entity) => api.post(path, entity),
    updateInvolvementIndexes: (params) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...involvementsApi,
    getInvolvement: convertToJoinedRequest<Involvement>(involvementsApi.getInvolvement, involvementsApi.getInvolvements),
  };
};
