import type { EventOccurrence, EventOccurrenceAttributes } from '@app/models/event-occurrence';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ApiRequestConfig, DonesafeIndexApiOptions, DonesafeApiIncludeOptions } from '@app/services/donesafe-api-utils';

export interface EventOccurrencesExtraFilters {
  triggered?: string;
  triggered_concept?: string;
}

export interface DonesafeEventOccurrencesApi {
  getEventOccurrenceAttributes(
    options?: DonesafeIndexApiOptions<EventOccurrence>,
    config?: ApiRequestConfig
  ): AxiosPromise<EventOccurrenceAttributes>;
  getEventOccurrences(
    options?: DonesafeIndexApiOptions<EventOccurrence, EventOccurrencesExtraFilters>,
    config?: ApiRequestConfig
  ): AxiosPromise<EventOccurrence[]>;
  retryEventOccurrence(entity: EventOccurrence, options?: DonesafeApiIncludeOptions<EventOccurrence>): AxiosPromise<EventOccurrence>;
}

const path = '/api/event_occurrences';

export const createEventOccurrencesApi = (api: AxiosInstance): DonesafeEventOccurrencesApi => {
  return {
    getEventOccurrences: (params, config) => api.get(path, { params, ...config }),
    getEventOccurrenceAttributes: (params, config) => api.get(`${path}/attributes`, { params, ...config }),
    retryEventOccurrence: (entity, options) => api.post(`${path}/${entity.id}/retry`, options),
  };
};
