import { I18n } from 'i18n-js';
import translations from './translations';

const i18nInstance = new I18n();
i18nInstance.defaultLocale = window.DONESAFE.defaultLocale;
i18nInstance.locale = window.DONESAFE.locale;
i18nInstance.enableFallback = true;

const fetchLocale = async (i18n: I18n, locale: keyof typeof translations) => {
  const data = (await translations[locale]?.()) || {};
  i18n.store(data);
};
export const initI18n = () =>
  Promise.all([
    fetchLocale(i18nInstance, i18nInstance.defaultLocale as keyof typeof translations),
    fetchLocale(i18nInstance, i18nInstance.locale as keyof typeof translations),
  ]);
export default i18nInstance;
