import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { ScheduledEvent } from '@app/models/scheduled-event';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
} from '@app/services/donesafe-api-utils';

export interface DonesafeScheduledEventsApi {
  createScheduledEvent(
    data: Partial<ScheduledEvent> & DonesafeApiOptions<ScheduledEvent>,
    config?: ApiRequestConfig
  ): AxiosPromise<ScheduledEvent>;
  deleteScheduledEvent(id: number | string): AxiosPromise<ScheduledEvent>;
  getScheduledEvent(
    id: ScheduledEvent['id'],
    params?: DonesafeApiOptions<ScheduledEvent>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ScheduledEvent>;
  getScheduledEvents(params: DonesafeIndexApiOptions<ScheduledEvent>, config?: ApiRequestConfig): AxiosPromise<ScheduledEvent[]>;
  getScheduledEventsWithSharedFilters(
    options: DonesafeIndexApiOptions<ScheduledEvent>,
    filterKey: keyof ScheduledEvent,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<ScheduledEvent[]>;
  updateScheduledEvent(
    id: ScheduledEvent['id'],
    data: Partial<ScheduledEvent> & DonesafeApiOptions<ScheduledEvent>,
    config?: ApiRequestConfig
  ): AxiosPromise<ScheduledEvent>;
}

export const createScheduledEventsApi = (api: AxiosInstance, path = '/api/scheduled_events'): DonesafeScheduledEventsApi => {
  const scheduledEventsApi: Omit<DonesafeScheduledEventsApi, 'getScheduledEventsWithSharedFilters'> = {
    getScheduledEvents: (params, config) => api.get(path, { params, ...config }),
    getScheduledEvent: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    deleteScheduledEvent: (id): AxiosPromise<ScheduledEvent> => api.delete(`${path}/${id}`),
    createScheduledEvent: (data, config) => api.post(path, data, config),
    updateScheduledEvent: (id, data, config) => api.put(`${path}/${id}`, data, config),
  };

  return {
    ...scheduledEventsApi,
    getScheduledEvent: convertToJoinedRequest<ScheduledEvent>(scheduledEventsApi.getScheduledEvent, scheduledEventsApi.getScheduledEvents),
    getScheduledEventsWithSharedFilters: sharedFilterListRequest(scheduledEventsApi.getScheduledEvents),
  };
};
