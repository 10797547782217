import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ExpensableCategory } from '@app/models/expensable-category';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeExpensableCategoriesApi {
  getExpensableCategories(
    options: DonesafeIndexApiOptions<ExpensableCategory>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableCategory[]>;
}

export const createExpensableCategoriesApi = (api: AxiosInstance, path = '/api/expensing_categories'): DonesafeExpensableCategoriesApi => {
  return {
    getExpensableCategories: (params, config) => api.get(path, { params, ...config }),
  };
};
