import type { ApprovedExpense } from '@app/models/approved_expense';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeApprovedExpensesApi {
  getApprovedExpenses(options: DonesafeIndexApiOptions<ApprovedExpense>, config?: ApiRequestConfig): AxiosPromise<ApprovedExpense[]>;
}

export const createApprovedExpensesApi = (api: AxiosInstance, path = '/api/approved_expenses'): DonesafeApprovedExpensesApi => {
  return {
    getApprovedExpenses: (params, config) => api.get(path, { params, ...config }),
  };
};
