import { includes } from 'lodash';

import { changeLocation } from './change-location';

export const openModalOrGoToLink = async (modal: boolean, url: string, modalDivId = 'modal'): Promise<boolean> =>
  await new Promise((resolve, reject) => {
    if (!url) {
      return reject(false);
    }

    const modalSelector = `#${modalDivId}`;
    const modalDiv = $(modalSelector);

    if (modal) {
      modalDiv
        .off('loaded.bs.modal')
        .off('hide.bs.modal')
        .modal({ backdrop: 'static', remote: `${url}${includes(url, '?') ? '&' : '?'}modal=true` })
        .on('loaded.bs.modal', () => window.applySelect2Config(modalSelector))
        .on('hide.bs.modal', (e: Event) => {
          if ((e.target as HTMLDivElement).id === modalDivId) {
            return resolve(true);
          }
        });
    } else {
      changeLocation(url);
      return resolve(true);
    }
  });
