import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SubFormListRole } from '../../models/sub-form-list-role';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeSubFormListRolesApi {
  getSubFormListRole(
    id: number | string,
    params?: DonesafeApiOptions<SubFormListRole>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubFormListRole>;
  getSubFormListRoles(options: DonesafeIndexApiOptions<SubFormListRole>, config?: ApiRequestConfig): AxiosPromise<SubFormListRole[]>;
}

export const createSubFormListRolesApi = (api: AxiosInstance, path = '/api/sub_form_list_roles'): DonesafeSubFormListRolesApi => {
  const restrictionsApi: DonesafeSubFormListRolesApi = {
    getSubFormListRoles: (params, config) => api.get(path, { params, ...config }),
    getSubFormListRole: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...restrictionsApi,
    getSubFormListRole: convertToJoinedRequest<SubFormListRole>(restrictionsApi.getSubFormListRole, restrictionsApi.getSubFormListRoles),
  };
};
