import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ServiceCategory } from '@app/models/service-category';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeServiceCategoriesApi {
  getServiceCategories(params: DonesafeIndexApiOptions<ServiceCategory>, config?: ApiRequestConfig): AxiosPromise<ServiceCategory[]>;
  getServiceCategory(
    id: number,
    params?: DonesafeApiOptions<ServiceCategory>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ServiceCategory>;
}

export const createServiceCategoriesApi = (api: AxiosInstance, path = '/api/service_categories'): DonesafeServiceCategoriesApi => {
  const serviceCategoriesApi: DonesafeServiceCategoriesApi = {
    getServiceCategories: (params, config) => api.get(path, { params, ...config }),
    getServiceCategory: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...serviceCategoriesApi,
    getServiceCategory: convertToJoinedRequest(serviceCategoriesApi.getServiceCategory, serviceCategoriesApi.getServiceCategories),
  };
};
