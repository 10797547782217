import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RecordIndicator } from '@app/models/record-indicator';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeRecordIndicatorsApi {
  createRecordIndicator(entity: Partial<RecordIndicator>): AxiosPromise<RecordIndicator>;

  deleteRecordIndicator(id: number | string): AxiosPromise<RecordIndicator>;

  getRecordIndicators(options: DonesafeIndexApiOptions<RecordIndicator>, config?: ApiRequestConfig): AxiosPromise<RecordIndicator[]>;
}

export const createRecordIndicatorsApi = (api: AxiosInstance, path = '/api/record_indicators'): DonesafeRecordIndicatorsApi => {
  return {
    getRecordIndicators: (params, config) => api.get(path, { params, ...config }),
    createRecordIndicator: (entity) => api.post(path, entity),
    deleteRecordIndicator: (id) => api.delete(`${path}/${id}`),
  };
};
