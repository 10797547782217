import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Attachment } from '@app/models/attachment';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
} from '@app/services/donesafe-api-utils';

interface ExtraAttachmentsFilters {
  attachable_sub_form_response?: { sub_form_completion_id: FilterValue };
}

export interface DonesafeAttachmentsApi {
  createAttachment(entity: Partial<Attachment> & { file: string }, params?: DonesafeApiOptions<Attachment>): AxiosPromise<Attachment>;

  deleteAttachment(id: number): AxiosPromise<Attachment>;

  getAttachment(
    id: number,
    params?: DonesafeApiOptions<Attachment>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Attachment>;

  getAttachments(
    options: DonesafeIndexApiOptions<Attachment, ExtraAttachmentsFilters>,
    config?: ApiRequestConfig
  ): AxiosPromise<Attachment[]>;

  getAttachmentsWithSharedFilter(
    options: DonesafeIndexApiOptions<Attachment>,
    filterKey: keyof Attachment,
    filterValue: FilterValue | FilterValue[],
    config?: ApiRequestConfig
  ): AxiosPromise<Attachment[]>;
}

export const createAttachmentsApi = (api: AxiosInstance, path = '/api/attachments'): DonesafeAttachmentsApi => {
  const attachmentsApi: Omit<DonesafeAttachmentsApi, 'getAttachmentsWithSharedFilter'> = {
    getAttachments: (params, config) => api.get(path, { params, ...config }),
    createAttachment: (entity, params) => api.post(path, entity, { params }),
    deleteAttachment: (id) => api.delete(`${path}/${id}`),
    getAttachment: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...attachmentsApi,
    getAttachment: convertToJoinedRequest(attachmentsApi.getAttachment, attachmentsApi.getAttachments),
    getAttachmentsWithSharedFilter: sharedFilterListRequest(attachmentsApi.getAttachments),
  };
};
