import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { IndicatorSet } from '@app/models/indicator-set';
import type {
  DonesafeIndexApiOptions,
  UpdateIndexParams,
  DonesafeApiOptions,
  ApiRequestConfig,
  ApiRequestJoinConfig,
} from '@app/services/donesafe-api-utils';

export interface DonesafeIndicatorSetsApi {
  createIndicatorSet(indicatorSet: Partial<IndicatorSet>): AxiosPromise<IndicatorSet>;

  getIndicatorSet(
    id: IndicatorSet['id'],
    params?: DonesafeApiOptions<IndicatorSet>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<IndicatorSet>;
  getIndicatorSets(options?: DonesafeIndexApiOptions<IndicatorSet>, config?: ApiRequestConfig): AxiosPromise<IndicatorSet[]>;
  updateIndicatorSet(id: number, entity?: Partial<IndicatorSet>): AxiosPromise<IndicatorSet>;
  updateIndicatorSetIndexes(params: UpdateIndexParams): AxiosPromise<IndicatorSet[]>;
}

export const createIndicatorSetsApi = (api: AxiosInstance, path = '/api/indicator_sets'): DonesafeIndicatorSetsApi => {
  const indicatorSetsApi: DonesafeIndicatorSetsApi = {
    createIndicatorSet: (indicatorSet) => api.post(path, indicatorSet),
    getIndicatorSets: (params, config) => api.get(path, { params, ...config }),
    updateIndicatorSetIndexes: (params) => api.post(`${path}/update_indexes`, params),
    getIndicatorSet: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateIndicatorSet: (id, entity) => api.put(`${path}/${id}`, entity),
  };

  return {
    ...indicatorSetsApi,
    getIndicatorSet: convertToJoinedRequest<IndicatorSet>(indicatorSetsApi.getIndicatorSet, indicatorSetsApi.getIndicatorSets),
  };
};
