import type { AxiosInstance, AxiosPromise } from 'axios';
import { getCsrfConfig } from '@app/utils/get-csrf-config';
import type { ContactUserData } from '@app/models/contact-user-data';
import type { TenantUser } from '@app/models/tenant-user';

export interface DonesafeContactUsersApi {
  createContactUser(data: Partial<ContactUserData>): AxiosPromise<TenantUser>;
}

export const createContactUsersApi = (api: AxiosInstance, path = '/api/contact_users'): DonesafeContactUsersApi => {
  return {
    createContactUser: (data) => api.post(path, data, { ...getCsrfConfig() }),
  };
};
