import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Permission } from '@app/models/permission';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
} from '@app/services/donesafe-api-utils';

export interface DonesafePermissionsApi {
  getPermission(
    id: Permission['id'],
    options?: DonesafeApiOptions<Permission>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Permission>;
  getPermissions(options: DonesafeIndexApiOptions<Permission>, config?: ApiRequestConfig): AxiosPromise<Permission[]>;
  getPermissionsWithSharedFilters(
    options: DonesafeIndexApiOptions<Permission>,
    filterKey: keyof Permission,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<Permission[]>;
}

export const createPermissionsApi = (api: AxiosInstance, path = '/api/permissions'): DonesafePermissionsApi => {
  const permissionsApi: Omit<DonesafePermissionsApi, 'getPermissionsWithSharedFilters'> = {
    getPermissions: (params, config) => api.get(path, { params, ...config }),
    getPermission: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...permissionsApi,
    getPermission: convertToJoinedRequest(permissionsApi.getPermission, permissionsApi.getPermissions),
    getPermissionsWithSharedFilters: sharedFilterListRequest(permissionsApi.getPermissions),
  };
};
