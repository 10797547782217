import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ConfidentialityType } from '@app/models/confidentiality-type';
import type { ApiRequestConfig, DonesafeIndexApiOptions, DonesafeApiOptions, ApiRequestJoinConfig } from '@app/services/donesafe-api-utils';

export interface DonesafeConfidentialityTypesApi {
  getConfidentialityType(
    id: number | string,
    params?: DonesafeApiOptions<ConfidentialityType>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ConfidentialityType>;
  getConfidentialityTypes(
    options: DonesafeIndexApiOptions<ConfidentialityType>,
    config?: ApiRequestConfig
  ): AxiosPromise<ConfidentialityType[]>;
}

export const createConfidentialityTypesApi = (api: AxiosInstance, path = '/api/confidentiality_types'): DonesafeConfidentialityTypesApi => {
  const confidentialityTypesApi: DonesafeConfidentialityTypesApi = {
    getConfidentialityTypes: (params, config) => api.get(path, { params, ...config }),
    getConfidentialityType: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...confidentialityTypesApi,
    getConfidentialityType: convertToJoinedRequest<ConfidentialityType>(
      confidentialityTypesApi.getConfidentialityType,
      confidentialityTypesApi.getConfidentialityTypes
    ),
  };
};
