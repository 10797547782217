import type { AxiosInstance, AxiosPromise } from 'axios';
import type { LogicSet } from '@app/components/rule-builder/models/types';
import type { LogicSetFormula, LogicSetFormulaResult } from '@app/models/calculator';
import { getCsrfConfig } from '@app/utils/get-csrf-config';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions } from '@app/services/donesafe-api-utils';

interface EditLogicSet {
  logic_sets: Definitions;
}

interface Definitions {
  definitions: string;
}

export interface DonesafeLogicSetsApi {
  archiveLogicSet(id: number | string): AxiosPromise;
  createLogicSet(entity?: EditLogicSet, config?: ApiRequestConfig): AxiosPromise<LogicSet>;
  executeFormula(id: number | string, formula: LogicSetFormula, config?: ApiRequestConfig): AxiosPromise<LogicSetFormulaResult>;
  getLogicSet(
    id: number | string,
    params?: DonesafeApiOptions<LogicSet>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<LogicSet>;
  getLogicSets(config?: ApiRequestConfig): AxiosPromise<LogicSet[]>;
  getSubFormsUsingLogicSet(id: number | string, params?: unknown, config?: ApiRequestConfig & ApiRequestJoinConfig): AxiosPromise;
  importLogicSet: (data: FormData) => AxiosPromise;
  updateLogicSet(id: number | string, entity?: EditLogicSet): AxiosPromise<LogicSet>;
}

const legacyPath = '/admin/settings/logic_sets';

export const createLogicSetsApi = (api: AxiosInstance, path = '/api/logic_sets'): DonesafeLogicSetsApi => {
  // TODO: rewrite this to use grape api
  return {
    getLogicSets: (config) => api.get(legacyPath, { ...config, ...getCsrfConfig() }),
    createLogicSet: (entity, config) => api.post(legacyPath, entity, { ...config, ...getCsrfConfig() }),
    getLogicSet: (id, params, config) => api.get(`${legacyPath}/${id}`, { params, ...config, ...getCsrfConfig() }),
    updateLogicSet: (id, entity) => api.put(`${legacyPath}/${id}`, entity, { ...getCsrfConfig() }),
    importLogicSet: (data) => api.post(`${legacyPath}/import`, data, getCsrfConfig({ 'Content-Type': 'multipart/form-data' })),
    getSubFormsUsingLogicSet: (id, params, config) =>
      api.get(`${legacyPath}/${id}/sub_forms_using_logic_set`, { params, ...config, ...getCsrfConfig() }),
    archiveLogicSet: (id) => api.put(`${legacyPath}/${id}/archive`, null, { ...getCsrfConfig() }),
    executeFormula: (id, data, config) => api.post(`${path}/${id}/execute_formula`, data, { ...getCsrfConfig(), ...config }),
  };
};
