import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RoleProfile } from '@app/models/role-profile';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';

export interface DonesafeRoleProfilesApi {
  createRoleProfile(entity?: Partial<RoleProfile>): AxiosPromise<RoleProfile>;
  deleteRoleProfile(id: RoleProfile['id']): AxiosPromise<RoleProfile>;
  getRoleProfile(
    id: RoleProfile['id'],
    params?: DonesafeApiOptions<RoleProfile>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<RoleProfile>;
  getRoleProfiles(params?: DonesafeIndexApiOptions<RoleProfile>, config?: ApiRequestConfig): AxiosPromise<RoleProfile[]>;
  updateRoleProfile(id: RoleProfile['id'], entity: DonesafeApiOptions<RoleProfile>): AxiosPromise<RoleProfile>;
  updateRoleProfileIndexes(params: UpdateIndexParams): AxiosPromise<RoleProfile[]>;
}

export const createRoleProfilesApi = (api: AxiosInstance, path = '/api/role_profiles'): DonesafeRoleProfilesApi => {
  const roleProfilesApi: DonesafeRoleProfilesApi = {
    getRoleProfiles: (params, config) => api.get(path, { params, ...config }),
    getRoleProfile: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateRoleProfile: (id, entity) => api.put(`${path}/${id}`, entity),
    createRoleProfile: (entity) => api.post(`${path}`, entity),
    deleteRoleProfile: (id) => api.delete(`${path}/${id}`),
    updateRoleProfileIndexes: (params) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...roleProfilesApi,
    getRoleProfile: convertToJoinedRequest(roleProfilesApi.getRoleProfile, roleProfilesApi.getRoleProfiles),
  };
};
