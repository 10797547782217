import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Notification } from '@app/models/notification';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeNotificationsApi {
  addUsersToNotification: (id: Notification['id'], userIds: number[] | 'all') => AxiosPromise<Notification>;
  getNotification: (
    id: Notification['id'],
    params?: DonesafeApiOptions<Notification>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ) => AxiosPromise<Notification>;
  getNotifications: (params?: DonesafeIndexApiOptions<Notification>, config?: ApiRequestConfig) => AxiosPromise<Notification[]>;
  removeUsersFromNotification: (id: Notification['id'], userIds: number[] | 'all') => AxiosPromise<Notification>;
  updateNotification: (
    id: Notification['id'],
    params?: DonesafeApiOptions<Pick<Notification, 'active'>>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ) => AxiosPromise<Notification>;
}

export const createNotificationsApi = (api: AxiosInstance, path = '/api/notifications'): DonesafeNotificationsApi => {
  const notificationsApi: DonesafeNotificationsApi = {
    getNotifications: (params, config) => api.get(path, { params, ...config }),
    getNotification: (id) => api.get(`${path}/${id}`),
    updateNotification: (id, entity, params) => api.patch(`${path}/${id}`, entity, { params }),
    removeUsersFromNotification: (id, userIds) => api.post(`${path}/${id}/remove_users`, { user_ids: userIds }),
    addUsersToNotification: (id, userIds) => api.post(`${path}/${id}/add_users`, { user_ids: userIds }),
  };

  return {
    ...notificationsApi,
    getNotification: convertToJoinedRequest<Notification>(notificationsApi.getNotification, notificationsApi.getNotifications),
  };
};
