const $$patch = function <T>(target: T, patch: Partial<T>, { ifEmpty = false } = {}): void {
  (Object.keys(patch) as (keyof T)[]).forEach((key) => {
    if (ifEmpty && target && key in (target as object)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$set(target, key, patch[key]);
  });
};

export default $$patch;
