import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Attachment } from '@app/models/attachment';
import type { SuperReportTotal, SuperReportGraphData } from '@app/models/super-report';
import type { Dashboard } from '@app/models/dashboard';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafePublicDashboardsApi {
  getPublicAttachments(
    options: DonesafeIndexApiOptions<Attachment> & { schema: string },
    config?: ApiRequestConfig
  ): AxiosPromise<Attachment[]>;
  getPublicDashboard(
    id: number,
    schema: string,
    params?: DonesafeApiOptions<Dashboard>,
    config?: ApiRequestConfig
  ): AxiosPromise<Dashboard>;
  getPublicSuperReportGraphData(id: number, schema: string, config?: ApiRequestConfig): AxiosPromise<SuperReportGraphData>;
  getPublicSuperReportTotal(id: number, schema: string, config?: ApiRequestConfig): AxiosPromise<SuperReportTotal>;
}

export const createPublicDashboardsApi = (api: AxiosInstance, path = '/api/public_dashboards'): DonesafePublicDashboardsApi => {
  // TODO: refactor request urls
  return {
    getPublicSuperReportTotal: (id, schema, config) => api.get(`${path}/super_report_total?super_report_id=${id}&schema=${schema}`, config),
    getPublicSuperReportGraphData: (id, schema, config) =>
      api.get(`${path}/super_report_graph_data?super_report_id=${id}&schema=${schema}`, config),
    getPublicAttachments: (params, config) => api.get(`${path}/attachments`, { params, ...config }),
    getPublicDashboard: (id, schema, params, config) =>
      api.get(`${path}/dashboard?dashboard_id=${id}&schema=${schema}`, { params, ...config }),
  };
};
