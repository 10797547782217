import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { UserInvolvement } from '@app/models/user-involvement';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeUserInvolvementsApi {
  createUserInvolvement(data: Partial<UserInvolvement>): AxiosPromise<UserInvolvement>;
  deleteUserInvolvement(id: number): AxiosPromise<UserInvolvement>;
  getUserInvolvement(
    id: number | string,
    params?: DonesafeApiOptions<UserInvolvement>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<UserInvolvement>;
  getUserInvolvements(options: DonesafeIndexApiOptions<UserInvolvement>, config?: ApiRequestConfig): AxiosPromise<UserInvolvement[]>;
}

export const createUserInvolvementsApi = (api: AxiosInstance, path = '/api/user_involvements'): DonesafeUserInvolvementsApi => {
  const userInvolvementsApi: DonesafeUserInvolvementsApi = {
    getUserInvolvement: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getUserInvolvements: (params, config) => api.get(path, { params, ...config }),
    createUserInvolvement: (data) => api.post(path, data),
    deleteUserInvolvement: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...userInvolvementsApi,
    getUserInvolvement: convertToJoinedRequest(userInvolvementsApi.getUserInvolvement, userInvolvementsApi.getUserInvolvements),
  };
};
