import type { AxiosInstance, AxiosPromise } from 'axios';
import type { MenuCollection } from '@app/models/menu-collection';
import type { ApiRequestConfig, DonesafeApiOptions, UpdateIndexParams, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeMenuCollectionsApi {
  createMenuCollection(
    entity: Partial<MenuCollection>,
    params?: DonesafeApiOptions<MenuCollection>,
    config?: ApiRequestConfig
  ): AxiosPromise<MenuCollection>;
  getMenuCollection(
    id: MenuCollection['id'],
    params?: DonesafeApiOptions<MenuCollection>,
    config?: ApiRequestConfig
  ): AxiosPromise<MenuCollection>;

  getMenuCollections(params?: DonesafeIndexApiOptions<MenuCollection>, config?: ApiRequestConfig): AxiosPromise<MenuCollection[]>;

  updateMenuCollection(
    id: MenuCollection['id'],
    entity: Partial<MenuCollection>,
    params?: DonesafeApiOptions<MenuCollection>,
    config?: ApiRequestConfig
  ): AxiosPromise<MenuCollection>;

  updateMenuCollectionIndexes(
    data: UpdateIndexParams,
    options?: DonesafeIndexApiOptions<MenuCollection>,
    config?: ApiRequestConfig
  ): AxiosPromise<MenuCollection[]>;
}

export const createMenuCollectionsApi = (api: AxiosInstance, path = '/api/menu_collections'): DonesafeMenuCollectionsApi => {
  return {
    getMenuCollections: (params, config) => api.get(path, { params, ...config }),
    getMenuCollection: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createMenuCollection: (entity, params, config) => api.post(path, entity, { params, ...config }),
    updateMenuCollection: (id, entity, params, config) => api.put(`${path}/${id}`, entity, { params, ...config }),
    updateMenuCollectionIndexes: (data, options, config) => api.post(`${path}/update_indexes`, { ...data, ...options, ...config }),
  };
};
