import type { AxiosInstance, AxiosPromise } from 'axios';
import type { FormComponent } from '@app/models/form-component';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions, UpdateIndexParams } from '@app/services/donesafe-api-utils';

export interface DonesafeFormComponentsApi {
  createFormComponent(entity: Partial<FormComponent>): AxiosPromise<FormComponent[]>;

  deleteFormComponent(id: number): AxiosPromise<FormComponent>;
  getFormComponent(id: number | string, params?: DonesafeApiOptions<FormComponent>, config?: ApiRequestConfig): AxiosPromise<FormComponent>;
  getFormComponents(params: DonesafeIndexApiOptions<FormComponent>, config?: ApiRequestConfig): AxiosPromise<FormComponent[]>;
  getNextFormComponent(
    params: {
      current_order?: number;
      record_id: number;
      record_type?: 'ModuleRecord' | 'Hazard' | 'Incident';
      skip_completed?: boolean;
    } & DonesafeApiOptions<FormComponent>
  ): AxiosPromise<FormComponent | null>;
  updateFormComponent(id: number, entity: Partial<FormComponent>): AxiosPromise<FormComponent>;
  updateFormComponentOrder(
    data: UpdateIndexParams,
    options?: DonesafeIndexApiOptions<FormComponent>,
    config?: ApiRequestConfig
  ): AxiosPromise<FormComponent[]>;
}

export const createFormComponentsApi = (api: AxiosInstance, path = '/api/form_components'): DonesafeFormComponentsApi => {
  return {
    getFormComponent: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getFormComponents: (params, config) => api.get(path, { params, ...config }),
    getNextFormComponent: (params) => api.get(`${path}/next_component`, { params }),
    createFormComponent: (entity) => api.post(path, entity),
    updateFormComponent: (id, entity) => api.put(`${path}/${id}`, entity),
    updateFormComponentOrder: (data, options, config) => api.post(`${path}/update_order`, { ...data, ...options, ...config }),
    deleteFormComponent: (id) => api.delete(`${path}/${id}`),
  };
};
