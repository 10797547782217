import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { GenderIdentity } from '@app/models/gender-identity';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeGenderIdentitiesApi {
  createGenderIdentity(genderIdentity: Partial<GenderIdentity>): AxiosPromise<GenderIdentity>;

  getGenderIdentities(options: DonesafeIndexApiOptions<GenderIdentity>, config?: ApiRequestConfig): AxiosPromise<GenderIdentity[]>;

  getGenderIdentity(
    id: GenderIdentity['id'],
    params?: DonesafeApiOptions<GenderIdentity>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<GenderIdentity>;

  updateGenderIdentity(id: GenderIdentity['id'], genderIdentity?: Partial<GenderIdentity>): AxiosPromise<GenderIdentity>;
}

export const createGenderIdentitiesApi = (api: AxiosInstance, path = '/api/gender_identities'): DonesafeGenderIdentitiesApi => {
  const genderIdentitiesApi: DonesafeGenderIdentitiesApi = {
    getGenderIdentities: (params, config) => api.get(path, { params, ...config }),
    createGenderIdentity: (genderIdentity) => api.post(path, genderIdentity),
    updateGenderIdentity: (id, genderIdentity) => api.put(`${path}/${id}`, genderIdentity),
    getGenderIdentity: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...genderIdentitiesApi,
    getGenderIdentity: convertToJoinedRequest<GenderIdentity>(
      genderIdentitiesApi.getGenderIdentity,
      genderIdentitiesApi.getGenderIdentities
    ),
  };
};
