import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { FeatureSet } from '@app/models/feature-set';
import type { ModuleName, TranslatedType } from '@app/models/module-name';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  UpdateIndexParams,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
} from '@app/services/donesafe-api-utils';

export interface DonesafeModuleNamesApi {
  createModuleName(entity: Partial<ModuleName>, params?: DonesafeApiOptions<ModuleName>): AxiosPromise<ModuleName>;

  deleteModuleName(id: number): AxiosPromise<ModuleName>;
  getHierarchyTypes(params?: DonesafeIndexApiOptions<TranslatedType>, config?: ApiRequestConfig): AxiosPromise<TranslatedType[]>;

  getModuleName(
    id: number,
    params?: DonesafeApiOptions<ModuleName>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ModuleName>;
  getModuleNames(
    params?: DonesafeIndexApiOptions<ModuleName, { show_all?: boolean }>,
    config?: ApiRequestConfig
  ): AxiosPromise<ModuleName[]>;
  getSolutionTypes(params?: DonesafeIndexApiOptions<TranslatedType>, config?: ApiRequestConfig): AxiosPromise<TranslatedType[]>;

  renameModuleName(id: number, new_name: string | undefined): AxiosPromise<ModuleName>;

  updateModuleName(
    id: number,
    entity: Partial<ModuleName> & { expensable_expensing_table_ids?: string | string[]; feature_set_attributes?: Partial<FeatureSet> },
    params?: DonesafeApiOptions<ModuleName>
  ): AxiosPromise<ModuleName>;

  updateModuleNameIndexes(
    data: UpdateIndexParams,
    options?: DonesafeIndexApiOptions<ModuleName>,
    config?: ApiRequestConfig
  ): AxiosPromise<ModuleName[]>;
}

export const createModuleNamesApi = (api: AxiosInstance, path = '/api/module_names'): DonesafeModuleNamesApi => {
  const moduleNamesApi: DonesafeModuleNamesApi = {
    getModuleNames: (params, config) => api.get(path, { params, ...config }),
    getModuleName: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getHierarchyTypes: (params, config) => api.get('/api/hierarchy_types', { params, ...config }),
    getSolutionTypes: (params, config) => api.get('/api/solution_types', { params, ...config }),
    updateModuleName: (id, entity, params) => api.put(`${path}/${id}`, entity, { params }),
    createModuleName: (entity, params) => api.post(`${path}`, entity, { params }),
    renameModuleName: (id, new_name) => api.put(`${path}/${id}/rename`, { new_name: new_name }),
    deleteModuleName: (id) => api.delete(`${path}/${id}`),
    updateModuleNameIndexes: (data, options, config) => api.post(`${path}/update_indexes`, { ...data, ...options, ...config }),
  };

  return {
    ...moduleNamesApi,
    getModuleName: convertToJoinedRequest<ModuleName>(moduleNamesApi.getModuleName, moduleNamesApi.getModuleNames),
  };
};
