import { Courier } from '@hsi/courier';
import type { CourierConfiguration } from '@hsi/courier';

const _private = Symbol('$courier');

class CourierInstanceManager {
  [_private]: Nullable<Courier> = null;

  get instance() {
    if (!this[_private]) {
      throw new Error('Courier instance not initialized.');
    }
    return this[_private];
  }

  init(config: CourierConfiguration) {
    if (!this[_private]) {
      this[_private] = new Courier(window, config);
      this[_private].initialize();

      return this[_private];
    }
  }
}

const createLazyProxy = <T extends object>(targetFn: () => T): T =>
  new Proxy({} as T, { get: (_, prop, receiver) => Reflect.get(targetFn(), prop, receiver) });

const courierInstanceManager = new CourierInstanceManager();

const init = (config: CourierConfiguration) => courierInstanceManager.init(config);
const subjects = createLazyProxy<Courier['subjects']>(() => courierInstanceManager.instance.subjects);
const dispatchers = createLazyProxy<Courier['dispatchers']>(() => courierInstanceManager.instance.dispatchers);

export { init, subjects, dispatchers };
