import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { Document } from '@app/models/document';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeDocumentsApi {
  createDocument(document: Partial<Document & { file: string }>): AxiosPromise<Document>;
  deleteDocument(id: number | string): AxiosPromise<Document>;
  getDocument(
    id: number | string,
    params?: DonesafeApiOptions<Document>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Document>;
  getDocuments(options: DonesafeIndexApiOptions<Document>, config?: ApiRequestConfig): AxiosPromise<Document[]>;
  updateDocument(id: number | string, entity?: Partial<Document & { file: string }>): AxiosPromise<Document>;
}

export const createDocumentsApi = (api: AxiosInstance, path = '/api/documents'): DonesafeDocumentsApi => {
  const documentsApi: DonesafeDocumentsApi = {
    getDocuments: (params, config) => api.get(path, { params, ...config }),
    getDocument: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createDocument: (document) => api.post(path, document),
    updateDocument: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteDocument: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...documentsApi,
    getDocument: convertToJoinedRequest<Document>(documentsApi.getDocument, documentsApi.getDocuments),
  };
};
