import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { RecordCalculationResult } from '@app/models/record-calculation-result';
import type { AxiosPromise, AxiosInstance } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';

export interface DonesafeRecordCalculationResultsApi {
  getRecordCalculationResult(
    id: number | string,
    params?: DonesafeApiOptions<RecordCalculationResult>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<RecordCalculationResult>;
  getRecordCalculationResults(
    params: DonesafeIndexApiOptions<RecordCalculationResult>,
    config?: ApiRequestConfig
  ): AxiosPromise<RecordCalculationResult[]>;
}

export const createRecordCalculationResultsApi = (
  api: AxiosInstance,
  path = '/api/record_calculation_results'
): DonesafeRecordCalculationResultsApi => {
  const recordCalculationResultsApi: DonesafeRecordCalculationResultsApi = {
    getRecordCalculationResults: (params, config) => api.get(path, { params, ...config }),
    getRecordCalculationResult: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };

  return {
    ...recordCalculationResultsApi,
    getRecordCalculationResult: convertToJoinedRequest<RecordCalculationResult>(
      recordCalculationResultsApi.getRecordCalculationResult,
      recordCalculationResultsApi.getRecordCalculationResults
    ),
  };
};
