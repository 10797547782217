import type { SubFormData } from '@app/services/api/sub-form-completions-api';
import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { DefaultTemplate } from '@app/models/default-template';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';

export interface DonesafeDefaultTemplatesApi {
  createDefaultTemplate(
    data: Partial<DefaultTemplate & { responses: SubFormData }>,
    params?: DonesafeApiOptions<DefaultTemplate>
  ): AxiosPromise<DefaultTemplate>;
  getDefaultTemplate(
    id: DefaultTemplate['id'],
    params?: DonesafeApiOptions<DefaultTemplate>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<DefaultTemplate>;
  getDefaultTemplates(options: DonesafeIndexApiOptions<DefaultTemplate>, config?: ApiRequestConfig): AxiosPromise<DefaultTemplate[]>;
  getDefaultTemplatesWithSharedFilters(
    options: DonesafeIndexApiOptions<DefaultTemplate>,
    filterKey: keyof DefaultTemplate,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<DefaultTemplate[]>;
  updateDefaultTemplate(
    id: DefaultTemplate['id'],
    data: Partial<DefaultTemplate & { responses: SubFormData }>,
    params?: DonesafeApiOptions<DefaultTemplate>
  ): AxiosPromise<DefaultTemplate>;
  updateDefaultTemplateIndexes(params: UpdateIndexParams): AxiosPromise<DefaultTemplate[]>;
}

export const createDefaultTemplatesApi = (api: AxiosInstance, path = '/api/default_templates'): DonesafeDefaultTemplatesApi => {
  const defaultTemplatesApi: Omit<DonesafeDefaultTemplatesApi, 'getDefaultTemplatesWithSharedFilters'> = {
    getDefaultTemplates: (params, config) => api.get(path, { params, ...config }),
    getDefaultTemplate: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateDefaultTemplate: (id, data, params) => api.put(`${path}/${id}`, data, { params }),
    createDefaultTemplate: (data, params) => api.post(path, data, { params }),
    updateDefaultTemplateIndexes: (params) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...defaultTemplatesApi,
    getDefaultTemplate: convertToJoinedRequest<DefaultTemplate>(
      defaultTemplatesApi.getDefaultTemplate,
      defaultTemplatesApi.getDefaultTemplates
    ),
    getDefaultTemplatesWithSharedFilters: sharedFilterListRequest(defaultTemplatesApi.getDefaultTemplates),
  };
};
