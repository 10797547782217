import type { AxiosInstance, AxiosPromise } from 'axios';
import type { MobileAppLayout } from '@app/models/mobile-app/layout';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeMobileAppLayoutsApi {
  createMobileAppLayout(entity: Partial<MobileAppLayout>): AxiosPromise<MobileAppLayout>;

  deleteMobileAppLayout(id: number | string): AxiosPromise<MobileAppLayout>;

  getMobileAppLayout(
    id: number | string,
    params?: DonesafeApiOptions<MobileAppLayout>,
    config?: ApiRequestConfig
  ): AxiosPromise<MobileAppLayout>;

  getMobileAppLayouts(params?: DonesafeIndexApiOptions<MobileAppLayout>, config?: ApiRequestConfig): AxiosPromise<MobileAppLayout[]>;
  updateMobileAppLayout(id: number | string, entity: Partial<MobileAppLayout>): AxiosPromise<MobileAppLayout>;
}

export const createMobileAppLayoutsApi = (api: AxiosInstance, path = '/api/mobile_app_layouts'): DonesafeMobileAppLayoutsApi => {
  return {
    getMobileAppLayouts: (params, config) => api.get(path, { params, ...config }),
    getMobileAppLayout: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createMobileAppLayout: (entity) => api.post(path, entity),
    updateMobileAppLayout: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteMobileAppLayout: (id) => api.delete(`${path}/${id}`),
  };
};
