import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ScoreBandProfile } from '@app/models/score-band-profile';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeScoreBandProfilesApi {
  createScoreBandProfile(entity: Partial<ScoreBandProfile>): AxiosPromise<ScoreBandProfile>;

  deleteScoreBandProfile(id: number): AxiosPromise<ScoreBandProfile>;

  getScoreBandProfile(
    id: number,
    params?: DonesafeApiOptions<ScoreBandProfile>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ScoreBandProfile>;

  getScoreBandProfiles(
    options: DonesafeIndexApiOptions<ScoreBandProfile, { module_name?: { name?: string } }>,
    config?: ApiRequestConfig
  ): AxiosPromise<ScoreBandProfile[]>;

  updateScoreBandProfile(id: number, entity: Partial<ScoreBandProfile>): AxiosPromise<ScoreBandProfile>;
}

export const createScoreBandProfilesApi = (api: AxiosInstance, path = '/api/score_band_profiles'): DonesafeScoreBandProfilesApi => {
  const scoreBandProfilesApi: DonesafeScoreBandProfilesApi = {
    getScoreBandProfiles: (params, config) => api.get(path, { params, ...config }),
    getScoreBandProfile: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createScoreBandProfile: (entity) => api.post(path, entity),
    updateScoreBandProfile: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteScoreBandProfile: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...scoreBandProfilesApi,
    getScoreBandProfile: convertToJoinedRequest<ScoreBandProfile>(
      scoreBandProfilesApi.getScoreBandProfile,
      scoreBandProfilesApi.getScoreBandProfiles
    ),
  };
};
