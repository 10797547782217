import type { AxiosInstance, AxiosPromise } from 'axios';
import type { TableCalculation } from '@app/models/table-calculation';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions } from '@app/services/donesafe-api-utils';

export type UpdateRemotePaySystemData = Record<string, string | number | object | Array<string | number | object>>;

export interface DonesafeTableCalculationsApi {
  createTableCalculation(
    entity: Partial<TableCalculation> & { table_calculation_source_record_id_hash: Record<string, string> }
  ): AxiosPromise<TableCalculation>;
  getTableCalculation(
    id: number | string,
    params?: DonesafeApiOptions<TableCalculation>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<TableCalculation>;
  getTableCalculationData: (
    params: { data: Record<string, any>; sub_form_question_id: number | string },
    config?: ApiRequestConfig
  ) => AxiosPromise;
  getTableCalculationSourceData: (
    params: { sub_form_question_id: number | string; table_calculation_source_record_id_hash: Record<string, string> },
    config?: ApiRequestConfig
  ) => AxiosPromise;
  updateRemotePaySystem(data: { data: UpdateRemotePaySystemData; sub_form_question_id: number | string }): AxiosPromise;
  updateTableCalculation(
    id: number | string,
    entity: Partial<TableCalculation> & { table_calculation_source_record_id_hash: Record<string, string> }
  ): AxiosPromise<TableCalculation>;
}

export const createTableCalculationsApi = (api: AxiosInstance, path = '/api/table_calculations'): DonesafeTableCalculationsApi => {
  return {
    getTableCalculation: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createTableCalculation: (entity) => api.post(path, entity),
    updateTableCalculation: (id, entity) => api.put(`${path}/${id}`, entity),
    getTableCalculationData: (params, config) => api.post(`${path}/fetch_remote`, params, config),
    getTableCalculationSourceData: (params, config) => api.post(`${path}/fetch_transformed`, params, config),
    updateRemotePaySystem: (data) => api.post(`${path}/post_remote`, data),
  };
};
