import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { PartialNullBy } from '@app/utils/types/partial';
import type { AxiosInstance, AxiosPromise } from 'axios';
import { getCsrfConfig } from '@app/utils/get-csrf-config';
import type { Location } from '@app/models/location';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeLocationsApiExtraOptions {
  location_tag_counts?: {
    location_tag_id?: number | number[];
  };
  with_restrictions?: boolean;
}

export interface DonesafeLocationsApi {
  createLocation(location: Partial<Location>): AxiosPromise<Location>;

  deleteLocation(id: number): AxiosPromise<Location>;

  getLocation(id: number, params?: DonesafeApiOptions<Location>, config?: ApiRequestConfig & ApiRequestJoinConfig): AxiosPromise<Location>;

  getLocations(
    options: DonesafeIndexApiOptions<Location, DonesafeLocationsApiExtraOptions>,
    config?: ApiRequestConfig
  ): AxiosPromise<Location[]>;

  updateLocation(id: number, entity?: Partial<PartialNullBy<Location, 'location_id'>>): AxiosPromise<Location>;
}

export const createLocationsApi = (api: AxiosInstance, path = '/api/locations'): DonesafeLocationsApi => {
  const locationsApi: DonesafeLocationsApi = {
    getLocations: (params, config) => api.get(path, { params, ...config, ...getCsrfConfig() }),
    createLocation: (location) => api.post(path, location),
    updateLocation: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteLocation: (id) => api.delete(`${path}/${id}`),
    getLocation: (id, params, config) => api.get(`${path}/${id}`, { params, ...config, ...getCsrfConfig() }),
  };

  return {
    ...locationsApi,
    getLocation: convertToJoinedRequest<Location>(locationsApi.getLocation, locationsApi.getLocations),
  };
};
