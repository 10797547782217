const en_au = () => import(/* webpackChunkName: "en_au-locale" */ '../../config/locales/en_au.yml');
const cs = () => import(/* webpackChunkName: "cs-locale" */ '../../config/locales/cs.yml');
const de = () => import(/* webpackChunkName: "de-locale" */ '../../config/locales/de.yml');
const en_us = () => import(/* webpackChunkName: "en_us-locale" */ '../../config/locales/en_us.yml');
const en_gb = () => import(/* webpackChunkName: "en_gb-locale" */ '../../config/locales/en_gb.yml');
const es = () => import(/* webpackChunkName: "es-locale" */ '../../config/locales/es.yml');
const fr = () => import(/* webpackChunkName: "fr-locale" */ '../../config/locales/fr.yml');
const id_id = () => import(/* webpackChunkName: "id_id-locale" */ '../../config/locales/id_id.yml');
const it = () => import(/* webpackChunkName: "it-locale" */ '../../config/locales/it.yml');
const ja = () => import(/* webpackChunkName: "ja-locale" */ '../../config/locales/ja.yml');
const ko = () => import(/* webpackChunkName: "ko-locale" */ '../../config/locales/ko.yml');
const nl = () => import(/* webpackChunkName: "nl-locale" */ '../../config/locales/nl.yml');
const pl = () => import(/* webpackChunkName: "pl-locale" */ '../../config/locales/pl.yml');
const pt = () => import(/* webpackChunkName: "pt-locale" */ '../../config/locales/pt.yml');
const ru = () => import(/* webpackChunkName: "ru-locale" */ '../../config/locales/ru.yml');
const th = () => import(/* webpackChunkName: "th-locale" */ '../../config/locales/th.yml');
const vi = () => import(/* webpackChunkName: "vi-locale" */ '../../config/locales/vi.yml');
const zh_cn = () => import(/* webpackChunkName: "zh_cn-locale" */ '../../config/locales/zh_cn.yml');
const zh_hk = () => import(/* webpackChunkName: "zh_hk-locale" */ '../../config/locales/zh_hk.yml');
export const parsleyValidations = (code: string) => {
  let languageCode = code.split('_')[0];
  if (languageCode === 'zh') {
    languageCode = 'zh_cn';
  }
  import(`parsleyjs/dist/i18n/${languageCode}.js`);
};

export default {
  en_au,
  cs,
  de,
  en_us,
  en_gb,
  es,
  fr,
  id_id,
  it,
  ja,
  ko,
  nl,
  pl,
  pt,
  ru,
  th,
  vi,
  zh_cn,
  zh_hk,
};
