import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Dashboard } from '@app/models/dashboard';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeDashboardsApi {
  cloneDashboard(id: Dashboard['id'], entity?: Partial<Dashboard>): AxiosPromise<Dashboard>;

  createDashboard(entity: Partial<Dashboard>): AxiosPromise<Dashboard>;

  deleteDashboard(id: Dashboard['id']): AxiosPromise<Dashboard>;
  getDashboard(
    id: Dashboard['id'],
    options?: DonesafeApiOptions<Dashboard>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Dashboard>;

  getDashboards(options: DonesafeIndexApiOptions<Dashboard>, config?: ApiRequestConfig): AxiosPromise<Dashboard[]>;
  updateDashboard(id: Dashboard['id'], entity?: Partial<Dashboard>): AxiosPromise<Dashboard>;
}

export const createDashboardsApi = (api: AxiosInstance, path = '/api/dashboards'): DonesafeDashboardsApi => {
  const dashboardsApi: DonesafeDashboardsApi = {
    createDashboard: (entity) => api.post(path, entity),
    getDashboards: (params, config) => api.get(path, { params, ...config }),
    cloneDashboard: (id, entity) => api.post(`${path}/${id}/clone`, entity),
    getDashboard: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateDashboard: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteDashboard: (id) => api.delete(`${path}/${id}`),
  };

  return { ...dashboardsApi, getDashboard: convertToJoinedRequest(dashboardsApi.getDashboard, dashboardsApi.getDashboards) };
};
