// https://github.com/kuitos/axios-extensions/blob/master/src/cacheAdapterEnhancer.ts

import type { AxiosAdapter, AxiosPromise } from 'axios';
import buildSortedURL from 'axios-extensions/lib/utils/buildSortedURL';
import isCacheLike from 'axios-extensions/lib/utils/isCacheLike';
import LRUCache from 'lru-cache';
import { v4 as generateUUID } from 'uuid';
import { Base64 } from 'js-base64';

export const FIVE_MINUTES = 1000 * 60 * 5;
export const CAPACITY = 100;

export type Options = {
  cacheFlag?: string;
  defaultCache?: LRUCache<string, AxiosPromise>;
  enabledByDefault?: boolean;
};

export default function customCacheAdapterEnhancer(adapter: AxiosAdapter, options: Options = {}): AxiosAdapter {
  const {
    enabledByDefault = true,
    cacheFlag = 'cache',
    defaultCache = new LRUCache<string, AxiosPromise>({ ttl: FIVE_MINUTES, max: CAPACITY }),
  } = options;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (config: any) => {
    const { url, method, params, data, paramsSerializer, forceUpdate } = config;
    const useCache = config[cacheFlag] !== void 0 && config[cacheFlag] !== null ? config[cacheFlag] : enabledByDefault;

    const noCacheConfig = { ...config, params: { ...config.params, _no_cache: generateUUID() } };

    // ONLY CHECK METHOD IF enabledByDefault === true
    if ((!enabledByDefault || method === 'get') && useCache) {
      // if had to provide a specified cache, then use it instead
      const cache = isCacheLike(useCache) && 'delete' in useCache ? useCache : defaultCache;

      // build the index according to the url and params
      const index = buildSortedURL(url, { ...params, __body: Base64.encode(data) }, paramsSerializer);

      let responsePromise = cache.get(index);

      if (!responsePromise || forceUpdate) {
        responsePromise = (async () => {
          try {
            return await adapter(noCacheConfig);
          } catch (reason) {
            cache.delete(index);
            throw reason;
          }
        })();
        // put the promise for the non-transformed response into cache as a placeholder
        cache.set(index, responsePromise);
      }
      return responsePromise;
    }

    return adapter(noCacheConfig);
  };
}
