import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Account } from '@app/models/account';

export interface DonesafeAccountsApi {
  updateCurrentAccount(entity: Partial<Account>): AxiosPromise<Account>;
}

export const createAccountsApi = (api: AxiosInstance, path = '/api/accounts'): DonesafeAccountsApi => {
  return {
    updateCurrentAccount: (entity) => api.patch(`${path}/current`, entity),
  };
};
