import { defineStore } from 'pinia';
import moment from 'moment-timezone';
import type { Account } from '@app/models/account';
import { camelCase, uniq } from 'lodash';

export const useAccountStore = defineStore('account', {
  state: () => ({
    data: window?.DONESAFE?.account as Account,
  }),
  getters: {
    complexTimezonesEnabled: ({ data }) => !!data?.complex_timezones,
    limitPermissionsByExplicitLocations: ({ data }) => !!data?.limit_permissions_by_explicit_locations,
    limitPermissionsByLocation: ({ data }) => !!data?.limit_permissions_by_location,
    limitPermissionsByOrganization: ({ data }) => !!data?.limit_permissions_by_organization,
    allowImageEditing: ({ data }) => !!data?.image_editor_enabled,
    intercomEnabled: ({ data }) => !!data?.allow_help,
    moduleConfigForNonTechAdmins: ({ data }) => !!data?.module_config_for_non_tech_admins,
    expensingEnabled: ({ data }) => !!data?.expensing_enabled,
    allowMapSelection: ({ data }) => !!data?.allow_google_maps,
    canAddUsers: ({ data }) => !!data?.allowed_to_add_users,
    dateTimePickerFormatWithTimeZone: ({ data }) => data?.datetimepicker_datetime_format + ' Z',
    dateTimePickerDateFormat: ({ data }) => data?.datetimepicker_date_format,
    languages: ({ data }) => uniq(data?.all_languages || []),
    currencyCode: ({ data }) => camelCase(data?.currency_code),
    currencySymbol: ({ data }) => data?.currency_symbol,
    timezoneDiffersFromServer: ({ data }) => moment.tz.guess() !== data?.timezone,
  },
  actions: {
    dateFormat(date?: Maybe<string>, { serverTimezoneAware = false, dateOnly = false } = {}): Maybe<string> {
      const { data } = this;

      if (date) {
        if (dateOnly) {
          return moment(date, this.dateTimePickerFormatWithTimeZone).format(this.dateTimePickerDateFormat);
        }
        if (serverTimezoneAware && this.timezoneDiffersFromServer) {
          return moment(date).tz(`${data?.timezone}`).format(this.dateTimePickerFormatWithTimeZone);
        }

        return moment(date).format(data?.datetimepicker_date_format);
      }
    },
    dateTimeFormat(dateTime?: Nullable<string>): Maybe<string> {
      const { data } = this;
      return (dateTime && moment(dateTime).format(data?.datetimepicker_datetime_format)) || undefined;
    },
    dateTimeFormatWithTimeZone(dateTime?: Nullable<string>): Maybe<string> {
      return (dateTime && moment(dateTime).format(this.dateTimePickerFormatWithTimeZone)) || undefined;
    },
  },
});
