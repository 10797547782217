import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { CalculationRule } from '@app/models/calculation-rule';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeCalculationRulesApi {
  createCalculationRule(calculationRule: Partial<CalculationRule>): AxiosPromise<CalculationRule>;
  deleteCalculationRule(id: CalculationRule['id']): AxiosPromise<CalculationRule>;
  getCalculationRule(
    id: CalculationRule['id'],
    params?: DonesafeApiOptions<CalculationRule>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<CalculationRule>;
  getCalculationRules(params: DonesafeIndexApiOptions<CalculationRule>, config?: ApiRequestConfig): AxiosPromise<CalculationRule[]>;
  updateCalculationRule(id: CalculationRule['id'], calculation_rule: Partial<CalculationRule>): AxiosPromise<CalculationRule>;
}

export const createCalculationRulesApi = (api: AxiosInstance, path = '/api/calculation_rules'): DonesafeCalculationRulesApi => {
  const calculationRulesApi: DonesafeCalculationRulesApi = {
    getCalculationRules: (params, config) => api.get(path, { params, ...config }),
    getCalculationRule: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createCalculationRule: (calculationRule) => api.post(path, calculationRule),
    updateCalculationRule: (id, calculation_rule) => api.put(`${path}/${id}`, calculation_rule),
    deleteCalculationRule: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...calculationRulesApi,
    getCalculationRule: convertToJoinedRequest<CalculationRule>(
      calculationRulesApi.getCalculationRule,
      calculationRulesApi.getCalculationRules
    ),
  };
};
