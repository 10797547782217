import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ScormRegistration } from '@app/models/scorm-registration';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeScormRegistrationsApi {
  getScormRegistration(
    id: number | string,
    params?: DonesafeApiOptions<ScormRegistration>,
    config?: ApiRequestConfig
  ): AxiosPromise<ScormRegistration>;

  getScormRegistrations(options: DonesafeIndexApiOptions<ScormRegistration>, config?: ApiRequestConfig): AxiosPromise<ScormRegistration[]>;
}

export const createScormRegistrationsApi = (api: AxiosInstance, path = '/api/scorm_registrations'): DonesafeScormRegistrationsApi => {
  return {
    getScormRegistrations: (params, config) => api.get(path, { params, ...config }),
    getScormRegistration: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
