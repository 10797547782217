import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ExpensableModuleBudget } from '@app/models/expensable-module-budget';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeExpensableModuleBudgetsApi {
  getModuleBudgets(
    options?: DonesafeIndexApiOptions<ExpensableModuleBudget>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableModuleBudget[]>;
}

export const createExpensableModuleBudgetsApi = (api: AxiosInstance, path = '/api/module_budgets'): DonesafeExpensableModuleBudgetsApi => {
  return {
    getModuleBudgets: (params, config) => api.get(path, { params, ...config }),
  };
};
