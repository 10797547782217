import { sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RecordRelation } from '@app/models/record-relation';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions, FilterValue } from '@app/services/donesafe-api-utils';

export interface DonesafeRecordRelationsApi {
  createRecordRelation(entity: Partial<RecordRelation>): AxiosPromise<RecordRelation>;

  deleteRecordRelation(id: number): AxiosPromise<RecordRelation>;

  getRecordRelation(
    id: number | string,
    params: DonesafeApiOptions<RecordRelation>,
    config?: ApiRequestConfig
  ): AxiosPromise<RecordRelation>;

  getRecordRelations(options?: DonesafeIndexApiOptions<RecordRelation>, config?: ApiRequestConfig): AxiosPromise<RecordRelation[]>;
  getRecordRelationsWithSharedFilters(
    options: DonesafeIndexApiOptions<RecordRelation>,
    filterKey: keyof RecordRelation,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<RecordRelation[]>;
}

export const createRecordRelationsApi = (api: AxiosInstance, path = '/api/record_relations'): DonesafeRecordRelationsApi => {
  const apiObject: Omit<DonesafeRecordRelationsApi, 'getRecordRelationsWithSharedFilters'> = {
    getRecordRelations: (params, config) => api.get(path, { params, ...config }),
    getRecordRelation: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createRecordRelation: (entity) => api.post(path, entity),
    deleteRecordRelation: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...apiObject,
    getRecordRelationsWithSharedFilters: sharedFilterListRequest(apiObject.getRecordRelations),
  };
};
