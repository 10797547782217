import type { AxiosInstance, AxiosPromise } from 'axios';
import type { AutomationToggle } from '@app/models/automation-toggle';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeAutomationTogglesApi {
  getAutomationToggles(options: DonesafeIndexApiOptions<AutomationToggle>, config?: ApiRequestConfig): AxiosPromise<AutomationToggle[]>;
}

export const createAutomationTogglesApi = (api: AxiosInstance, path = '/api/automation_toggles'): DonesafeAutomationTogglesApi => {
  return {
    getAutomationToggles: (params, config) => api.get(path, { params, ...config }),
  };
};
