import type { default as _Vue } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueScrollTo = require('vue-scrollto');

const withScrollTo = (Vue: typeof _Vue): typeof _Vue => {
  Vue.use(VueScrollTo, {
    duration: 500,
    offset: -80, // cater for Donesafe header
  });

  return Vue;
};

declare module 'vue/types/vue' {
  interface Vue {
    readonly $scrollTo: typeof VueScrollTo;
  }
}

export default withScrollTo;
