import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { SubFormSection } from '@app/models/sub-form-section';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';
import type { PartialNullBy } from '@app/utils/types/partial';

export interface DonesafeSubFormSectionsApi {
  createSubFormSection(section: Partial<SubFormSection>, params?: DonesafeApiOptions<SubFormSection>): AxiosPromise<SubFormSection>;

  deleteSubFormSection(id: number): AxiosPromise<SubFormSection>;

  getSubFormSection(
    id: number | string,
    params?: DonesafeApiOptions<SubFormSection>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubFormSection>;

  getSubFormSections(params: DonesafeIndexApiOptions<SubFormSection>, config?: ApiRequestConfig): AxiosPromise<SubFormSection[]>;

  updateSubFormSection(
    id: number,
    section: Partial<PartialNullBy<SubFormSection, 'sub_form_section_group_id'>>,
    params?: DonesafeApiOptions<SubFormSection>
  ): AxiosPromise<SubFormSection>;

  updateSubFormSectionFromQuestionSet(
    id: number,
    questionSetId: number,
    params?: DonesafeApiOptions<SubFormSection>
  ): AxiosPromise<SubFormSection>;

  updateSubFormSectionIndexes(params: UpdateIndexParams): AxiosPromise<void>;
}

export const createSubFormSectionsApi = (api: AxiosInstance, path = '/api/sub_form_sections'): DonesafeSubFormSectionsApi => {
  const subFormSectionsApi: DonesafeSubFormSectionsApi = {
    getSubFormSections: (params, config) => api.get(path, { params, ...config }),
    getSubFormSection: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createSubFormSection: (section, params) => api.post(path, section, { params }),
    deleteSubFormSection: (id) => api.delete(`${path}/${id}`),
    updateSubFormSection: (id, section, params) => api.put(`${path}/${id}`, section, { params }),
    updateSubFormSectionFromQuestionSet: (id, questionSetId, params) =>
      api.post(`${path}/${id}/apply_question_set`, { sub_form_question_set_id: questionSetId }, { params }),
    updateSubFormSectionIndexes: (params: UpdateIndexParams) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...subFormSectionsApi,
    getSubFormSection: convertToJoinedRequest<SubFormSection>(subFormSectionsApi.getSubFormSection, subFormSectionsApi.getSubFormSections),
  };
};
