import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { WorkplaceIndustry } from '@app/models/workplace-industry';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeWorkplaceIndustriesApi {
  createWorkplaceIndustry(workplaceIndustry: Partial<WorkplaceIndustry>): AxiosPromise<WorkplaceIndustry>;
  getWorkplaceIndustries(options: DonesafeIndexApiOptions<WorkplaceIndustry>, config?: ApiRequestConfig): AxiosPromise<WorkplaceIndustry[]>;
  getWorkplaceIndustry(
    id: number | string,
    params?: DonesafeApiOptions<WorkplaceIndustry>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<WorkplaceIndustry>;
  updateWorkplaceIndustry(id: number | string, workplaceIndustry?: Partial<WorkplaceIndustry>): AxiosPromise<WorkplaceIndustry>;
}

export const createWorkplaceIndustriesApi = (api: AxiosInstance, path = '/api/workplace_industries'): DonesafeWorkplaceIndustriesApi => {
  const workplaceIndustriesApi: DonesafeWorkplaceIndustriesApi = {
    getWorkplaceIndustries: (params, config) => api.get(path, { params, ...config }),
    getWorkplaceIndustry: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createWorkplaceIndustry: (workplaceIndustry) => api.post(path, workplaceIndustry),
    updateWorkplaceIndustry: (id, workplaceIndustry) => api.put(`${path}/${id}`, workplaceIndustry),
  };

  return {
    ...workplaceIndustriesApi,
    getWorkplaceIndustry: convertToJoinedRequest<WorkplaceIndustry>(
      workplaceIndustriesApi.getWorkplaceIndustry,
      workplaceIndustriesApi.getWorkplaceIndustries
    ),
  };
};
