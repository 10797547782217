import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RecordParams } from './rule-sets-api';
import type { Event } from '@app/models/event';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { PerOptionResponse } from './utils-api';

export interface RunEventParams {
  per_id?: number;
  per_type?: 'ModuleRecord' | 'SubFormCompletion' | 'TenantUser';
  triggered_id?: number;
  triggered_type?: string;
}

type WithSource = {
  source: 'automation_definition' | 'scheduled_events';
  source_id: number;
};

type EventsColletionSize = {
  record_id: number;
  record_type: string;
};

export type EventsPerResults = {
  collection_size: number;
  collection_size_text: string;
  injectable_arguments: PerOptionResponse;
};

export interface DonesafeEventsApi {
  getAllEventTypeFilters(config?: ApiRequestConfig): AxiosPromise<Record<string, string>>;
  getCollectionSize(id: number, params: RecordParams, config?: ApiRequestConfig): AxiosPromise<{ count: number }>;
  getEvent(id: number, params?: DonesafeApiOptions<Event>, config?: ApiRequestConfig): AxiosPromise<Event>;
  getEvents(
    options: DonesafeIndexApiOptions<Event & { with_event_occurrences: boolean }>,
    config?: ApiRequestConfig
  ): AxiosPromise<Event[]>;
  getEventsPerResults(options: EventsColletionSize & WithSource, config?: ApiRequestConfig): AxiosPromise<Record<number, EventsPerResults>>;
  runEventAction(id: number, params: RunEventParams): AxiosPromise<Event>;
}

export const createEventsApi = (api: AxiosInstance, path = '/api/events'): DonesafeEventsApi => {
  return {
    getCollectionSize: (id, params, config) => api.get(`${path}/${id}/collection_size`, { params, ...config }),
    runEventAction: (id, params) => api.post(`${path}/${id}/action`, params),
    getEvent: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getEvents: (params, config) => api.get(path, { params, ...config }),
    getEventsPerResults: (params, config) => api.get(`${path}/per_results`, { params, ...config }),
    getAllEventTypeFilters: (config) => api.get(`${path}/filters/event_type`, { ...config }),
  };
};
