import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ObserveTemplate } from '@app/models/observe_template';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeObserveTemplatesApi {
  getObserveTemplates(options: DonesafeIndexApiOptions<ObserveTemplate>, config?: ApiRequestConfig): AxiosPromise<ObserveTemplate[]>;
}

export const createObserveTemplatesApi = (api: AxiosInstance, path = '/api/observe_templates'): DonesafeObserveTemplatesApi => {
  return {
    getObserveTemplates: (params, config) => api.get(path, { params, ...config }),
  };
};
