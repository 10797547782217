import type { default as _Vue } from 'vue';
import DOMPurify from 'dompurify';

export const SANITIZE_ALLOWED_TAGS = window.DONESAFE.SANITIZE_ALLOWED_TAGS;
export const SANITIZE_ALLOWED_TAGS_ADMIN = window.DONESAFE.SANITIZE_ALLOWED_TAGS_ADMIN;

const withSanitize = (Vue: typeof _Vue): typeof _Vue => {
  DOMPurify.setConfig({
    ADD_ATTR: ['target'], // Allow elements with the target attribute
    ALLOWED_TAGS: SANITIZE_ALLOWED_TAGS,
  });
  Vue.prototype.$sanitize = DOMPurify.sanitize;

  const adminDomPurify = DOMPurify();
  adminDomPurify.setConfig({
    ADD_ATTR: ['target'],
    ALLOWED_TAGS: SANITIZE_ALLOWED_TAGS_ADMIN,
  });
  Vue.prototype.$adminSanitize = adminDomPurify.sanitize;

  return Vue;
};

declare module 'vue/types/vue' {
  interface Vue {
    readonly $adminSanitize: (source: string | Node, config?: DOMPurify.Config) => string;
    readonly $sanitize: (source: string | Node, config?: DOMPurify.Config) => string;
  }
}

export default withSanitize;
