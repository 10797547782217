import type { ExpensableTransactionLedger } from '@app/models/expensable-transaction-ledger';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeExpensableTransactionLedgersApi {
  getExpensableTransactionLedgers(
    options: DonesafeIndexApiOptions<ExpensableTransactionLedger>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableTransactionLedger[]>;
}

export const createExpensableTransactionLedgersApi = (
  api: AxiosInstance,
  path = '/api/transaction_ledgers'
): DonesafeExpensableTransactionLedgersApi => {
  return {
    getExpensableTransactionLedgers: (params, config) => api.get(path, { params, ...config }),
  };
};
