import type { AxiosInstance, AxiosPromise } from 'axios';
import type { RestrictionDefault } from '@app/models/restriction-default';
import { getCsrfConfig } from '@app/utils/get-csrf-config';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeRestrictionDefaultsApi {
  createRestrictionDefault(entity?: Partial<RestrictionDefault>): AxiosPromise<RestrictionDefault>;
  getRestrictionDefaults(
    params: DonesafeIndexApiOptions<RestrictionDefault>,
    config?: ApiRequestConfig
  ): AxiosPromise<RestrictionDefault[]>;
  updateRestrictionDefault(id: number | string, entity?: Partial<RestrictionDefault>): AxiosPromise<RestrictionDefault>;
}

export const createRestrictionDefaultsApi = (api: AxiosInstance, path = '/api/restriction_defaults'): DonesafeRestrictionDefaultsApi => {
  return {
    getRestrictionDefaults: (params, config) => api.get(path, { params, ...config, ...getCsrfConfig() }),
    updateRestrictionDefault: (id, entity) => api.put(`${path}/${id}`, entity),
    createRestrictionDefault: (entity) => api.post(`${path}`, entity),
  };
};
