import type { OperatorBlobType } from '@app/models/mixins';

const MIN_POSITION = 0;
const MAX_POSITION = 2000;
const OPERATOR_HEIGHT = 50;
const OPERATOR_WIDTH = 150;
const OPERATOR_SHIFT = 50;

export const normalizedWorkflowOperators = (operators: OperatorBlobType) => {
  const normalizedOperators: OperatorBlobType = { ...operators };
  Object.keys(normalizedOperators).forEach((id) => {
    if (normalizedOperators[id].left < MIN_POSITION) normalizedOperators[id].left = MIN_POSITION + OPERATOR_SHIFT;
    if (normalizedOperators[id].top < MIN_POSITION) normalizedOperators[id].top = MIN_POSITION + OPERATOR_SHIFT;
    if (normalizedOperators[id].left > MAX_POSITION - 100) normalizedOperators[id].left = MAX_POSITION - (OPERATOR_WIDTH + OPERATOR_SHIFT);
    if (normalizedOperators[id].top > MAX_POSITION) normalizedOperators[id].top = MAX_POSITION - (OPERATOR_HEIGHT + OPERATOR_SHIFT);
  });
  return normalizedOperators;
};
