import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Relation } from '@app/models/relation';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeRelationsApi {
  createRelation(data: Partial<Relation>): AxiosPromise<Relation>;

  deleteRelation(id: number): AxiosPromise<Relation>;

  getRelations(
    params: DonesafeIndexApiOptions<Relation, { record_id?: number; record_type?: string }>,
    config?: ApiRequestConfig
  ): AxiosPromise<Relation[]>;
}

export const createRelationsApi = (api: AxiosInstance, path = '/api/relations'): DonesafeRelationsApi => {
  return {
    getRelations: (params, config) => api.get(path, { params, ...config }),
    createRelation: (data) => api.post(path, data),
    deleteRelation: (id) => api.delete(`${path}/${id}`),
  };
};
