import type { AxiosInstance, AxiosPromise } from 'axios';
import type { PaperTrailAttribute, PaperTrailItemType, PaperTrailVersion } from '@app/models/paper-trail-version';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafePaperTrailsApi {
  getPaperTrailAttributes(
    params: DonesafeIndexApiOptions<PaperTrailVersion> & {
      item_id: string | number | string[] | number[];
      item_type: string;
      with_children?: boolean;
    },
    config?: ApiRequestConfig
  ): AxiosPromise<{ attributes: PaperTrailAttribute[]; item_types: PaperTrailItemType[]; whodunnits: string[] }>;
  getPaperTrails(
    params: DonesafeIndexApiOptions<PaperTrailVersion & { '@object_changes': string[] }> & {
      item_id: string | number | string[] | number[];
      item_type: string;
      with_children?: boolean;
    },
    config?: ApiRequestConfig
  ): AxiosPromise<PaperTrailVersion[]>;
}

export const createPaperTrailsApi = (api: AxiosInstance, path = '/api/paper_trails'): DonesafePaperTrailsApi => {
  return {
    getPaperTrails: (params, config) => api.get(path, { params, ...config }),
    getPaperTrailAttributes: (params, config) => api.get(`${path}/attributes`, { params, ...config }),
  };
};
