import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { PlaceholderProfile } from '@app/models/placeholder-profile';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafePlaceholderProfilesApi {
  createPlaceholderProfile(entity: Partial<PlaceholderProfile>): AxiosPromise<PlaceholderProfile>;
  deletePlaceholderProfile(id: PlaceholderProfile['id']): AxiosPromise<PlaceholderProfile>;
  getPlaceholderProfile(
    id: PlaceholderProfile['id'],
    params?: DonesafeApiOptions<PlaceholderProfile>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<PlaceholderProfile>;
  getPlaceholderProfiles(
    options: DonesafeIndexApiOptions<PlaceholderProfile>,
    config?: ApiRequestConfig
  ): AxiosPromise<PlaceholderProfile[]>;
  updatePlaceholderProfile(id: PlaceholderProfile['id'], entity?: Partial<PlaceholderProfile>): AxiosPromise<PlaceholderProfile>;
}

export const createPlaceholderProfilesApi = (api: AxiosInstance, path = '/api/placeholder_profiles'): DonesafePlaceholderProfilesApi => {
  const placeholderProfilesApi: DonesafePlaceholderProfilesApi = {
    getPlaceholderProfiles: (params, config) => api.get(path, { params, ...config }),
    updatePlaceholderProfile: (id, entity) => api.put(`${path}/${id}`, entity),
    getPlaceholderProfile: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createPlaceholderProfile: (entity) => api.post(path, entity),
    deletePlaceholderProfile: (id) => api.delete(`${path}/${id}`),
  };
  return {
    ...placeholderProfilesApi,
    getPlaceholderProfile: convertToJoinedRequest(
      placeholderProfilesApi.getPlaceholderProfile,
      placeholderProfilesApi.getPlaceholderProfiles
    ),
  };
};
