import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AutomatedEvent } from '@app/models/automated-event';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeAutomatedEventsApi {
  getAutomatedEvent(
    id: number | string,
    params?: DonesafeApiOptions<AutomatedEvent>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<AutomatedEvent>;

  getAutomatedEvents(options: DonesafeIndexApiOptions<AutomatedEvent>, config?: ApiRequestConfig): AxiosPromise<AutomatedEvent[]>;
}

export const createAutomatedEventsApi = (api: AxiosInstance, path = '/api/automated_events'): DonesafeAutomatedEventsApi => {
  const automatedEventApi: DonesafeAutomatedEventsApi = {
    getAutomatedEvent: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getAutomatedEvents: (params, config) => api.get(path, { params, ...config }),
  };

  return {
    ...automatedEventApi,
    getAutomatedEvent: convertToJoinedRequest<AutomatedEvent>(automatedEventApi.getAutomatedEvent, automatedEventApi.getAutomatedEvents),
  };
};
