import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { Relationship } from '@app/models/relationship';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  FilterValue,
} from '@app/services/donesafe-api-utils';

export interface DonesafeRelationshipsApi {
  createRelationship(entity: Partial<Relationship>): AxiosPromise<Relationship>;

  deleteRelationship(id: number | string): AxiosPromise<Relationship>;

  getRelationship(
    id: number | string,
    params?: DonesafeApiOptions<Relationship>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Relationship>;

  getRelationships(options: DonesafeIndexApiOptions<Relationship>, config?: ApiRequestConfig): AxiosPromise<Relationship[]>;

  getRelationshipsWithSharedFilter(
    options: DonesafeIndexApiOptions<Relationship>,
    filterKey: keyof Relationship,
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<Relationship[]>;

  updateRelationship(id: number | string, entity: Partial<Relationship>): AxiosPromise<Relationship>;
}

export const createRelationshipsApi = (api: AxiosInstance, path = '/api/relationships'): DonesafeRelationshipsApi => {
  const relationshipsApi: Omit<DonesafeRelationshipsApi, 'getRelationshipsWithSharedFilter'> = {
    createRelationship: (entity) => api.post(path, entity),
    updateRelationship: (id, entity) => api.put(`${path}/${id}`, entity),
    getRelationships: (params, config) => api.get(path, { params, ...config }),
    getRelationship: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    deleteRelationship: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...relationshipsApi,
    getRelationship: convertToJoinedRequest<Relationship>(relationshipsApi.getRelationship, relationshipsApi.getRelationships),
    getRelationshipsWithSharedFilter: sharedFilterListRequest(relationshipsApi.getRelationships),
  };
};
