import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ScormCloudConnection } from '@app/models/scorm-cloud-connection';

export interface DonesafeScormCloudApi {
  performScormCloudRequest(entity: Partial<ScormCloudConnection>): AxiosPromise<ScormCloudConnection>;
}

export const createScormCloudApi = (api: AxiosInstance, path = '/api/scorm_cloud'): DonesafeScormCloudApi => {
  return {
    performScormCloudRequest: (entity) => api.post(path, entity),
  };
};
