import type { CancelTokenSource } from 'axios';
import http from 'axios';
import decamelizeKeys from 'decamelize-keys';
import { range } from 'lodash';
import fileChecksumPromise from '@app/services/file-checksum-promise';

interface UploaderConfig {
  headers?: Record<string, string>;
  onProgress?: (event: ProgressEvent) => void;
  partSize?: number;
  url: string;
}

interface Part {
  etag: string;
  partNumber: number;
}

export default class MultipartUploader {
  file: File;
  config: UploaderConfig;
  cancelTokenSource: CancelTokenSource | null = null;
  uploadId?: string;
  blobId?: number;

  constructor(file: File, config: UploaderConfig) {
    this.config = config;
    this.file = file;
  }

  cancel(): void {
    this.cancelTokenSource && this.cancelTokenSource.cancel();

    const { uploadId, blobId } = this;

    if (!uploadId) return;

    http.post(`${this.config.url}/abort_multipart_upload`, decamelizeKeys({ uploadId, blobId }), {
      ...this.requestConfig(),
      cancelToken: undefined,
    });
  }

  async upload(): Promise<string> {
    const {
      file,
      config: { partSize = 4 * 1024 * 1024 * 1024 },
    } = this;

    this.cancelTokenSource = http.CancelToken.source();

    const { uploadId, blobId } = await this.initiateUpload(file);

    this.uploadId = uploadId;
    this.blobId = blobId;

    const parts = await Promise.all(
      this.splitIntoParts(file, partSize).map(async ({ partNumber, file }) => ({
        partNumber,
        etag: await this.uploadPart(uploadId, blobId, partNumber, file),
      }))
    );

    return this.completeUpload(uploadId, blobId, parts);
  }

  async initiateUpload(file: File): Promise<{ blobId: number; uploadId: string }> {
    const {
      data: { blob_id, upload_id },
    } = await http.post(
      `${this.config.url}/create_multipart_upload`,
      {
        blob: {
          filename: this.file.name,
          content_type: this.file.type,
          byte_size: this.file.size,
          checksum: await fileChecksumPromise(file),
        },
      },
      this.requestConfig()
    );

    return { uploadId: upload_id, blobId: blob_id };
  }

  async uploadPart(uploadId: string, blobId: number, partNumber: number, file: Blob): Promise<string> {
    const { data: presignedUrl } = await http.post(
      `${this.config.url}/upload_part`,
      decamelizeKeys({ uploadId, blobId, partNumber }),
      this.requestConfig()
    );

    const response = await http.put(presignedUrl, file, {
      headers: { 'Cache-Control': 'no-cache' },
      cancelToken: this.cancelTokenSource?.token,
    });

    return response.headers.etag;
  }

  async completeUpload(uploadId: string, blobId: number, parts: Part[]): Promise<string> {
    const { data: signedId } = await http.post(
      `${this.config.url}/complete_multipart_upload`,
      decamelizeKeys({ uploadId, blobId, parts: parts.map((p) => decamelizeKeys(p)) }),
      this.requestConfig()
    );

    return signedId;
  }

  requestConfig(): object {
    return {
      baseURL: window.DONESAFE.baseApiUrl,
      headers: { ...this.config.headers },
      cancelToken: this.cancelTokenSource?.token,
    };
  }

  splitIntoParts(file: File, partSize: number): { file: Blob; partNumber: number }[] {
    return range(0, Math.ceil(file.size / partSize)).map((partNumber) => ({
      partNumber: partNumber + 1,
      file: file.slice(partNumber * partSize, Math.min(file.size, (partNumber + 1) * partSize)),
    }));
  }
}
