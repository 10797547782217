import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Timezone } from '@app/models/timezone';
import type { ToocsSection } from '@app/models/toocs/toocs-section';
import type { ApiRequestConfig } from '@app/services/donesafe-api-utils';

export interface DonesafeBaseApi {
  getTimezones: (params?: object, config?: ApiRequestConfig) => AxiosPromise<Timezone[]>;
  getToocs: (config?: ApiRequestConfig) => AxiosPromise<ToocsSection[]>;
  setToken: (token: string) => void;
  uploadFile: (data: FormData) => AxiosPromise<{ link: string }>;
}

export const createBaseApi = (api: AxiosInstance): DonesafeBaseApi => {
  return {
    uploadFile: (data) => api.post('/api/utils/file_upload', data),
    getTimezones: (params, config) => api.get('/api/dictionaries/timezones', { params, ...config }),
    getToocs: (config) => api.get('/api/dictionaries/toocs', config),
    setToken: (token): void => {
      api.defaults.headers.common['Access-Token'] = token;
    },
  };
};
