import type { ExpenseCategoryOverwrite } from '@app/models/expense-category-overwrite';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeExpenseCategoryOverwritesApi {
  createExpenseCategoryOverwrite(entity: Partial<ExpenseCategoryOverwrite>): AxiosPromise<ExpenseCategoryOverwrite[]>;
  getExpenseCategoryOverwrites(
    options: DonesafeIndexApiOptions<ExpenseCategoryOverwrite>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpenseCategoryOverwrite[]>;
  updateExpenseCategoryOverwrite(id: number | string, entity: Partial<ExpenseCategoryOverwrite>): AxiosPromise<ExpenseCategoryOverwrite>;
}

export const createExpenseCategoryOverwritesApi = (
  api: AxiosInstance,
  path = '/api/expense_category_overwrites'
): DonesafeExpenseCategoryOverwritesApi => {
  return {
    getExpenseCategoryOverwrites: (params, config) => api.get(path, { params, ...config }),
    createExpenseCategoryOverwrite: (entity) => api.post(path, entity),
    updateExpenseCategoryOverwrite: (id, entity) => api.put(`${path}/${id}`, entity),
  };
};
