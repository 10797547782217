import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { SubFormList, SubFormListResponseCounterData } from '@app/models/sub-form-list';
import type {
  ApiRequestJoinConfig,
  UpdateIndexParams,
  ApiRequestConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
} from '@app/services/donesafe-api-utils';
import type { PartialNullBy } from '@app/utils/types/partial';

export interface DonesafeSubFormListsApi {
  createSubFormList(
    entity: Partial<SubFormList>,
    params?: DonesafeApiOptions<SubFormList>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormList>;
  deleteSubFormList(id: SubFormList['id'], params?: DonesafeApiOptions<SubFormList>, config?: ApiRequestConfig): AxiosPromise<SubFormList>;
  getAccessibleSubFormLists(
    params: DonesafeIndexApiOptions<SubFormList, { record_id: number; record_type: string }>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormList[]>;
  getResponseCounterData(
    id: SubFormList['id'],
    params: { record_id: number },
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormListResponseCounterData>;
  getSubFormList(
    id: SubFormList['id'],
    params?: DonesafeApiOptions<SubFormList>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<SubFormList>;
  getSubFormLists(options?: DonesafeIndexApiOptions<SubFormList>, config?: ApiRequestConfig): AxiosPromise<SubFormList[]>;
  manageChecklist(
    id: SubFormList['id'],
    entity: Partial<SubFormList>,
    params?: DonesafeApiOptions<SubFormList>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormList>;
  updateSubFormList(
    id: SubFormList['id'],
    entity: Partial<
      PartialNullBy<SubFormList, 'add_background_color' | 'add_icon' | 'approval_button_icon' | 'approval_button_background_color'>
    >,
    params?: DonesafeApiOptions<SubFormList>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormList>;
  updateSubFormListIndexes(
    data: UpdateIndexParams,
    options?: DonesafeIndexApiOptions<SubFormList>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormList[]>;
  updateSubFormListRestrictions(
    id: SubFormList['id'],
    params?: DonesafeApiOptions<SubFormList>,
    config?: ApiRequestConfig
  ): AxiosPromise<SubFormList[]>;
}

export const createSubFormListsApi = (api: AxiosInstance, path = '/api/sub_form_lists'): DonesafeSubFormListsApi => {
  const subFormListsApi: DonesafeSubFormListsApi = {
    createSubFormList: (entity, params, config) => api.post(path, entity, { params, ...config }),
    deleteSubFormList: (id, params, config) => api.delete(`${path}/${id}`, { params, ...config }),
    getAccessibleSubFormLists: (params, config) => api.get(`${path}/accessible`, { params, ...config }),
    getResponseCounterData: (id, params, config) => api.get(`${path}/${id}/response_counter_data`, { params, ...config }),
    getSubFormList: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getSubFormLists: (params, config) => api.get(path, { params, ...config }),
    manageChecklist: (id, entity, params, config) => api.put(`${path}/${id}/manage_checklist`, entity, { params, ...config }),
    updateSubFormList: (id, entity, params, config) => api.put(`${path}/${id}`, entity, { params, ...config }),
    updateSubFormListIndexes: (data, options, config) => api.post(`${path}/update_indexes`, { ...data, ...options, ...config }),
    updateSubFormListRestrictions: (id, params, config) => api.put(`${path}/${id}/restrictions`, { ...params, ...config }),
  };

  return {
    ...subFormListsApi,
    getSubFormList: convertToJoinedRequest(subFormListsApi.getSubFormList, subFormListsApi.getSubFormLists),
  };
};
