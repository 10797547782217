import type { AxiosInstance, AxiosPromise } from 'axios';
import type { MobileAppLayoutEntity } from '@app/models/mobile-app/entity';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeMobileAppLayoutEntitiesApi {
  createMobileAppLayoutEntity(entity: Partial<MobileAppLayoutEntity>): AxiosPromise<MobileAppLayoutEntity>;

  deleteMobileAppLayoutEntity(id: number | string): AxiosPromise<MobileAppLayoutEntity>;

  getMobileAppLayoutEntities(
    params?: DonesafeIndexApiOptions<MobileAppLayoutEntity>,
    config?: ApiRequestConfig
  ): AxiosPromise<MobileAppLayoutEntity[]>;

  getMobileAppLayoutEntity(
    id: number | string,
    params?: DonesafeApiOptions<MobileAppLayoutEntity>,
    config?: ApiRequestConfig
  ): AxiosPromise<MobileAppLayoutEntity>;

  updateMobileAppLayoutEntity(id: number | string, entity: Partial<MobileAppLayoutEntity>): AxiosPromise<MobileAppLayoutEntity>;
}

export const createMobileAppLayoutEntitiesApi = (
  api: AxiosInstance,
  path = '/api/mobile_app_layout_entities'
): DonesafeMobileAppLayoutEntitiesApi => {
  return {
    getMobileAppLayoutEntities: (params, config) => api.get(path, { params, ...config }),
    getMobileAppLayoutEntity: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createMobileAppLayoutEntity: (entity) => api.post(path, entity),
    updateMobileAppLayoutEntity: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteMobileAppLayoutEntity: (id) => api.delete(`${path}/${id}`),
  };
};
