import type { AxiosInstance, AxiosPromise } from 'axios';
import type { HourType } from '@app/models/hour-type';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions, ApiRequestJoinConfig } from '@app/services/donesafe-api-utils';

export interface DonesafeHourTypesApi {
  createHourType(hourType: Partial<HourType>): AxiosPromise<HourType>;

  deleteHourType(id: number): AxiosPromise<HourType>;

  getHourType(id: number, params?: DonesafeApiOptions<HourType>, config?: ApiRequestConfig & ApiRequestJoinConfig): AxiosPromise<HourType>;

  getHourTypes(options: DonesafeIndexApiOptions<HourType>, config?: object): AxiosPromise<HourType[]>;

  updateHourType(id: number, entity: Partial<HourType>): AxiosPromise<HourType>;
}

export const createHourTypesApi = (api: AxiosInstance, path = '/api/hour_types'): DonesafeHourTypesApi => {
  return {
    createHourType: (hourType) => api.post(path, hourType),
    deleteHourType: (id) => api.delete(`${path}/${id}`),
    getHourType: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getHourTypes: (params, config) => api.get(path, { params, ...config }),
    updateHourType: (id, entity) => api.put(`${path}/${id}`, entity),
  };
};
