import type { AxiosInstance, AxiosPromise } from 'axios';
import type { MobileAppRegistration } from '@app/models/mobile-app/registration';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export type WSAction = 'change_mode' | 'unregister' | 'fetch' | 'print';

export interface DonesafeMobileAppRegistrationsApi {
  createMobileAppRegistration(entity: Partial<MobileAppRegistration>): AxiosPromise<MobileAppRegistration>;

  deleteMobileAppRegistration(id: number | string): AxiosPromise<MobileAppRegistration>;
  getMobileAppRegistration(
    id: number | string,
    params?: DonesafeApiOptions<MobileAppRegistration>,
    config?: ApiRequestConfig
  ): AxiosPromise<MobileAppRegistration>;

  getMobileAppRegistrations(
    params?: DonesafeIndexApiOptions<MobileAppRegistration>,
    config?: ApiRequestConfig
  ): AxiosPromise<MobileAppRegistration[]>;

  notifyMobileAppRegistration(
    id: number | string,
    data: { action: WSAction; data: object }
  ): AxiosPromise<{ action: string; data: object }>;

  updateMobileAppRegistration(id: number | string, entity: Partial<MobileAppRegistration>): AxiosPromise<MobileAppRegistration>;
}

export const createMobileAppRegistrationsApi = (
  api: AxiosInstance,
  path = '/api/mobile_app_registrations'
): DonesafeMobileAppRegistrationsApi => {
  return {
    getMobileAppRegistrations: (params, config) => api.get(path, { params, ...config }),
    getMobileAppRegistration: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    createMobileAppRegistration: (entity) => api.post(path, entity),
    updateMobileAppRegistration: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteMobileAppRegistration: (id) => api.delete(`${path}/${id}`),
    notifyMobileAppRegistration: (id, data) => api.post(`${path}/${id}/notify`, data),
  };
};
