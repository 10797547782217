import { dispatchers } from '@app/services/courier';
import type { SnackbarMessageType } from '@hsi/courier/interfaces/snackbar.interface';
import { type ToastParams } from './types/toast-params';

const legacyToaster = (params: string | ToastParams): void =>
  $.toast({ position: 'top-right', ...(typeof params === 'string' ? { text: params } : params) });

export const toaster = (params: string | ToastParams): void => {
  if (!window.DONESAFE?.account?.one_hsi_ui) {
    return legacyToaster(params);
  }

  dispatchers.snackbar.dispatch({
    message: typeof params === 'string' ? params : params.text,
    type: typeof params === 'object' && params.icon ? (params.icon as SnackbarMessageType) : 'info',
  });
};
