import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Profile } from '@app/models/profile';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { PartialNullBy } from '@app/utils/types/partial';

export interface DonesafeProfilesApi {
  getProfile(
    id: number | string,
    params?: DonesafeApiOptions<Profile>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Profile>;

  getProfiles(options: DonesafeIndexApiOptions<Profile>, config?: ApiRequestConfig): AxiosPromise<Profile[]>;

  updateProfile(id: number | string, entity?: Partial<PartialNullBy<Profile, 'country_code' | 'state_code'>>): AxiosPromise<Profile>;
}

export const createProfilesApi = (api: AxiosInstance, path = '/api/profiles'): DonesafeProfilesApi => {
  return {
    getProfiles: (params, config) => api.get(path, { params, ...config }),
    updateProfile: (id, entity) => api.put(`${path}/${id}`, entity),
    getProfile: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
