
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { convertToReadableFileSize } from '@app/utils/convert-to-readable-file-size';

  @Component({})
  export default class FileUploaderItem extends Vue {
    @Prop(Object) readonly fileData!: {
      file: File;
      progress?: ProgressEvent;
    };

    @Prop(Boolean) readonly small!: boolean;

    get _file(): File {
      return this.fileData.file;
    }

    get fileName(): string {
      return this._file.name;
    }

    get fileSize(): string {
      return convertToReadableFileSize(this._file.size, true);
    }

    get progress(): number {
      const progress = this.fileData.progress;
      return (progress && progress.loaded && progress.total && Math.floor((progress.loaded / progress.total) * 100)) || 0;
    }

    // get speed() {
    //   return this.fileData.bitrate && convertToReadableFileSize((this.fileData.bitrate * 0.125), true) || 0
    // }
  }
