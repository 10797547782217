import type { OperatorBlobType } from '@app/models/mixins';
import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { Workflow } from '@app/models/workflow';
import type { WorkflowLink } from '@app/models/workflow-link';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

interface ActiveLinksOptions {
  record_id?: number;
  record_type?: string;
}

export interface DonesafeWorkflowsApi {
  createWorkflow(data: Partial<Workflow>): AxiosPromise<Workflow>;
  deleteWorkflow(id: Workflow['id']): AxiosPromise<Workflow>;
  getWorkflow(
    id: Workflow['id'],
    params?: DonesafeApiOptions<Workflow>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Workflow>;
  getWorkflowActiveLinks(
    id: Workflow['id'],
    params: DonesafeIndexApiOptions<WorkflowLink, ActiveLinksOptions>,
    config?: ApiRequestConfig
  ): AxiosPromise<WorkflowLink[]>;
  getWorkflows(options: DonesafeIndexApiOptions<Workflow>, config?: ApiRequestConfig): AxiosPromise<Workflow[]>;
  updateWorkflow(id: Workflow['id'], entity: Partial<Workflow>): AxiosPromise<Workflow>;
  updateWorkflowBlob(data: {
    entity_id: number;
    entity_type: 'ModuleName' | 'SubFormList';
    operator_blob: OperatorBlobType;
  }): AxiosPromise<Workflow>;
}

export const createWorkflowsApi = (api: AxiosInstance, path = '/api/workflows'): DonesafeWorkflowsApi => {
  const workflowsApi: DonesafeWorkflowsApi = {
    getWorkflows: (params, config) => api.get(path, { params, ...config }),
    getWorkflow: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    getWorkflowActiveLinks: (id, params, config) => api.get(`${path}/${id}/active_links`, { params, ...config }),
    createWorkflow: (data) => api.post(path, data),
    updateWorkflowBlob: (data) => api.post(`${path}/update_blob`, data),
    deleteWorkflow: (id): AxiosPromise<Workflow> => api.delete(`${path}/${id}`),
    updateWorkflow: (id, entity) => api.put(`${path}/${id}`, entity),
  };

  return {
    ...workflowsApi,
    getWorkflow: convertToJoinedRequest<Workflow>(workflowsApi.getWorkflow, workflowsApi.getWorkflows),
  };
};
