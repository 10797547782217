import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Scheme } from '@app/models/scheme';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeSchemesApi {
  getScheme(id: number | string, params?: DonesafeApiOptions<Scheme>, config?: ApiRequestConfig): AxiosPromise<Scheme>;

  getSchemes(options: DonesafeIndexApiOptions<Scheme>, config?: ApiRequestConfig): AxiosPromise<Scheme[]>;
}

export const createSchemesApi = (api: AxiosInstance, path = '/api/scheme_records'): DonesafeSchemesApi => {
  return {
    getSchemes: (params, config) => api.get(path, { params, ...config }),
    getScheme: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
  };
};
