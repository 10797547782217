import { dispatchers } from '@app/services/courier';
import 'imports-loader?define=>false&module=>undefined!jquery-placeholder';

$(function () {
  $('input[placeholder], textarea[placeholder]').placeholder();
  $('.modal').on('shown.bs.modal', function () {
    $(this).find('[data-parsley-validate]').parsley();
    $(this).ajaxComplete(function () {
      $(this).find('input, textarea').placeholder();
    });
  });

  $('body').on('shown.bs.modal', '.modal', function () {
    !!window?.DONESAFE?.account?.one_hsi_ui && dispatchers.modal.dispatch(true);
  });

  // modal has finished being hidden from the user
  $('body').on('hidden.bs.modal', '.modal', function () {
    // remove all existing markup which is added via ajax
    $(this).removeData('bs.modal');
  });
});
