import type { DonesafeApiOptions, ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ExpensableRecordBudget } from '@app/models/expensable-record-budget';

export interface DonesafeBudgetsApi {
  createExpensableRecordBudget(
    params: Pick<ExpensableRecordBudget, 'budgetable_id' | 'budgetable_type' | 'expensable_expensing_table_id'>
  ): AxiosPromise<ExpensableRecordBudget>;

  getExpensableRecordBudget(
    id: ExpensableRecordBudget['id'],
    params?: DonesafeApiOptions<ExpensableRecordBudget>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableRecordBudget>;
  getExpensableRecordBudgets(
    params: DonesafeIndexApiOptions<ExpensableRecordBudget>,
    config?: ApiRequestConfig
  ): AxiosPromise<ExpensableRecordBudget[]>;
  updateExpensableRecordBudget(
    id: ExpensableRecordBudget['id'],
    entity?: Pick<ExpensableRecordBudget, 'ready'>,
    params?: DonesafeApiOptions<ExpensableRecordBudget>
  ): AxiosPromise<ExpensableRecordBudget>;
}

export const createExpensableRecordBudgetsApi = (api: AxiosInstance, path = '/api/record_budgets'): DonesafeBudgetsApi => {
  return {
    getExpensableRecordBudgets: (params, config) => api.get(path, { params, ...config }),
    getExpensableRecordBudget: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateExpensableRecordBudget: (id, entity, params) => api.put(`${path}/${id}`, entity, { params }),
    createExpensableRecordBudget: (entity) => api.post(path, entity),
  };
};
