import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type {
  AuditReportRecordType,
  AuditReportTemplate,
  BulkGenerateParams,
  GenerateJobResult,
  GenerateParams,
} from '@app/models/audit-report-template';
import type {
  ApiRequestJoinConfig,
  UpdateIndexParams,
  ApiRequestConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
} from '@app/services/donesafe-api-utils';

export interface DonesafeAuditReportTemplatesApi {
  bulkGenerateAuditReport(id: number, params: BulkGenerateParams): AxiosPromise<GenerateJobResult>;

  createAuditReportTemplate(entity: Partial<AuditReportTemplate & { file: string }>): AxiosPromise<AuditReportTemplate>;

  deleteAuditReportTemplate(id: number): AxiosPromise<AuditReportTemplate>;
  generateAuditReport(id: number, params: GenerateParams): AxiosPromise<GenerateJobResult>;

  getAuditReportTemplate(
    id: number,
    params?: DonesafeApiOptions<AuditReportTemplate & { record_id?: number; record_type?: AuditReportRecordType }>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<AuditReportTemplate>;

  getAuditReportTemplates(
    options: DonesafeIndexApiOptions<AuditReportTemplate & { record_id?: number; record_type?: AuditReportRecordType }>,
    config?: ApiRequestConfig
  ): AxiosPromise<AuditReportTemplate[]>;

  updateAuditReportTemplate(id: number, entity: Partial<AuditReportTemplate & { file: string }>): AxiosPromise<AuditReportTemplate>;
  updateAuditReportTemplateIndexes(
    params: UpdateIndexParams & DonesafeIndexApiOptions<AuditReportTemplate>
  ): AxiosPromise<AuditReportTemplate[]>;
}

export const createAuditReportTemplatesApi = (
  api: AxiosInstance,
  path = '/api/audit_report_templates'
): DonesafeAuditReportTemplatesApi => {
  const auditReportTemplatesApi: DonesafeAuditReportTemplatesApi = {
    createAuditReportTemplate: (entity) => api.post(path, entity),
    bulkGenerateAuditReport: (id, params) => api.post(`${path}/${id}/bulk_generate`, params),
    getAuditReportTemplates: (params, config) => api.get(path, { params, ...config }),
    getAuditReportTemplate: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateAuditReportTemplate: (id, entity) => api.put(`${path}/${id}`, entity),
    deleteAuditReportTemplate: (id) => api.delete(`${path}/${id}`),
    updateAuditReportTemplateIndexes: (params) => api.post(`${path}/update_indexes`, params),
    generateAuditReport: (id, params) => api.post(`${path}/${id}/generate`, params),
  };

  return {
    ...auditReportTemplatesApi,
    getAuditReportTemplate: convertToJoinedRequest<AuditReportTemplate>(
      auditReportTemplatesApi.getAuditReportTemplate,
      auditReportTemplatesApi.getAuditReportTemplates
    ),
  };
};
