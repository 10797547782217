import 'imports-loader?define=>false&exports=>false!eonasdan-bootstrap-datetimepicker';
import 'imports-loader?define=>false&exports=>false!bootstrap-datepicker';

$.extend(true, $.fn.datepicker.defaults, {
  autoclose: true,
  icons: {
    time: 'fa fa-clock-o',
    date: 'fa fa-calendar',
    up: 'fa fa-chevron-up',
    down: 'fa fa-chevron-down',
    previous: 'fa fa-chevron-left',
    next: 'fa fa-chevron-right',
    today: 'fa fa-calendar',
    clear: 'fa fa-trash',
  },
  templates: {
    leftArrow: '<i class="fa fa-chevron-left"></i>',
    rightArrow: '<i class="fa fa-chevron-right"></i>',
  },
  todayHighlight: true,
  clearBtn: true,
});

$.extend(true, $.fn.datetimepicker.defaults, {
  showClear: true,
  showClose: true,
  toolbarPlacement: 'bottom',
  sideBySide: true,
  ignoreReadonly: true,
  icons: {
    time: 'fa fa-clock-o',
    date: 'fa fa-calendar',
    up: 'fa fa-chevron-up',
    down: 'fa fa-chevron-down',
    previous: 'fa fa-chevron-left',
    next: 'fa fa-chevron-right',
    today: 'fa fa-calendar',
    clear: 'fa fa-trash',
    close: 'fa fa-check',
  },
});
