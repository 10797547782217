import withGoogleMaps from '@app/vue/with-google-maps';
import type { Component, default as _Vue } from 'vue';
import type Vue from 'vue';
import VueFroala from 'vue-froala-wysiwyg';
import I18n from '@app/i18n';
import VueClipboard from 'vue-clipboard2';
import VueRouter from 'vue-router';
import withScrollTo from '@app/vue/with-scroll-to';
import withSanitize from '@app/vue/with-sanitize';
import VueCompositionAPI from '@vue/composition-api';
import withVeeValidate from '@app/vue/with-vee-validate';
import withRollbar from '@app/vue/with-rollbar';
import PortalVue from 'portal-vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { API } from '@app/services/api-factory';

import $$patch from './patch';
import { $$warn, $$debug, $$info, ConsoleLogLevel } from './console';
import $link from './link';

declare module 'vue/types/vue' {
  interface Vue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly $$debug: (...args: any[]) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly $$info: (...args: any[]) => void;
    readonly $$patch: typeof $$patch;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly $$warn: (...args: any[]) => void;
    readonly $consoleLogLevel: ConsoleLogLevel;
    readonly $fontAwesomeIcons: string[];
    readonly $isDevelopment: boolean;
    readonly $link: typeof $link;
    readonly $materialIcons: string[];
    readonly $t: typeof I18n.t;
  }
}

const setup =
  ({ router, components }: { components?: Record<string, Component>; router: VueRouter }) =>
  (Vue: typeof _Vue) => {
    Vue.use({
      install(vue: typeof _Vue): void {
        vue.prototype.$isDevelopment = window.DONESAFE.isDevelopment || localStorage.getItem('ds-dev') === 'true';
        const consoleLevel = localStorage.getItem('ds-console-level');
        vue.prototype.$consoleLogLevel =
          (consoleLevel && parseInt(consoleLevel, 10)) || (window.DONESAFE.isDevelopment ? ConsoleLogLevel.warn : ConsoleLogLevel.disabled);
        vue.prototype.$t = (key: string, args?: object): string => I18n.t(key, args);
        vue.prototype.$$patch = $$patch;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        vue.prototype.$$warn = (...data: any[]): void => $$warn(vue.prototype, ...data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        vue.prototype.$$debug = (...data: any[]): void => $$debug(vue.prototype, ...data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        vue.prototype.$$info = (...data: any[]): void => $$info(vue.prototype, ...data);
        vue.prototype.$link = $link;
        vue.prototype.$api = API;
        vue.prototype.$materialIcons = window.DONESAFE.material_icons;
        vue.prototype.$fontAwesomeIcons = window.DONESAFE.font_awesome_icons;
      },
    });

    Vue.use(PiniaVuePlugin);
    Vue.use(VueRouter);
    Vue.use(VueFroala);
    Vue.use(VueCompositionAPI);
    withScrollTo(Vue);
    withGoogleMaps(Vue);
    withSanitize(Vue);
    withVeeValidate(Vue);
    withRollbar(Vue);
    Vue.use(PortalVue);
    Vue.use(VueClipboard);

    const pinia = createPinia();

    const initVuejs = (): void => {
      return [].slice.call(document.querySelectorAll('[data-behaviour="vue"]')).forEach((node: Element & { __vue__: Vue }) => {
        !node.__vue__ && new Vue({ router, components, pinia }).$mount(node);
      });
    };

    window.DONESAFE.initVuejs = initVuejs;

    initVuejs();
  };

export default setup;
