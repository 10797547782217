import type { AxiosInstance, AxiosPromise } from 'axios';
import type { Restriction } from '@app/models/restriction';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { ApiRequestConfig, ApiRequestJoinConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeRestrictionsApi {
  createRestriction(entity?: Partial<Restriction>): AxiosPromise<Restriction>;
  deleteRestriction(id: number | string): AxiosPromise<Restriction>;
  getRestriction(
    id: number | string,
    params?: DonesafeApiOptions<Restriction>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<Restriction>;
  getRestrictions(params: DonesafeIndexApiOptions<Restriction>, config?: ApiRequestConfig): AxiosPromise<Restriction[]>;
  updateRestriction(id: number | string, entity?: Partial<Restriction>): AxiosPromise<Restriction>;
}

export const createRestrictionsApi = (api: AxiosInstance, path = '/api/restrictions'): DonesafeRestrictionsApi => {
  const restrictionsApi: DonesafeRestrictionsApi = {
    getRestrictions: (params, config) => api.get(path, { params, ...config }),
    getRestriction: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    updateRestriction: (id, entity) => api.put(`${path}/${id}`, entity),
    createRestriction: (entity) => api.post(`${path}`, entity),
    deleteRestriction: (id) => api.delete(`${path}/${id}`),
  };

  return {
    ...restrictionsApi,
    getRestriction: convertToJoinedRequest<Restriction>(restrictionsApi.getRestriction, restrictionsApi.getRestrictions),
  };
};
