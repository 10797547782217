import type { AxiosRequestConfig, CancelTokenSource } from 'axios';
import axios from 'axios';
import fileChecksumPromise from '@app/services/file-checksum-promise';

interface UploaderConfig {
  headers?: Record<string, string>;
  onProgress?: (event: ProgressEvent) => void;
  url: string;
}

export class Uploader {
  file: File;
  config: UploaderConfig;
  cancelTokenSource: CancelTokenSource | null = null;

  constructor(file: File, config: UploaderConfig) {
    this.config = config;
    this.file = file;
  }

  cancel(): void {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
  }

  requestConfig(): AxiosRequestConfig {
    return {
      baseURL: window.DONESAFE.baseApiUrl,
      headers: { ...this.config.headers },
      cancelToken: this.cancelTokenSource?.token,
    };
  }

  async upload(): Promise<string> {
    const checksum = await fileChecksumPromise(this.file);

    this.cancelTokenSource = axios.CancelToken.source();

    const {
      data: {
        signed_id: signedId,
        direct_upload: { url: uploadUrl, headers: uploadHeaders },
      },
    } = await axios.post(
      this.config.url,
      {
        blob: {
          filename: this.file.name,
          content_type: this.file.type,
          byte_size: this.file.size,
          checksum: checksum,
        },
      },
      this.requestConfig()
    );

    this.cancelTokenSource = axios.CancelToken.source();

    await axios.put(uploadUrl, this.file, {
      headers: { ...uploadHeaders, 'Cache-Control': 'no-cache' },
      cancelToken: this.cancelTokenSource.token,
      onUploadProgress: (progressEvent) => this.config.onProgress?.(progressEvent),
    });

    return signedId;
  }
}
