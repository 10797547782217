import { escape } from 'lodash';

export const select2IconResponseTemplate = (result: { [key: string]: string } = {}, options = {}): JQuery => {
  const defaults = {
    iconType: 'material',
    iconAttribute: 'text',
    primaryAttribute: 'text',
  };
  const actualOptions = { ...defaults, ...options };
  const primaryText = escape(result[actualOptions.primaryAttribute]);
  if (result.id) {
    const icon = escape(result[actualOptions.iconAttribute]);
    if (actualOptions.iconType === 'fa') {
      return $(`<span><i class="fa fa-${icon}"/> ${primaryText}</span>`);
    } else {
      return $(`<span><i class="material-icons">${icon}</i> ${primaryText}</span>`);
    }
  }
  return $(`<span>${primaryText}</span>`);
};
