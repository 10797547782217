import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ApiRequestConfig, DonesafeIndexApiOptions, UpdateIndexParams } from '@app/services/donesafe-api-utils';
import type DashboardPaneGroup from '@app/models/dashboard-pane-group';

interface DashboardPaneGroupExtraParameters {
  accessible?: boolean;
  with_accessible_panes?: boolean;
  with_active_panes?: boolean;
  with_config?: boolean;
}

export interface DonesafeDashboardPaneGroupsApi {
  getDashboardPaneGroups(
    options: DonesafeIndexApiOptions<DashboardPaneGroup, DashboardPaneGroupExtraParameters>,
    config?: ApiRequestConfig
  ): AxiosPromise<DashboardPaneGroup[]>;
  updateDashboardPaneGroupsIndexes(params: UpdateIndexParams): AxiosPromise<void>;
  updateDashboardPaneGroupsUserIndexes(params: UpdateIndexParams): AxiosPromise<void>;
}

export const createDashboardPaneGroupsApi = (api: AxiosInstance, path = '/api/dashboard_pane_groups'): DonesafeDashboardPaneGroupsApi => {
  return {
    getDashboardPaneGroups: (params, config) => api.get(path, { params, ...config }),
    updateDashboardPaneGroupsIndexes: (params: UpdateIndexParams) => api.post(`${path}/update_indexes`, params),
    updateDashboardPaneGroupsUserIndexes: (params: UpdateIndexParams) => api.post(`${path}/update_user_indexes`, params),
  };
};
