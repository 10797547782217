import type { AxiosInstance, AxiosPromise } from 'axios';
import type { UserLocationTag } from '@app/models/user-location-tag';
import type { ApiRequestConfig, DonesafeApiOptions, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';

export interface DonesafeUserLocationTagsApi {
  createUserLocationTag(
    entity: DonesafeApiOptions<UserLocationTag> & Pick<UserLocationTag, 'user_id' | 'location_tag_id' | 'location_id'>
  ): AxiosPromise<UserLocationTag>;
  deleteUserLocationTag(id: number | string, params?: DonesafeApiOptions<UserLocationTag>): AxiosPromise<UserLocationTag>;
  getUserLocationTag(
    id: number | string,
    params?: DonesafeApiOptions<UserLocationTag>,
    config?: ApiRequestConfig
  ): AxiosPromise<UserLocationTag>;
  getUserLocationTags(options: DonesafeIndexApiOptions<UserLocationTag>, config?: ApiRequestConfig): AxiosPromise<UserLocationTag[]>;
}

export const createUserLocationTagsApi = (api: AxiosInstance, path = '/api/user_location_tags'): DonesafeUserLocationTagsApi => {
  return {
    getUserLocationTags: (params, config) => api.get(path, { params, ...config }),
    createUserLocationTag: (entity) => api.post(path, entity),
    getUserLocationTag: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    deleteUserLocationTag: (id, params) => api.delete(`${path}/${id}`, { params }),
  };
};
