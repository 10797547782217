import type { AxiosInstance, AxiosPromise } from 'axios';
import type { ApiRequestConfig, DonesafeIndexApiOptions } from '@app/services/donesafe-api-utils';
import type { Shift } from '@app/models/shift';

export interface DonesafeShiftsApi {
  getShifts(options: DonesafeIndexApiOptions<Shift>, config?: ApiRequestConfig): AxiosPromise<Shift[]>;
}

export const createShiftsApi = (api: AxiosInstance, path = '/api/shifts'): DonesafeShiftsApi => {
  return {
    getShifts: (params, config) => api.get(path, { params, ...config }),
  };
};
