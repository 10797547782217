import { convertToJoinedRequest, sharedFilterListRequest } from '@app/services/donesafe-api-utils';
import type { AxiosInstance, AxiosPromise } from 'axios';
import { getCsrfConfig } from '@app/utils/get-csrf-config';
import type { ModuleRecord } from '@app/models/module-record';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  FilterValue,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
} from '@app/services/donesafe-api-utils';

import type { RelatedItem, SubFormData } from './sub-form-completions-api';

export interface ModuleRecordExtraParameters {
  attachments?: Record<string, RelatedItem[]>;
  related_records?: Record<string, RelatedItem[]>;
  responses?: SubFormData;
  responses_by_code?: SubFormData;
}

export interface DonesafeModuleRecordExtraFilters {
  date?: string;
  date_end?: string;
  date_start?: string;
  follow?: boolean;
  scope?: 'all' | 'team' | 'wider_team' | 'mine';
  sub_form_response?: { sub_form_completion_id: FilterValue };
}

export interface DonesafeModuleRecordsApi {
  createModuleRecord(
    entity: Partial<
      Omit<ModuleRecord, 'attachments' | 'related_records'> &
        ModuleRecordExtraParameters & { relationships?: Record<string, number | string> }
    > &
      DonesafeApiOptions<ModuleRecord>
  ): AxiosPromise<ModuleRecord>;

  csvExport(options: DonesafeIndexApiOptions<ModuleRecord, DonesafeModuleRecordExtraFilters>): AxiosPromise<{ url: string }>;

  csvExportAsync(options: DonesafeIndexApiOptions<ModuleRecord, DonesafeModuleRecordExtraFilters>): AxiosPromise<{ message: string }>;

  deleteModuleRecord(id: number): AxiosPromise<ModuleRecord>;

  getModuleRecord(
    id: number,
    params?: DonesafeApiOptions<ModuleRecord>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<ModuleRecord>;

  getModuleRecords(
    options: DonesafeIndexApiOptions<ModuleRecord, DonesafeModuleRecordExtraFilters>,
    config?: ApiRequestConfig
  ): AxiosPromise<ModuleRecord[]>;

  getModuleRecordsWithSharedFilter(
    options: DonesafeIndexApiOptions<ModuleRecord, DonesafeModuleRecordExtraFilters>,
    filterKey: keyof ModuleRecord | 'sub_form_response_id',
    filterValue: FilterValue,
    config?: ApiRequestConfig
  ): AxiosPromise<ModuleRecord[]>;
  updateModuleRecord(
    id: number,
    entity: Partial<Omit<ModuleRecord, 'attachments' | 'related_records'> & ModuleRecordExtraParameters & { form_uuid?: string }> &
      DonesafeApiOptions<ModuleRecord>
  ): AxiosPromise<ModuleRecord>;
}

export const createModuleRecordsApi = (api: AxiosInstance): DonesafeModuleRecordsApi => {
  const moduleRecordsApi: Omit<DonesafeModuleRecordsApi, 'getModuleRecordsWithSharedFilter'> = {
    csvExport: (params) => api.post('/api/module_records/csv_export', params),
    csvExportAsync: (params) => api.post('/api/module_records/csv_export', { ...params, async: true }),
    getModuleRecords: (params, config) => api.get('/api/module_records', { params, ...config }),
    getModuleRecord: (id, params, config) => api.get(`/api/module_records/${id}`, { params, ...config }),
    createModuleRecord: (entity): AxiosPromise<ModuleRecord> => api.post('/api/module_records', entity, { ...getCsrfConfig() }),
    updateModuleRecord: (id, entity) => api.put(`/api/module_records/${id}`, entity),
    deleteModuleRecord: (id) => api.delete(`/api/module_records/${id}`),
  };

  return {
    ...moduleRecordsApi,
    getModuleRecord: convertToJoinedRequest(moduleRecordsApi.getModuleRecord, moduleRecordsApi.getModuleRecords),
    getModuleRecordsWithSharedFilter: sharedFilterListRequest(moduleRecordsApi.getModuleRecords),
  };
};
