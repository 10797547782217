import type { AxiosInstance, AxiosPromise } from 'axios';
import { convertToJoinedRequest } from '@app/services/donesafe-api-utils';
import type { RecordCalculation } from '@app/models/record-calculation';
import type {
  ApiRequestConfig,
  ApiRequestJoinConfig,
  DonesafeApiOptions,
  DonesafeIndexApiOptions,
  UpdateIndexParams,
} from '@app/services/donesafe-api-utils';

export interface DonesafeRecordCalculationsApi {
  createRecordCalculation(entity?: Partial<RecordCalculation>): AxiosPromise<RecordCalculation>;

  deleteRecordCalculation(id: RecordCalculation['id']): AxiosPromise<RecordCalculation>;

  getRecordCalculation(
    id: RecordCalculation['id'],
    params?: DonesafeApiOptions<RecordCalculation>,
    config?: ApiRequestConfig & ApiRequestJoinConfig
  ): AxiosPromise<RecordCalculation>;

  getRecordCalculations(params: DonesafeIndexApiOptions<RecordCalculation>, config?: ApiRequestConfig): AxiosPromise<RecordCalculation[]>;

  recalculateRecordCalculation(id: RecordCalculation['id']): AxiosPromise<RecordCalculation>;

  updateRecordCalculation(id: RecordCalculation['id'], entity?: Partial<RecordCalculation>): AxiosPromise<RecordCalculation>;

  updateRecordCalculationIndexes(params: UpdateIndexParams & DonesafeIndexApiOptions<RecordCalculation>): AxiosPromise<RecordCalculation[]>;
}

export const createRecordCalculationsApi = (api: AxiosInstance, path = '/api/record_calculations'): DonesafeRecordCalculationsApi => {
  const recordCalculationsApi: DonesafeRecordCalculationsApi = {
    getRecordCalculations: (params, config) => api.get(path, { params, ...config }),
    getRecordCalculation: (id, params, config) => api.get(`${path}/${id}`, { params, ...config }),
    deleteRecordCalculation: (id) => api.delete(`${path}/${id}`),
    updateRecordCalculation: (id, entity) => api.put(`${path}/${id}`, entity),
    createRecordCalculation: (entity) => api.post(path, entity),
    recalculateRecordCalculation: (id) => api.post(`${path}/${id}/recalculate`),
    updateRecordCalculationIndexes: (params) => api.post(`${path}/update_indexes`, params),
  };

  return {
    ...recordCalculationsApi,
    getRecordCalculation: convertToJoinedRequest<RecordCalculation>(
      recordCalculationsApi.getRecordCalculation,
      recordCalculationsApi.getRecordCalculations
    ),
  };
};
